import { Box, Text, Heading, useColorModeValue } from "@chakra-ui/react";

export const Hardware = () => {
  return (
    <Box>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 5
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Hardware
      </Heading>
      <Text>
        Due to the complexity of the device and the pricing for parts, I
        couldn’t make to the hardware side of the device. To show the user
        interface in the non-VR version, I decided to create an application for
        my iPad to demonstrate the User interface.{" "}
      </Text>
    </Box>
  );
};
