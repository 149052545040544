import {
  Box,
  Heading,
  Text,
  Image,
  useColorModeValue,
  Container,
  Center,
} from "@chakra-ui/react";
import Mockup from "../../../assets/projects-imgs/the_silent_maze/WebsiteWireframe.svg";
import LandingPageRef from "../../../assets/projects-imgs/the_silent_maze/LandingPageRef.png";
import DownloadPageRef from "../../../assets/projects-imgs/the_silent_maze/DownloadPageRef.png";
export const WebsiteDesign = () => {
  return (
    <Box mt={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 7
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Website Design
      </Heading>
      <Box display={{ sm:"flex", md: "flex", base: "block" }}>
        <Box px={"10px"}>
          <Text>
            When creating the website for the project, I started making a simple
            mock-up of the website within Figma.
          </Text>
          <Text my={"10px"}>
            Using the research, I did; I created a brief mock-up of the landing
            page within Figma. In the top left-hand corner is where the logo
            would be and over to the right is where the navigation would be with
            the social links right next to them.
          </Text>
          <Text>
            Originally, we were going to have the game to be played from the
            website which is what that black box is for, but we realised that
            the game will be too large to load from the website and just allow
            them to download it from the website instead. Now the black box has
            been turned into an image that displays the game & website title in
            the middle of the screen.
          </Text>
          <Text my={"10px"}></Text>
        </Box>
        <Image w={"400px"} rounded={"md"} src={Mockup} />
      </Box>
      <Box display={{ md: "block", base: "block" }}>
        <Box px={"10px"}>
          <Text>
            Underneath that section, is where a bunch of information about who
            we are went, why we made the game and what the game is. This content
            is not in the Figma design as I was running out of time to create
            the website, so I decided to make on it on the go. Now I look back,
            I realised that was a mistake and I have learned from that mistake
            as with any project I make, I will design them first within Figma or
            something equal.
          </Text>
          <Text my={"10px"}>
            Below is the final screenshot of the landing page I made. Underneath
            the final landing page screenshot is the Download page. I mainly
            focused on this during the project as I was originally going to make
            that the landing page after the change about not having the game on
            the website but last minute, I decided to have a landing page in
            place then have a button to go the download page.
          </Text>
          
        </Box>
        <Image w={"400px"} mb={"10px"} rounded={"md"} width={"100%"} src={LandingPageRef} />
        <Image w={"400px"} rounded={"md"} width={'100%'} src={DownloadPageRef} />
      </Box>
    </Box>
  );
};
