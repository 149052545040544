import { CopyBlock, hopscotch } from "react-code-blocks";
import { Box } from "@chakra-ui/react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

function MyCodeComponent(props) {
  return (
    <Box w={"100%"}>
      <sSyntaxHighlighter language={props.language} style={dark}>
        <CopyBlock
          text={props.code}
          language={props.language}
          showLineNumbers={props.showLineNumbers}
          wrapLines
          theme={hopscotch}
          codeblock={true}
        />
      </sSyntaxHighlighter>
    </Box>
  );
}

export default MyCodeComponent;
