import {
  Box,
  Heading,
  Image,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsApple } from "react-icons/bs";
import { FaSteam } from "react-icons/fa";
import { SiNintendoswitch } from "react-icons/si";
import EpicGameRef from "../../../assets/projects-imgs/the_silent_maze/EpicGamesStoreRef.png";
import Destiny2Ref from "../../../assets/projects-imgs/the_silent_maze/Destiny2Ref.png";
import NintendoSwitchRef from "../../../assets/projects-imgs/the-hive/NintendoSwitchRef.png";

const ResearchData = [
  {
    title: "Epic Games - LEGO Star Wars Skywalker Saga",
    Content:
      "The inspiration I got from the epic games store was how the layout and UI is structured. The layout is very simple and provides the necessary information required about the product, but it also looks modern with the colour scheme.",
    Image: EpicGameRef,
    link: "https://store.epicgames.com/en-US/p/lego-star-wars-the-skywalker-saga",
  },
  {
    title: "Bungie - Destiny 2",
    Content:
      "Like the epic game store, I got the same the inspiration from Bungie’s website promoting Destiny 2, however they use a lot more content from the game to promote their product. I also took notes from how the structure their wording to promote the game. ",
    Image: Destiny2Ref,
    link: "https://www.bungie.net/",
  }
];

export const Research = () => {
  const cardbg = useColorModeValue("gray.200", "blackAlpha.300");
  return (
    <Box mt={"15px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 5
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Research
      </Heading>
      <Text>
        Before designing the website, I did some research on how game companies
        promote their games.
      </Text>
      <Box
        mt={"20px"}
        gap={"20px"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {ResearchData.map((data, index) => {
          return (
            <Box
              rounded={"md"}
              p={"2%"}
              w={{ base: "full", md: "800px" }}
              bg={cardbg}
              px={"3%"}
              h={"fit-content"}
              key={index}
            >
              <a href={data.link} target={"_blank"}>
                <Image rounded={"md"} src={data.Image} />
                <Heading
                  fontSize={{ base: "23px", md: "28px" }}
                  borderBottom={"3px solid #e5712f"}
                  display={"inline-block"}
                  my={"7px"}
                >
                  {data.title}
                </Heading>
                <Text>{data.Content}</Text>
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
