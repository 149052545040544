import { useColorModeValue } from "@chakra-ui/react";

const Colours = {
  darkMidnightBlue: "#003366",
  peru: "#E5712F",
  darkGray: "#8C8C8C",
  lightGray: "#C0C0C0",
  cetaceanBlue: "#010057",
  cobaltBlue: "#0047AB",
  shark: "#1D1D20",
};

export default Colours;


