import {
  Box,
  Heading,
  Image,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsApple } from "react-icons/bs";
import { FaSteam } from "react-icons/fa";
import { SiNintendoswitch } from "react-icons/si";
import AppleRef from "../../../assets/projects-imgs/the-hive/AppleRef.png";
import SteamDeckRef from "../../../assets/projects-imgs/the-hive/SteamDeckRef.png";
import NintendoSwitchRef from "../../../assets/projects-imgs/the-hive/NintendoSwitchRef.png";

const ResearchData = [
  {
    title: "Apple",
    Content:
      "The inspiration I got from Apple's website was how they would market their products, as in the words they used to get customers to purchase their products and build trust with the customer. This inspiration helped me to write promotion material as I'm not that great with promoting that kind of products.",
    Image: AppleRef,
    link: "https://www.apple.com",
  },
  {
    title: "Steam Deck",
    Content:
      "The inspiration I got from Valve's Steam Deck webpage was the UI design, more exact the navbar and some components such as card design, layout, and the font family. Since our device was the closet to the design of the steam deck, I researched on how they displayed the device and showed the information regarding the device.",
    Image: SteamDeckRef,
    link: "https://www.steamdeck.com/en/",
  },
  {
    title: "Ninendo Switch",
    Content:
      "Similar the statement about the Apple website, I also got inspiration from the way they advertise their products but also along with the lines of the steam deck, I also wanted to see how display their products within the components of the website. However the styled components of the Nintendo Switch page didn’t have a feel for me so my main focus was the marketing side of the website.",
    Image: NintendoSwitchRef,
    link: "https://www.nintendo.com/switch",
  },
];

export const Research = () => {
  const cardbg = useColorModeValue("gray.200", "blackAlpha.300");
  return (
    <Box mt={"15px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 6
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Research
      </Heading>
      <Text>
        Before designing the webpage, I researched a few websites/webapps that
        were quite like the device that we were making, the websites I visited
        and took inspiration from were:
      </Text>
      <Box
        mt={"20px"}
        gap={"20px"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {ResearchData.map((data, index) => {
          return (
            <Box
              rounded={"md"}
              p={"2%"}
              w={{ base: "full", md: "800px" }}
              bg={cardbg}
              px={"3%"}
              h={"fit-content"}
              key={index}
            >
              <a href={data.link} target={"_blank"}>
                <Image rounded={"md"} src={data.Image} />
                <Heading
                  fontSize={{ base: "23px", md: "28px" }}
                  borderBottom={"3px solid #e5712f"}
                  display={"inline-block"}
                  my={"7px"}
                >
                  {data.title}
                </Heading>
                <Text>{data.Content}</Text>
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
