import {
  Box,
  Wrap,
  WrapItem,
  useColorModeValue,
  Text,
  Heading,
  Flex,
} from "@chakra-ui/react";

const TeamData = [
  {
    role: "3D Artist",
    name: "Nathan Kelley",
    content:
      "Nathan was the 3D artist that would provide the assets for YoungJu for the game. He very closely to YoungJu and Katie to make the design was on the correct path. ",
  },
  {
    role: "Designer",
    name: "Katie White",
    content:
      "Katie was amongst one of the designers we had within our group. Throughout this project she took on the responsibility of creating the colour scheme that we would use within the game and the website. She also created the logos that was used. ",
  },
  {
    role: "Designer",
    name: "Ellis Peacock",
    content:
      "Ellie was also amongst the designers but due to unknown reasons, he couldn’t participate within the project. ",
  },
  {
    role: "Game Developer",
    name: "YoungJu Yun",
    content:
      "YoungJu was the game developer of the group. He took on the responsibility of creating the game and all the controls for the game.",
  },
  {
    role: "Front-End Developer",
    name: "Bradlee King",
    content:
      "I was the Web developer within the group, I took on the reasonability on creating the website that would promote the game. I worked very closely with Katie as we would help each other with designing the website.",
  },
];

export const TeamList = () => {
  const cardbg = useColorModeValue("gray.200", "blackAlpha.300");
  return (
    <Box>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 3
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Who was in my Team
      </Heading>
      <Text mt={"6px"}>
      </Text>
      <Flex>
        <Box name="team_description" display={"flex"} my={"10px"}>
          <Wrap spacingX={"-20px"}>
            {TeamData.map((data, index) => {
              return (
                <WrapItem cursor={"default"}>
                  <Box
                    rounded={"md"}
                    p={"6px"}
                    bg={cardbg}
                    w={{ base: "auto", md: "400px" }}
                    h={{ base: "full", md: "240px" }}
                    mb={"10px"}
                  >
                    <Text
                      fontSize={"15px"}
                      color={"#E5712F"}
                      textTransform={"uppercase"}
                      fontWeight={"extrabold"}
                      letterSpacing={"1.5px"}
                    >
                      {data.role}
                    </Text>
                    <Heading borderBottom={"2px solid #E5712F"}>
                      {data.name}
                    </Heading>
                    <Text>{data.content}</Text>
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};
