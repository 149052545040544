import {
  Box,
  HStack,
  Text,
  Image,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import UnshackledCover from "../../assets/projects-imgs/Unshackled/UnshackledCoverArt.svg";
import XcodeIcon from "../../assets/projects-imgs/Unshackled/XcodeIcon.png";
import FigmaIcon from "../../assets/figma-brands.png";
import MiroLogo from "../../assets/MiroLogo.png";
import GithubLogoLight from "../../assets/GitHub-Light.png";
import GithubLogoDark from "../../assets/GitHub_Dark.png";
import { BsArrowReturnRight } from "react-icons/bs";
import { SiFigma, SiGithub, SiTrello, SiXcode } from "react-icons/si";
import BriefRef from "../../assets/projects-imgs/Unshackled/BriefRef.png";

const Unshackled2 = ({ bg, tc }) => {
  return (
    <React.Fragment>
      <Box
        name="Unshackled2"
        h={"100%"}
        w={"full"}
        justifyContent={"center"}
        bg={bg}
        color={tc}
        fontWeight={"semibold"}
      >
        <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"}>
            <Text
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              DM2114: Development Practice A 22/23 (S2)
            </Text>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              Unshackled
            </Heading>
            <Tag>Continued in Second Semester</Tag>
            <HStack>
              <Tag bg={"#E5712F"}>Client Project</Tag>
              <Tag bg={"#E5712F"}>
                <Link
                  href="https://github.com/kingofbrad/Unshackled---Mobile-Project"
                  target={"_blank"}
                >
                  Link to Github
                </Link>
              </Tag>
            </HStack>
            <Tag>Word Count: N/A</Tag>
          </VStack>
          <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
            <VStack>
              <Image
                src={UnshackledCover}
                borderRadius={{ base: "10px", md: "15px" }}
              />
              <Tag>Credits: Unshackled Team</Tag>
            </VStack>

            <TableofContents />
          </VStack>
          <UnshackledSection1 />
          <Section3 />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Unshackled2;

const TableofContents = () => {
  const { logoToggle, setLogoToggleMode } = useColorMode();
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "4" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ContentItem title="Brief">
          An application that will allow sexual assault victim to escape and to
          create their future by allowing them to create journals, track their
          mood, create goals to complete and chat to people anonymously
          regarding certain topics. Unshackled is an mobile application that
          allows sexual assault victims to break their bond to.
        </ContentItem>
        <ContentItem title="End Goal">
          The end goal for this project is to create an application to help
          victims of sexual assault. This application will allow these victims
          to escape reality and have a safe space. The application will be
          available for IOS and Android as that was requested via the client
          within the brief they provided. However the IOS version will only be
          created as I am a single developer working and developing the
          application, alongside, I only know how to create applications within
          Swift.
        </ContentItem>
        <ContentItem title="Role">
          As this a continuation of the project from semester, the other members
          of the project have moved onto other projects, so I am the only
          individual who is working on the application. The project did have 5
          members in the beginning.
        </ContentItem>
        <ContentItem title="Software">
          <VStack align={"start"}>
            <HStack>
              <SiXcode size="25px" />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Xcode 14
              </Text>
            </HStack>
            <HStack>
              <SiFigma size={"30px"} />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Figma
              </Text>
            </HStack>
            <HStack>
              <SiGithub size="25px" />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                GitHub
              </Text>
            </HStack>
            <HStack>
              <SiTrello size={"25px"} />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Trello
              </Text>
            </HStack>
          </VStack>
        </ContentItem>
        <ContentItem title="Research & Prep">
          <ContentListItem title="1.0 Client Brief" />
          <ContentListItem title="1.1 Device Target" />
          <ContentListItem title="1.2 Research | Swift Language" />
          <ContentListItem title="1.3 Research | IOS Best Practice " />
          <ContentListItem title="1.4 Research | App Store Regulations" />
        </ContentItem>
        <ContentItem title={"Development | Swift"}>
          <ContentListItem title="2.0 Picking Up where i left off" />
          <ContentListItem title="2.1 Bug Catching and fixing" />
          <ContentListItem title="2.2 Changing the Onboarding" />
          <ContentListItem title="2.3 Refactoring" />
          <ContentListItem title="2.4 Adding Google & Apple Auth" />
          <ContentListItem title="2.5  " />
          <ContentListItem title="2.6  " />
        </ContentItem>
        <ContentItem title="Final Results">
          <ContentListItem title="3.0 Final Outcome" />
          <ContentListItem title="3.1 What has been accomplished so far" />
          <ContentListItem title="3.2 Time Management" />
          <ContentListItem title="3.3 Conclusion" />
          <ContentListItem title="3.4 Handover Document" />
          <ContentListItem title="3.5 References" />
        </ContentItem>
      </SimpleGrid>
    </Box>
  );
};

export const ContentItem = (props) => {
  return (
    <Box width={"100%"} height={"100%"} fontWeight={"medium"}>
      <Text
        letterSpacing={"1px"}
        fontSize={{ base: "20px", md: "2xl" }}
        textTransform={"uppercase"}
        fontWeight={"bold"}
      >
        {props.title}
      </Text>
      {props.children}
    </Box>
  );
};

export const ContentListItem = (props) => {
  return (
    <HStack>
      <BsArrowReturnRight />
      <Text fontWeight={"semibold"}>{props.title}</Text>
    </HStack>
  );
};

const Section3 = () => {
  return (
    <VStack name="Section 3" align={"start"} marginTop={"20px"}>
      <ReferenceList />
    </VStack>
  );
};

const ReferenceList = () => {
  return (
    <Box pb="30px">
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="UnshackledReferences"
      >
        References
      </Heading>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ReferenceCell
          author="Apple Inc."
          year="N/A"
          name="Planning your iOS app"
          link="https://developer.apple.com/ios/planning/"
          accessDate="10/4/2023"
        />
        <ReferenceCell
          author="Apple Inc."
          year="2017"
          name="Human Interface Guidelines"
          link="https://developer.apple.com/design/human-interface-guidelines/foundations/app-icons/"
          accessDate="11/12/2022"
        />
        <ReferenceCell
          author="Apple Inc."
          year="2017"
          name="Human Interface Guidelines"
          link="https://www.stickpng.com/img/icons-logos-emojis/tech-companies/miro-app-logo"
          accessDate="11/12/2022"
        />
        <ReferenceCell
          author="Flutter"
          year="2017"
          name="Representing the Flutter brand"
          link="https://flutter.dev/brand"
          accessDate="12/12/2022"
        />
        <ReferenceCell
          author="Firebase"
          year="2012"
          name="Representing the Firebase brand"
          link="https://firebase.google.com/brand-guidelines"
          accessDate="14/12/2022"
        />
      </SimpleGrid>
    </Box>
  );
};

export const ReferenceCell = (props) => {
  return (
    <Box>
      <Text>
        {props.author} &#40;{props.year}&#41; "{props.name}", {props.blogname},{" "}
        {props.day} {props.month}. Available at:
        <Link color={"#e5712f"} px={"4px"} href={props.link} target={"_blank"}>
          {props.link}
        </Link>
        <br />
        &#40;Accessed: {props.accessDate}&#41;
      </Text>
    </Box>
  );
};

const UnshackledSection1 = () => {
  return (
    <VStack name="Section 1" align={"start"} marginTop={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 1 Research & Prep
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
      >
        Client Brief
      </Heading>
      <Text>
        In semester 1, I selected a client brief to create a application to help
        victims and survivors of sexual assault, This application is allow those
        people to escape reality and find their personal space. This application
        is to have a journal system to log their day-day life and a mood tracker
        connected to it so correspond to their current mood. There will also be
        a forum type of messaging to allow people to connect and talk about
        their situation and hopefully find some kind of comfort.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Device Target"
      >
        Device Target
      </Heading>
      <Text>
        From the brief, the application was requested to be developed for IOS
        and Android. Throughout the first semester, I was developing the
        application for IOS as I only know how to develop for native IOS
        development. However, the development for android development is on the
        schedule. The android version will properly not be developed my myself,
        but the client can take the project and pass it along to another
        development team/studio.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Swift Language"
      >
        Research | Swift Language
      </Heading>
      <VStack>
        <Image
          borderRadius={"10px"}
          src="https://miro.medium.com/max/1400/1*4fDVdzPaeC7IqiW3R1YhAg.webp"
        />
        <Tag>Credits: kenanatmaca.com</Tag>
      </VStack>

      <Text>
        Throughout the first semester, I had some understanding on how to use
        apple’s language Swift and its corresponding framework called SwiftUI.
        Using the documentation that apple provided on their developer webpage,
        I was able to gain a better understanding using the language. I also
        resourced two other websites to help my journey through using SwiftUI
        and Swift, these websites were: HackingwithSwift and Stack overflow.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Flutter Langauge"
      >
        Research | IOS Best Practice
      </Heading>
      <Text>
        At the beginning of the project back in semester 1, I wanted to begin
        the development as soon as possible for the application and manged to
        begin it within a 4-week time frame. However, for this semester, I
        decided to take a step back and research on the best practices for IOS
        development and what I was doing wrong and how I could improve my
        experience and understanding in IOS development. Utilising Apple’s
        ‘Planning your IOS app’ webpage, I was able to gain an elevated
        understanding on how and why these practices are in place and how they
        are used.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        Research | App Store Review Guidelines
      </Heading>
      <Text>
        As the application was being developed, the concept of having the
        application on the app store came to mind, so I needed to understand
        what regulations and rules Apple have placed to ensure that all
        applications uploaded are safe and meeting their expectations. The
        research and information were discovered on Apples official website
        through their developer section.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        Audience
      </Heading>
      <Text>
        The targeted audience for this application is survivors of sexual
        assault of all genders/sexualities and age.
      </Text>
      <Text>
        The client specifies said that they want this application to be open to
        everyone that needs it and don&#180;t feel like they are being forced to
        use it out of their free will. This is further back up by the brief that
        was given out as seen in image 1.7.1
      </Text>
      <VStack>
        <Image src={BriefRef} rounded="10px" />
        <HStack>
          <Tag>Image 1.7.1</Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
    </VStack>
  );
};
