import { ResumeProto, Resume } from "./Pages/Resume";
import {
  Route,
  BrowserRouter as Router,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { Home } from "./Pages/home";
import React from "react";
import NoMatch from "./Pages/Sub-Pages/NoMatch";
import { Root_Layout } from "./components/Root_Layout";
import  Testpage  from "./Pages/testpage";
import { Projects } from "./Pages/Sub-Pages/Projects";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root_Layout />}>
      <Route index element={<Home />} />
      <Route path="Projects/*" element={<Projects />} />
      <Route path="Resume" element={<Resume />} />
      <Route path="/testPage" element={<Testpage />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
