import React from "react";
import {
  useColorMode,
  useColorModeValue,
  Box,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { ComputerLanguages, DevelopmentTools } from "./Resume";

export const Skill = ({ background, textcolor, silverToggle }) => {
  return (
    <Box name="skills" bg={background} color={textcolor}>
      <VStack align={"start"}>
        <Heading color={silverToggle}>Skills</Heading>
        <ComputerLanguages silverToggle={silverToggle} />
        <DevelopmentTools silverToggle={silverToggle} />
      </VStack>
    </Box>
  );
};
