import { Box, Heading, Text, useColorModeValue } from "@chakra-ui/react";

export const Summary = () => {
  return (
    <Box name="MazeSummary" mt={"30px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 1
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Summary
      </Heading>
      <Text mt={"5px"}>
        The Second project of semester 2 was an interactive project that we had
        to cater to a series of categories of our group&#39;s choice. The goal
        of this project was to create something that would be interactive for
        the users whilst maintaining the need of the category.{" "}
      </Text>
      <Text mt={{ md: "10px", base: "5px" }}>
        Categories that we had to apply this project to were School groups,
        International Students, People with disabilities, Technophobes,
        Educators.
      </Text>
      <Text mt={{ md: "10px", base: "5px" }}>
        For this project, I was grouped with four other people who skillsets
        were spread between all the different pathways within the Digital Media
        Department. These skillsets consisted of: Digital Designers, 3D Artist,
        Game Developer, and a Front-End Web developer. Within this group, I was
        the web developer. I was responsible for creating the website that would
        promote the project and give the right information regarding the
        project.
      </Text>
    </Box>
  );
};

export const ProductIdea = () => {
  return (
    <Box mt={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 2
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        The Project
      </Heading>
      <Text mt={"5px"}>
        Within this project, we all agreed on creating a game that would revolve
        around Virtual reality and using sense of feel and sight. The category
        that this game applies to is the people with disabilities. Mainly those
        who have a loss of hearing.{" "}
      </Text>
      <Text mt={"10px"}>
        The games name is &#39;The Silent Maze&#39;. This is due to where the
        user will not be using any sound whatsoever and will just be relying on
        their sense of touch and sight and the player being in a maze trying to
        navigate to the exit progress onto the next level.
      </Text>
      <Text mt={"10px"}>
        Our original game was going to be a language-based game for children in
        primary schools to allow them to learn new languages whilst playing the
        game but in end we realised that there many more game very similar to
        our concept and we wanted to create original that has not be done before
        hence why the game was scraped.
      </Text>
    </Box>
  );
};
