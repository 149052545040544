export const PublishedProperties = `  
@Published var worldState: WorldState?
@Published var synthTargets: Synthtargets = []
@Published var events: Event = []
@Published var showError = false
@Published var items: Item = []
@Published var isFetchingData: Bool = true
`;

export const Endpoint = `var endPoint = "https://api.warframestat.us"`;
export const FetchWorldStateAPI = `
func fetchWorldState() async throws {
    let request = URL(string: "\(endPoint)/\(APIPlayformPathEndPoint.pc)")
    let (data, response) = try await URLSession.shared.data(from: request!)
    guard let response = response as? HTTPURLResponse else {
        throw APIError.invaildServerResponse
    }
    switch response.statusCode {
    case 400 ..< 500: throw APIError.invaildClientResponse
    case 500 ..< 600: throw APIError.invaildServerResponse
    default: break
    }
    let worldStateResponse = try JSONDecoder().decode(WorldState.self, from: data)
    worldState = worldStateResponse
}
`;

export const FetchSynthTargets = `
func fetchSynthTargets() async throws {
    let request = URL(string: "\(endPoint)/\(APIPathEndPoint.synthTargets)")
    let (data, response) = try await URLSession.shared.data(from: request!)
    guard let reponse = response as? HTTPURLResponse else {
        throw APIError.invaildServerResponse
    }
    switch reponse.statusCode {
    case 400 ..< 500: throw APIError.invaildClientResponse
    case 500 ..< 600: throw APIError.invaildServerResponse
    default:break
    }
    let synthTargetsResponse = try JSONDecoder().decode(Synthtargets.self, from: data)
    synthTargets = synthTargetsResponse
    
}
`;
export const FetchItem = `
func fetchItem(searchTerm: String) async throws {
    guard !searchTerm.isEmpty else {
        return
    }
    
    do{
        guard let requestURL = URL(string: "\(endPoint)/\(APIPathEndPoint.items)/search/\(searchTerm)") else {
            throw APIError.invalidURL
        }
        
        let (data, response) = try await URLSession.shared.data(from: requestURL)
        guard let response = response as? HTTPURLResponse else {
            throw APIError.invaildServerResponse
        }
        
        switch response.statusCode{
        case 400..<500: throw APIError.invaildClientResponse
        case 500..<600: throw APIError.invaildServerResponse
        default: break
        }
        let decoder = JSONDecoder()
        decoder.keyDecodingStrategy = .convertFromSnakeCase
        let itemResponse = try decoder.decode(Item.self, from: data)
        items = itemResponse
    } catch let DecodingError.typeMismatch(type, context) {
        throw APIError.typeMismatch(expected: type, context: context)
    } catch {
        throw error
    }
}
`;
export const FetchData = `
func fetchData() {
    Task {
        do {
            try await fetchWorldState()
            self.isFetchingData = false
        } catch APIError.invalidURL {
            print("invalid URL")
            Toast.shared.present(title: "Invalid URL", symbol: "network.slash", tint: .primary)
        } catch APIError.invaildClientResponse {
            Toast.shared.present(title: "Client Error", symbol: "wifi.slash", tint: .primary)
            self.showError = true
        } catch APIError.invalidData {
            Toast.shared.present(title: "Invalid Data", symbol: "icloud.slash", tint: .primary)
        } catch APIError.invaildServerResponse {
            Toast.shared.present(title: "Server Error", symbol: "xmark.icloud.fill", tint: .primary)
        } catch {
            Toast.shared.present(title: "Unexpected Error", symbol: "exclamationmark.triangle", tint: .primary)
        }
    }
}
`;

export const APIError = `
enum APIError: Error {
    case invalidURL
    case invaildClientResponse
    case invalidData
    case invaildServerResponse
    case typeMismatch(expected: Any.Type, context: DecodingError.Context)
}
`;

export const APIPlatformPathEndPoint = `
enum APIPlayformPathEndPoint {
    case pc
    case xbox
    case playstation
    case nintendoSwitch
    
    var path: String {
        switch self {
        case .pc:
            return "pc"
        case .xbox:
            return "xb1"
        case .playstation:
            return "ps4"
        case .nintendoSwitch:
            return "swi"
        }
    }
}
`;

export const APIPathEndPoint = `
enum APIPathEndPoint {
    case weapons
    case warframes
    case events
    case synthTargets
    case items
    
    var path: String {
        switch self {
        case .events:
            return "events"
        case.synthTargets:
            return "synthTargets"
        case .weapons:
            return "weapons"
        case .warframes:
            return "warframes"
        case .items:
            return "items"
        }
    
    }
}
`;
export const ContentView = `
struct ContentView: View {
    @State private var selectedTab: Tab = .house
    @ObservedObject var networkModel = NetworkCall()
    @State private var openSettings: Bool = false
    @State private var timer: Timer?
    
    init() {
        UITabBar.appearance().isTranslucent = true
        UITabBar.appearance().barTintColor = UIColor.woodsmoke
        UITabBar.appearance().backgroundColor = UIColor.woodsmoke
    }
    
    var body: some View {
      NavigationStack {
               if !networkModel.isFetchingData  {
                   VStack{
                       TabView(selection: $selectedTab) {
                           DashBoardView(networkModel: networkModel)
                               .tabItem { Label("Home", systemImage: "house")}
                               .tag(Tab.house)
                           NewsView(networkModel: networkModel)
                               .tabItem { Label("News", systemImage: "newspaper")}
                               .tag(Tab.newspaper)
                           CodexView()
                               .tabItem {
                                   Label("Codex", systemImage: selectedTab == .book ? "book" : "book.closed")
                                       .contentTransition(.symbolEffect)
                               }
                               .tag(Tab.book)
                       }
                       
                   }
                   .background(Color.blackPearl)
               } else {
                   SplashScreen()
                       .onAppear {
                           networkModel.fetchData()
                       }
               }
        }
      
        .background(
            Color.blackPearl
        )
//        .refreshable {
//            networkModel.fetchData()
//        }
        .onAppear {
            startTimer()
        }
        .onDisappear {
            timer?.invalidate()
        }
    }
    
    private func startTimer() {
        self.timer = Timer.scheduledTimer(withTimeInterval: 60 , repeats: true) { _ in
            networkModel.fetchData()
        }
    }
    
}

#Preview {
    RootView {
        ContentView()
    }
}

`;

export const DashBoardView = `
struct DashBoardView: View {
    
    @ObservedObject var networkModel = NetworkCall()
    
    
    @State private var currentTab: Int = 0
    @State private var openSheet: Bool = false
    
    var body: some View {
        NavigationStack {
            VStack {
                TabView(selection: self.$currentTab) {
                    WorldStateView(networkModel: networkModel).tag(0)
                    FissureView(networkModel: networkModel).tag(1)
                    InvasionsView(networkModel: networkModel).tag(2)
                    SyndicatesView(networkModel: networkModel).tag(3)
                    
                }
                .tabViewStyle(.page(indexDisplayMode: .always))
                .ignoresSafeArea(.all)
                .animation(.easeInOut(duration: 0.3), value: currentTab)
                
            }
        }
        .foregroundStyle(Color.white)
        .background(Color.blackPearl)
        
    }
}
`;

export const WorldState = `
struct WorldStateView: View {
    var networkModel: NetworkCall
    
    var body: some View {
        VStack {
            TimerScrollView(networkModel: networkModel)
        }
        .foregroundStyle(Color.white)
        
    }
}


struct TimerScrollView: View {
    var networkModel: NetworkCall
    
    var body: some View {
        ScrollView() {
            VoidTraderView(nm: networkModel)
            EventsView(networkModel: networkModel)
            ArbitrationView(networkModel: networkModel)
                .opacity(networkModel.showError ? 0:1)
            
            LazyVGrid(columns: [GridItem(.flexible(minimum: 180)), GridItem(.flexible(minimum: 180))]) {
                CircuitChoicesView(networkModel: networkModel)
                SteelPathView(networkModel: networkModel)
            }
            DailyDealsView(networkModel: networkModel)
            CyclesView(networkModel:networkModel)
            ArchonHuntView(networkModel: networkModel)
            SortieView(nm: networkModel)
            Spacer()
        }
        .padding(.top, 60)
        .padding(.horizontal, 10)
        .scrollIndicators(.hidden)
        
    }
}
`;

export const CyclesView = `
struct CyclesView: View {
    @ObservedObject var networkModel = NetworkCall()
     @State private var openChoices: Bool = true
 
     
     var body: some View {
         VStack(alignment:.center, spacing: 20) {
             if let earthData = networkModel.worldState?.earthCycle {
                 CyclesCard(imageBool: earthData.isDay, falseImage: "sun.max.fill", trueImage: "moon.fill", state: earthData.state, expiry: earthData.expiry, location: "Earth")
             }
             if let cetusData = networkModel.worldState?.cetusCycle {
                 CyclesCard(imageBool: cetusData.isDay, falseImage: "sun.max.fill", trueImage: "moon.fill", state: cetusData.state, expiry: cetusData.expiry, location: "Cetus")
             }
             if let vallisData = networkModel.worldState?.vallisCycle {
                 CyclesCard(imageBool: vallisData.isWarm, falseImage: "sun.max.fill", trueImage: "snowflake", state: vallisData.state, expiry: vallisData.expiry, location: "Orb Vallis")
             }
             if let driftData = networkModel.worldState?.cambionCycle {
                 HStack {
                     Image(driftData.active == "fass" ? "Fass" : "Vome")
                         .resizable()
                         .frame(width: 20, height: 20)
                         .padding(.trailing, 2)
                    
                     Text("Cambion Drift")
                         .font(.system(size: 15))
                         .fontWeight(.semibold)
 
                     Divider()
                         .frame(width: 2,height: 20)
                         .foregroundStyle(Color.white)
                         .background(Color.white)
                         
                     Text(driftData.state.capitalized)
                         .font(.footnote)
                         .foregroundStyle(Color.silverChalice)
                     Spacer()
                     CountdownView(expiryDateString: driftData.expiry)
                 }
                 .padding(.horizontal, 30)
             }  
             if let zarimanData = networkModel.worldState?.zarimanCycle {
                 HStack {
                     Image(zarimanData.state == "Grineer" ? "Grineer" : "Corpus")
                         .resizable()
                         .frame(width: 20, height: 20)
                         .padding(.trailing, 2)
                         .foregroundStyle(.white)
                         
                     
                     Text("Zariman")
                         
                         .font(.system(size: 15))
                         .fontWeight(.semibold)
                     Divider()
                         .frame(width: 2,height: 20)
                         .foregroundStyle(Color.white)
                         .background(Color.white)
                         
                     Text(zarimanData.state.capitalized)
                         .font(.footnote)
                         .foregroundStyle(Color.silverChalice)
                     Spacer()
                     CountdownView(expiryDateString: zarimanData.expiry)
                 }
                 .padding(.horizontal, 30)
             }
             if let duviriData = networkModel.worldState?.duviriCycle {
                 HStack {
                     VStack(alignment: .leading) {
                         HStack {
                             Text("Duviri")
                                 .font(.system(size: 15))
                             Divider()
                                 .frame(width: 2,height: 20)
                                 .foregroundStyle(Color.white)
                                 .background(Color.white)
                             
                             Text(duviriData.state.capitalized)
                                 .font(.footnote)
                                 .foregroundStyle(Color.silverChalice)
                         }
                        
                 }
                     Spacer()
                     CountdownView(expiryDateString: duviriData.expiry)
                 }
                 
                 .padding(.horizontal, 30)
             }
         }
         .padding(.vertical, 20)
         .background(Color.blueCharcoal)
         .foregroundStyle(.white)
         .clipShape(RoundedRectangle(cornerRadius: 10))
         
         
     }
 }
 
`;

export const VoidTrader = `
struct VoidTraderView: View {
    var nm: NetworkCall

    @State private var showInventory: Bool = false
    
    var body: some View {
        NavigationStack {
            if let voidTrader = nm.worldState?.voidTrader  {
                VStack {
                    HStack {
                        VStack(alignment: .leading) {
                            Spacer()
                            Text(voidTrader.character)
                                .font(.system(size: 20, weight: .semibold))
                                
                            Text(voidTrader.location)
                                .font(.system(size: 15))
                                .foregroundStyle(.silverChalice)
                               
                            Spacer()
                            TimeRemainingTimer_Days_View(activation: voidTrader.activation, expiry: voidTrader.expiry)
                                .font(Font.custom("SF Pro Text", size: 15).weight(.semibold))
                                .foregroundColor(.silverChalice)
                           
                        }
                        .padding(.leading, 10)
                        .padding(.bottom,5)
                        
                        Spacer()
                    }
                    
                }
                .frame(height: 137)
                .background(
                    Image("Baro Ki'Teer")
                        .resizable()
                        .scaledToFill()
                        .shadow(radius: 5)
                        .overlay {
                            Color.black.opacity(0.3)
                        }
                    
                )
                .clipShape(RoundedRectangle(cornerRadius: 10))
                .foregroundStyle(.white)
                .fullScreenCover(isPresented: $showInventory) {
                    NavigationStack {
                        VStack(spacing: 20) {
                            Image("Baro Ki'Teer")
                                .resizable()
                                .scaledToFill()
                               
                                .shadow(radius: 5)
                                .frame(height: 137)
                            
                                .ignoresSafeArea(.all)
                            
                            HStack {
                                VStack{
                                    Text(voidTrader.character)
                                        .font(.system(size: 20, weight: .semibold))
                                      
                                    Text(voidTrader.location)
                                        .font(.system(size: 15))
                                        .foregroundStyle(.silverChalice)
                                       
                                }
                                TimeRemainingTimer_Days_View(activation: voidTrader.activation, expiry: voidTrader.expiry)
                                    .font(Font.custom("SF Pro Text", size: 15).weight(.semibold))
                                    .foregroundColor(.silverChalice)
                                   
                            }
                            
                            VStack(alignment: .leading) {
                                Text("Inventory")
                                    .font(.system(size: 18, weight: .semibold))
                                ScrollView(.vertical) {
                                    ForEach(voidTrader.inventory, id: \.uniqueName) { item in
                                        VStack(alignment: .leading)
                                        {
                                            Text(item.item)
                                            HStack {
                                                HStack {
                                                    Text("Credits |")
                                                        .font(.system(size: 15))
                                                    
                                                    Text("\(item.credits ?? 0)")
                                                        .font(.system(size: 15))
                                                        .foregroundStyle(.silverChalice)
                                                }
                                                HStack {
                                                    Text("Ducats |")
                                                        .font(.system(size: 15))
                                                    
                                                    Text("\(item.ducats ?? 0)")
                                                        .font(.system(size: 15))
                                                        .foregroundStyle(.silverChalice)
                                                }
                                            }
                                            Divider()
                                                .frame(minHeight: 2)
                                                .overlay(Color.blue)
                                                .padding(.horizontal, 20)
                                        }
                                        
                                        .frame(maxWidth:.infinity ,alignment: .leading)
                                    }
                                    Spacer()
                                }
                            }
                            .padding(.horizontal, 10)
                        }
                        .frame(maxWidth: .infinity, maxHeight: .infinity)
                        .background(
                            LinearGradient(colors: [.affair, .mirage], startPoint: .top, endPoint: .bottom)
                        )
                        .toolbar {
                            ToolbarItem(placement: .confirmationAction){
                                Button {
                                    showInventory = false
                                } label: {
                                    Image(systemName: "xmark")
                                        .foregroundStyle(Color.white)
                                }
                                
                            }
                        }
                    }
                    
                }
                
            }
        }
        
        .onTapGesture{
            withAnimation{
                if !(nm.worldState?.voidTrader.inventory.isEmpty ?? false) {
                    showInventory = true
                }
            }
            
        }
        
    }
}

`;

export const CircuitChoices = `
struct CircuitChoicesView: View {
    @State private var openChoices: Bool = false
    @ObservedObject var networkModel = NetworkCall()
    
    var body: some View {
        VStack {
            if let circuit = networkModel.worldState?.duviriCycle {
                VStack(alignment: .center, spacing: 5) {
                    Text("Circuit")
                        .font(.system(size: 18))
                        .bold()
                    Text("View the possible choices")
                        .font(.system(size: 14))
                        .foregroundStyle(.silverChalice)
                    Text("Tap to View")
                        .font(.system(size: 14))
                        .foregroundStyle(.silverChalice)
                }
                .frame(maxWidth: .infinity)
                .frame(height: 89)
                .background(Color.blueCharcoal)
                .clipShape(RoundedRectangle(cornerRadius: 10))
                .onTapGesture {
                    openChoices.toggle()
                }
                .sheet(isPresented: $openChoices, content: {
                    VStack(alignment: .leading) {
                        Text("Circuit Choices")
                            .font(.system(size: 20))
                            .fontWeight(.semibold)
                            .padding(.top, 30)
                          
                            .padding(.horizontal,20)
                            .padding(.top, 5)
                        
                        // Information
                        VStack(alignment: .leading, spacing: 40){
                            VStack(alignment: .leading, spacing: 20) {
                                if let firstChoice = circuit.choices.first {
                                    HStack {
                                        Text("Mode: -")
                                            .foregroundStyle(Color.gray)
                                            .fontWeight(.semibold)
                                        
                                        Text(firstChoice.category.capitalized)
                                            .fontWeight(.semibold)
                                            .font(.system(size: 16))
                                    }
                                    VStack {
                                        ForEach(firstChoice.choices, id:\.self) { choice in
                                            HStack {
                                                Text("Warframe")
                                                    .fontWeight(.semibold)
                                                    .foregroundStyle(Color.silverChalice)
                                                Spacer()
                                                Text("\(choice)")
                                                    .padding(4)
                                                    .background(Color.tundora)
                                                    .clipShape(RoundedRectangle(cornerRadius: 5))
                                                    .font(.system(size: 15))
                                            }
                                        }
                                    }
                                    
                                }
                                if let secondChoice = circuit.choices.last {
                                    HStack {
                                        Text("Mode: -")
                                            .foregroundStyle(Color.gray)
                                            .fontWeight(.semibold)
                                        Text(secondChoice.category.capitalized)
                                            .fontWeight(.semibold)
                                            .font(.system(size: 16))
                                    }
                                    VStack {
                                        ForEach(secondChoice.choices, id:\.self) { choice in
                                            HStack {
                                                Text("Weapon")
                                                    .fontWeight(.semibold)
                                                    .foregroundStyle(Color.silverChalice)
                                                Spacer()
                                                Text("\(choice)")
                                                    .padding(4)
                                                    .background(Color.tundora)
                                                    .clipShape(RoundedRectangle(cornerRadius: 5))
                                                    .font(.system(size: 15))
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                        .padding(20)
                        Spacer()
                    }
                    .frame(maxWidth: .infinity, maxHeight: .infinity)
                    .foregroundStyle(Color.white)
                    .background(Color.blueCharcoal)
                    .ignoresSafeArea()
                    .presentationDetents([.large])
                    
                })
            }
            
        }
        
        
    }
}

`;
export const Invasions = `
struct InvasionsView: View {
    var networkModel: NetworkCall
    
    var body: some View {
        VStack {
            InvasionsList(networkModel: networkModel)
        }
    }
}

struct InvasionsList: View {
    var networkModel: NetworkCall
    
    var body: some View {
        ScrollView {
            ConstructionProgressView(networkModel: networkModel)
            ForEach(networkModel.worldState?.invasions ?? [], id: \.id) { invasion in
                if !invasion.completed {
                    InvasionCard(node: invasion.node, desc: invasion.desc, attackerFaction: invasion.attacker.faction.rawValue, defenderFaction: invasion.defender.faction.rawValue, attackerReward: invasion.attacker.reward?.asString ?? "", defenderReward: invasion.defender.reward?.asString ?? "", completion: invasion.completion)
                }
            }
        }
        .scrollIndicators(.hidden)
        .padding(.top, 50)
    }
}

#Preview {
    InvasionsView(networkModel: NetworkCall())
}



struct InvasionCard: View {
    let node: String
    let desc: String
    let attackerFaction: String
    let defenderFaction: String
    let attackerReward: String
    let defenderReward: String
    let completion: Double
    
    
    var body: some View {
        ZStack {
            ForEach(FissuresLocation.allCases, id: \.rawValue) { fissureImage in
                if let planetName = node.components(separatedBy: "(").last?.dropLast() {
                    if fissureImage.rawValue.lowercased() == String(planetName).lowercased() {
                        Image(fissureImage.rawValue)
                            .resizable()
                            .scaledToFill()
                            .overlay {
                                Color.black.opacity(0.4)
                            }
                            .clipShape(RoundedRectangle(cornerRadius: 10))
                        
                    }
                }
            }
            VStack {
                HStack{
                    VStack(alignment: .leading) {
                        Text(node)
                            .fontWeight(.semibold)
                            .font(.system(size: 18))
                        Text(desc)
                            .fontWeight(.semibold)
                            .font(.system(size: 16))
                            .foregroundStyle(.silverChalice)
                    }
                    Spacer()
                    Text("\(attackerFaction) vs \(defenderFaction)")
                        .fontWeight(.semibold)
                    
                        .font(.system(size: 16))
                }
                .padding(.horizontal, 10)
                Spacer()
                
                HStack {
                    Text(attackerReward)
                        .fontWeight(.semibold)
                    Spacer()
                    Text(defenderReward)
                        .fontWeight(.semibold)
                }
                .padding(.horizontal, 10)
                CustomProgressBar(completion: completion)
                    .padding(.horizontal, 10)
                
            }
            .padding(.vertical, 10)
            
            
        }
        .padding(.horizontal, 10)
    }
}



struct ConstructionProgressView: View {
    
var networkModel: NetworkCall
    var body: some View {
        VStack {
            if let constructionData = networkModel.worldState?.constructionProgress {
                VStack(spacing: 10) {
                    Text("Construction Progress")
                        .fontWeight(.semibold)
                        .font(.system(size: 20))
                    HStack {
                        VStack {
                            HStack {
                                Text("Grineer")
                                    .fontWeight(.semibold)
                                    .font(.system(size: 18))
                                Image("Grineer")
                                    .resizable()
                                    .frame(width: 25, height: 25)
                            }
                            Text("\(constructionData.fomorianProgress)%")
                                .fontWeight(.semibold)
                                .font(.system(size: 18))
                                .foregroundStyle(.silverChalice)
                        }
                        Spacer()
                        VStack {
                            HStack {
                                Text("Corpus")
                                    .fontWeight(.semibold)
                                    .font(.system(size: 18))
                                Image("Corpus")
                                    .resizable()
                                    .frame(width: 25, height: 25)
                            }
                            Text("\(constructionData.razorbackProgress)%")
                                .fontWeight(.semibold)
                                .font(.system(size: 18))
                                .foregroundStyle(.silverChalice)
                        }
                    }
                    Text("View the build progress for the Formoion and the Razorback ")
                        .fontWeight(.semibold)
                        .font(.system(size: 10))
                        .foregroundStyle(.silverChalice)
                }
                .padding()
                .background(Color.blueCharcoal)
                .clipShape(RoundedRectangle(cornerRadius: 10))
            }
        }
        .padding(.horizontal, 10)
        .foregroundStyle(.white)
       
    }
}

`;
export const Syndicates = `
enum SyndicateSigil: String, CaseIterable {
    case Ostrons
    case Solaris_United = "Solaris United"
    case Entrati
    case Nightwave
    case Simaris
    
}


struct SyndicatesView: View {
    var networkModel: NetworkCall
    @State  var ostronBounties: Bool = false
    @State  var solarisBounties: Bool = false
    @State  var entratiBounties: Bool = false
    @State  var nightwaveBounties: Bool = false
    @State  var simarisBounties: Bool = false
    
    
    var body: some View {
        ScrollView {
            VStack {
                if let nightwave = networkModel.worldState?.nightwave {
                    SyndicateCard(faction: "Nightwave", subHeadline: "", factionColor: .crownOfThorns, imageSize: CGSize(width: 50, height: 50), textColor: .cornflowerLilac, season: nightwave.season, showTimer: true, expiry: nightwave.expiry, footNote: "")
                        .onTapGesture {
                            nightwaveBounties.toggle()
                        }
                        .fullScreenCover(isPresented: $nightwaveBounties, content: {
                            NightwaveMissionView(isPresented: $nightwaveBounties, faction: "Nightwave", factionColor: .crownOfThorns, imageSize: CGSize(width: 50, height: 50), textColor: .cornflowerLilac, activeChallenge: nightwave.activeChallenges, expiry: "")
                        })
                }
                
                
                if let firstMission = networkModel.worldState?.syndicateMissions.first {
                    HStack {
                        Text("Bounties refresh in:")
                            .fontWeight(.semibold)
                            .font(.system(size: 18))
                        Spacer()
                        CountdownView(expiryDateString: firstMission.expiry)
                            .font(.system(size: 18))
                    }
                }
                
                ForEach(networkModel.worldState?.syndicateMissions as? [SyndicateMission] ?? [], id: \.id) { mission in
                    
                    if mission.syndicate == "Ostrons" {
                        SyndicateCard(faction: mission.syndicate, subHeadline: "Tap to view bounites", factionColor: .desert, imageSize: CGSize(width: 60, height: 74.44), textColor: .raffia, season: 0, showTimer: false, expiry: "", footNote: "")
                            .onTapGesture {
                                ostronBounties.toggle()
                            }
                            .fullScreenCover(isPresented: $ostronBounties, content: {
                                SyndicateBountyView(isPresented: $ostronBounties, faction: mission.syndicate, factionColor: .desert,imageSize: CGSize(width: 60, height: 74.44), textColor: .raffia, job: mission.jobs )
                                    .frame(maxWidth: .infinity, maxHeight: .infinity)
                                    
                            })
                    }
                    if mission.syndicate == "Solaris United" {
                        SyndicateCard(faction: mission.syndicate,subHeadline: "Tap to view bounites" ,factionColor: .shingleFawn, imageSize: CGSize(width: 60, height: 69), textColor: .straw, season: 0, showTimer: false, expiry: "", footNote: "")
                            .onTapGesture {
                                solarisBounties.toggle()
                            }
                            .fullScreenCover(isPresented: $solarisBounties, content: {
                                SyndicateBountyView(isPresented: $solarisBounties, faction: mission.syndicate, factionColor: .shingleFawn, imageSize: CGSize(width: 60, height: 69), textColor: .straw, job: mission.jobs)
                                    .frame(maxWidth: .infinity, maxHeight: .infinity)
                               
                            })
                    }
                    if mission.syndicate == "Entrati" {
                        SyndicateCard(faction: mission.syndicate,subHeadline: "Tap to view bounites"  ,factionColor: .trout, imageSize: CGSize(width: 60, height: 53), textColor: .marigold, season: 0, showTimer: false, expiry: "", footNote: "")
                            .onTapGesture {
                                entratiBounties.toggle()
                            }
                            .fullScreenCover(isPresented: $entratiBounties, content: {
                                SyndicateBountyView(isPresented: $entratiBounties, faction: mission.syndicate, factionColor: .trout, imageSize: CGSize(width: 60, height: 53), textColor: .marigold, job: mission.jobs)
                            })
                    }
                }
                Divider()
                    .frame(height:3)
                    .foregroundStyle(.white)
                    .background(.white)
                SyndicateCard(faction: "Simaris", subHeadline: "Tap to view targets", factionColor: .cafeRoyale, imageSize: CGSize(width: 50, height: 40.09), textColor: .chalky, season: 0, showTimer: false, expiry: "", footNote: "")
                    .onTapGesture {
                        simarisBounties.toggle()
                    }
                    .fullScreenCover(isPresented: $simarisBounties, content: {
                        SimarisTargetView(isPresented: $simarisBounties)
                    })
            }
            
        }
        .frame(maxWidth:.infinity)
        .padding(.top, 50)
        .padding(.horizontal, 10)
        .scrollIndicators(.hidden)
    }
    
    
    
}

`;

export const Fissures = `
enum FissuresLocation: String, CaseIterable {
    case Earth
    case Venus
    case Mars
    case Pluto
    case Europa
    case Eris
    case Phobos
    case Saturn
    case Kuva_Fortress = "Kuva Fortress"
    case Uranus
    case Void
    case Jupiter
    case Ceres
    case Neptune
    case Zariman
    case Sedna
    case Deimos
    case Lua
    case Veil
    case Derelict
    case Mercury
    
}

enum Relic: String, CaseIterable {
    case Lith
    case Meso
    case Neo
    case Axi
    case Requiem
    case all
}

struct FissureView: View {
    var networkModel: NetworkCall
    var body: some View {
        VStack {
            FissuresList(networkModel: networkModel )
        }
        .foregroundStyle(Color.white)
    }
}

struct RelicImageView: View {
    let relic: Relic
    var body: some View {
        Image(relic.rawValue)
            .resizable()
            .aspectRatio(contentMode: .fit)
            .frame(width: 70, height: 70)
    }
}


struct FissureCard: View {
    let node: String
    let tier: String
    let enemy: String
    let relicImage: String
    let isHard: Bool
    let isStorm: Bool
    let expiry: String
    var body: some View {
        ZStack {
            ForEach(FissuresLocation.allCases, id: \.rawValue) { fissureImage in
                if let planetName = node.components(separatedBy: "(").last?.dropLast() {
                    if fissureImage.rawValue.lowercased() == String(planetName).lowercased() {
                        Image(fissureImage.rawValue)
                            .resizable()
                            .scaledToFill()
                            .frame(height: 100)
                            .overlay {
                                Color.black.opacity(0.4)
                            }
                            .clipShape(RoundedRectangle(cornerRadius: 10))
                            
                            
                    }
                }
            }
            
            HStack {
                VStack(alignment:.leading) {
                    Text(node)
                    Text(tier)
                    Text(enemy)
                    CountdownView(expiryDateString: expiry)
                }
                .padding(.horizontal, 10)
                .frame(maxWidth:.infinity, alignment: .leading)
                Spacer()
                
                RelicImageView(relic: Relic(rawValue: relicImage) ?? .Lith )
                    .padding(.trailing, 20)
            }
            
            if isHard {
                Image("LotusEmblem")
                    .colorMultiply(Color.white.opacity(0.4))
                
            } else if isStorm {
                Image("Archwing")
                    .colorMultiply(Color.white.opacity(0.4))
                
            }
            
            
            
        }
    }
}
struct RadioButton: View {
    var text: String
    var isSelected: Bool
    var action: () -> Void
    
    var body: some View {
        Button(action: action) {
            HStack {
                Image(systemName: isSelected ? "largecircle.fill.circle" : "circle")
                Text(text)
            }
        }
    }
}



struct FissuresList: View {
    
    var networkModel: NetworkCall
    
    @State private var selectedToggleOption: ToggleOption = .all
    
    enum ToggleOption: String, CaseIterable {
        case all = "All"
        case fissures = "Fissures"
        case voidStorms = "Void Storms"
        case steelPath = "Steel Path"
    }
    
    var body: some View {
        VStack {
            HStack {
                ForEach(ToggleOption.allCases, id: \.self) { option in
                    
                    Button(action: {
                        selectedToggleOption = option
                    }) {
                        ZStack {
                            RoundedRectangle(cornerRadius: 10)
                                .foregroundColor(option == selectedToggleOption ? .blueCharcoal : .clear)
                            
                            Text(option.rawValue)
                                .font(.system(size: 12, weight: .semibold))
                                .padding(2)
                                .foregroundColor(option == selectedToggleOption ? .white : .white)
                                .clipShape(RoundedRectangle(cornerRadius: 5))
                                .frame(maxWidth: .infinity)
                        }
                        .frame(height: 40)
                        
                    }
                    
                    Spacer()
                }
            }
            .frame(maxWidth: .infinity)
            
            ScrollView{
                ForEach(filteredFissures, id: \.id) { fissure in
                    if !fissure.expired {
                        FissureCard(node: fissure.node, tier: fissure.tier, enemy: fissure.enemy, relicImage: fissure.tier, isHard: fissure.isHard, isStorm: fissure.isStorm, expiry: fissure.expiry)
                    }
                    
                }
            }
            
        }
        .frame(maxWidth:.infinity)
        .padding(.top, 50)
        .padding(.horizontal, 10)
        
    }
    
    @MainActor
    var filteredFissures: [Fissure] {
        guard let worldState = networkModel.worldState else {
            return []
        }
        
        var filteredFissures: [Fissure] = worldState.fissures
        
        switch selectedToggleOption {
        case .fissures:
            filteredFissures = filteredFissures.filter { !$0.isHard && !$0.isStorm }
        case .voidStorms:
            filteredFissures = filteredFissures.filter { $0.isStorm }
        case .steelPath:
            filteredFissures = filteredFissures.filter { $0.isHard }
        default:
            break
        }
        
        return filteredFissures.sorted(by: { $0.tierNum < $1.tierNum })
    }
}

`;
export const SyndicateCardView = `
struct SyndicateCard: View {
    var faction: String
    let subHeadline: String
    var factionColor: Color
    var imageSize: CGSize
    var textColor: Color
    let season: Int
    let showTimer: Bool 
    let expiry: String
    let footNote: String
    
    
    var body: some View {
        
        VStack {
            HStack {
                ForEach(SyndicateSigil.allCases, id:\.rawValue) { image in
                    if image.rawValue == faction {
                        Image(faction)
                            .resizable()
                            .frame(width: imageSize.width, height: imageSize.height)
                    }
                }
                
                VStack(alignment: .leading) {
                    Text(faction)
                        .fontWeight(.semibold)
                        .font(.system(size: 18))
                        .foregroundStyle(textColor)
                    if !subHeadline.isEmpty {
                        Text(subHeadline)
                            .fontWeight(.semibold)
                            .font(.subheadline)
                            .foregroundStyle(.silverChalice)
                    } else {
                        VStack(alignment: .leading) {
                            Text("Season \(String(season))")
                                .fontWeight(.semibold)
                                .font(.subheadline)
                                .foregroundStyle(.silverChalice)
                            Text("Tap to view missions")
                                .fontWeight(.semibold)
                                .font(.footnote)
                                .foregroundStyle(.silverChalice)
                        }
                    }
                    if !footNote.isEmpty {
                        Text("")
                    }
                    
                }
                Spacer()
                if showTimer {
                    VoidTraderCountDownTimer(expiryDateString: expiry)
                }
               
            }
            
        }
        .frame(height: 80)
        .padding()
        .background(factionColor)
        .clipShape(RoundedRectangle(cornerRadius: 10))
    }
    
    
}
`;
export const SyndicateBountyView = `
struct SyndicateBountyView: View {
    
    @Binding var isPresented: Bool
    
    var faction: String
    var factionColor: Color
    var imageSize: CGSize
    var textColor: Color
    let job: [Job]
    
    var body: some View {
        VStack(alignment: .leading){
            VStack {
                HStack {
                    ForEach(SyndicateSigil.allCases, id:\.rawValue) { image in
                        if image.rawValue == faction {
                            Image(faction)
                                .resizable()
                                .frame(width: imageSize.width, height: imageSize.height)
                        }
                    }
                    
                    
                    Text(faction)
                        .fontWeight(.semibold)
                        .font(.system(size: 25))
                        .foregroundStyle(textColor)
                    
                    Spacer()
                    Button {
                        isPresented.toggle()
                    } label: {
                        Image(systemName: "xmark")
                            .padding(8)
                            .background(.ultraThinMaterial)
                            .clipShape(Circle())
                        
                    }
                }
                
            }
            .frame(height: 80)
            .padding()
            .background(factionColor.overlay(content: {
                Color.black.opacity(0.2)
            }))
            .clipShape(RoundedRectangle(cornerRadius: 10))
            .padding(.horizontal, 10)
            Text("Tap to view rewards")
                .fontWeight(.semibold)
                .font(.system(size: 16))
                .foregroundStyle(.silverChalice)
                .padding(.horizontal, 20)
            
            ScrollView {
                ForEach(job, id: \.id) { job in
                    BountieCard(type: job.type, faction: faction, factionColor: factionColor, textColor: textColor, enemyLevel: job.enemyLevels, standingStage: job.standingStages, rewardPool: job.rewardPool)

                }
            }
            .scrollIndicators(.hidden)
            
            
            Spacer()
        }
        .padding(.top, 20)
        .background(factionColor)
    }
}

`;
export const Nightwave = `
struct NightwaveMissionView: View {
    @StateObject var networkModel = NetworkCall()
    
    @Binding var isPresented: Bool
    
    var faction: String
    var factionColor: Color
    var imageSize: CGSize
    var textColor: Color
    let activeChallenge: [ActiveChallenge]
    let expiry: String
    
    var body: some View {
        VStack(alignment: .leading) {
            VStack {
                HStack {
                    ForEach(SyndicateSigil.allCases, id:\.rawValue) { image in
                        if image.rawValue == faction {
                            Image(faction)
                                .resizable()
                                .frame(width: imageSize.width, height: imageSize.height)
                        }
                    }
                    
                    
                    Text(faction)
                        .fontWeight(.semibold)
                        .font(.system(size: 25))
                        .foregroundStyle(textColor)
                    
                    Spacer()
                    Button {
                        isPresented.toggle()
                    } label: {
                        Image(systemName: "xmark")
                            .padding(8)
                            .background(.ultraThinMaterial)
                            .clipShape(Circle())
                        
                    }
                }
                
            }
            .frame(height: 80)
            .padding()
            .background(factionColor.overlay(content: {
                Color.black.opacity(0.2)
            }))
            .clipShape(RoundedRectangle(cornerRadius: 10))
            .padding(.horizontal, 10)
            Text("Scroll to view missions")
                .fontWeight(.semibold)
                .font(.system(size: 16))
                .foregroundStyle(.silverChalice)
                .padding(.horizontal, 15)
            
            ScrollView {
                ForEach(networkModel.worldState?.nightwave.activeChallenges ?? [], id:\.id) { challenge in
                    
                    if challenge.isDaily {
                        NightwaveMissionElement(title: challenge.title, desc: challenge.desc, reputation: challenge.reputation, image: "daily", imageSize: CGSize(width: 50, height: 30.46), factionColor: .crownOfThorns, textColor: .cornflowerLilac, expiry: challenge.expiry)
                    }
                    if !challenge.isDaily && !challenge.isElite {
                        NightwaveMissionElement(title: challenge.title, desc: challenge.desc, reputation: challenge.reputation, image: "weekly", imageSize: CGSize(width: 50, height: 44.5), factionColor: .crownOfThorns, textColor: .cornflowerLilac, expiry: challenge.expiry)
                    }
                    if challenge.isElite {
                        NightwaveMissionElement(title: challenge.title, desc: challenge.desc, reputation: challenge.reputation, image: "elite", imageSize: CGSize(width: 50, height: 48.5), factionColor: .crownOfThorns, textColor: .cornflowerLilac, expiry: challenge.expiry)
                    }
                    
                    
                    
                    
                }
                
            }
            Spacer()
        }
        .background(factionColor)
        .task {
            do {
                try await networkModel.fetchWorldState()
            } catch {
                print("Something went wrong \(error)")
            }
        }
    }
}




struct NightwaveMissionElement: View {
    let title: String
    let desc: String
    let reputation: Int
    let image: String
    var imageSize: CGSize
    var factionColor: Color
    var textColor: Color
    let expiry: String
    var body: some View {
        VStack(alignment: .leading) {
            HStack {
                
                Image(image)
                    .resizable()
                    .frame(width: imageSize.width, height: imageSize.height)
                
                Text(title)
                    .fontWeight(.semibold)
                    .font(.system(size: 25))
                    .foregroundStyle(textColor)
                Spacer()
                Text(String(reputation))
                    .foregroundStyle(.silverChalice)
                    .fontWeight(.semibold)
                Image("nightwaveStanding")
            }
            HStack {
                Text(desc)
                    .fontWeight(.semibold)
                    .foregroundStyle(.silverChalice)
                Spacer()
               VoidTraderCountDownTimer(expiryDateString: expiry)
                    .fontWeight(.semibold)
                    .foregroundStyle(.silverChalice)
            }
            
        }
        .frame(height: 80)
        .padding()
        .background(factionColor.overlay(content: {
            Color.black.opacity(0.2)
        }))
        .clipShape(RoundedRectangle(cornerRadius: 10))
        .padding(.horizontal, 10)
    }
}

enum NightwaveSigils: String, CaseIterable {
    case daily
    case weekly
    case elite
}
`;
export const Simaris = `
struct SimarisTargetView: View {
    @StateObject var networkModel = NetworkCall()
    @Binding var isPresented: Bool
    @State private var searchText: String = ""
    @State private var filteredTargets: [Synthtarget] = []
    
    var faction: String = "Simaris"
    var factionColor: Color = .cafeRoyale
    var imageSize: CGSize = CGSize(width: 50, height: 40.09)
    var textColor: Color = .chalky
    let expiry: String = ""
    
    
    
    private func performSearch(keyword: String) {
        filteredTargets = networkModel.synthTargets.filter { target in
            target.name.contains(keyword)
        }
    }
    private var targets: [Synthtarget] {
        filteredTargets.isEmpty ? networkModel.synthTargets : filteredTargets
    }
    
    var body: some View {
        VStack(alignment: .leading) {
            VStack {
                HStack {
                    ForEach(SyndicateSigil.allCases, id:\.rawValue) { image in
                        if image.rawValue == faction {
                            Image(faction)
                                .resizable()
                                .frame(width: imageSize.width, height: imageSize.height)
                        }
                    }
                    
                    
                    Text(faction)
                        .fontWeight(.semibold)
                        .font(.system(size: 25))
                        .foregroundStyle(textColor)
                    
                    Spacer()
                    Button {
                        isPresented.toggle()
                    } label: {
                        Image(systemName: "xmark")
                            .padding(8)
                            .background(.ultraThinMaterial)
                            .clipShape(Circle())
                        
                    }
                }
                
            }
            .frame(height: 80)
            .padding()
            .background(factionColor.overlay(content: {
                Color.black.opacity(0.2)
            }))
            .clipShape(RoundedRectangle(cornerRadius: 10))
            .padding(.horizontal, 10)
            Text("Tap to view target locations")
                .padding(.horizontal, 15)
                .foregroundStyle(textColor)
                .fontWeight(.semibold)
            CustomSearchBar(searchText: $searchText, backgroundColor: textColor, textColor: factionColor, cancelButtonColor: textColor, isCategory: false, placeHolderText: "for targets", onCommit: {})
            ScrollView {
                ForEach(targets, id: \.name) { target in
                    TargetView(name: target.name, factionColor: factionColor, textColor: textColor) {
                        AnyView (
                            VStack(alignment: .leading) {
                                HStack {
                                    VStack(alignment: .leading, spacing: 10) {
                                        ForEach(target.locations, id:\.planet) { location in
                                            VStack {
                                                HStack {
                                                    Text(location.planet)
                                                        .fontWeight(.semibold)
                                                    Divider()
                                                        .frame(width: 1.5)
                                                        .foregroundStyle(.white)
                                                        .background(.white)
                                                    Text(location.mission)
                                                        .fontWeight(.semibold)
                                                    Spacer()
                                                    Text(location.faction)
                                                        .fontWeight(.semibold)
                                                        .foregroundStyle(textColor)
                                                }
                                                HStack {
                                                    Text(location.type)
                                                        .foregroundStyle(Color.silverChalice)
                                                        .fontWeight(.semibold)
                                                    Divider()
                                                        .frame(width: 1.5)
                                                        .foregroundStyle(.white)
                                                        .background(.white)
                                                    Text(location.spawnRate)
                                                        .foregroundStyle(Color.silverChalice)
                                                        .fontWeight(.semibold)
                                                    Spacer()
                                                    Text("Level: \(location.level)")
                                                        .foregroundStyle(Color.silverChalice)
                                                        .fontWeight(.semibold)
                                                }
                                            }
                                            .foregroundStyle(textColor)
                                        }
                                    }
                                    .padding()
                                }
                            }
                            
                        )
                    }
                }
                .onChange(of: searchText, perform: performSearch)
                
            }
            
            
            
            
        }
        .background(factionColor)
        .task {
            do {
                try await networkModel.fetchSynthTargets()
            } catch {
                print("Something Happened \(error)")
            }
        }
    }
}

#Preview {
    SimarisTargetView(isPresented: .constant(false))
}


struct TargetView: View {
    var name: String
    let factionColor: Color
    let textColor: Color
    @State private var isExpanded: Bool = false
    
    let content: () -> AnyView
    var body: some View {
        VStack(alignment: .leading, spacing: 0) {
            Button {
                withAnimation {
                    self.isExpanded.toggle()
                }
            } label: {
                VStack(spacing: 0) {
                    HStack {
                        Text(name)
                            .fontWeight(.semibold)
                            .font(.system(size: 20))
                            .foregroundStyle(textColor)
                        Spacer()
                    }
                    
                }
                .frame(height: 50)
                .padding()
                .background(factionColor.overlay(content: {
                    if isExpanded {
                        Color.black.opacity(0.4)
                    } else {
                        Color.black.opacity(0.2)
                    }
                    
                }))
                .clipShape(isExpanded ? UnevenRoundedRectangle(cornerRadii: .init(topLeading: 10, bottomLeading:0, bottomTrailing: 0, topTrailing: 10)) : UnevenRoundedRectangle(cornerRadii: .init(topLeading: 10, bottomLeading:10, bottomTrailing: 10, topTrailing: 10)))
                .padding(.horizontal, 10)
            }
            if isExpanded {
                content()
                    .transition(.opacity)
                    .background(factionColor.overlay(content: {
                        Color.black.opacity(0.2)
                    }))
                    .frame(maxWidth:.infinity)
                    .clipShape(UnevenRoundedRectangle(cornerRadii: .init(topLeading: 0, bottomLeading: 10, bottomTrailing: 10, topTrailing: 0)))
                    .padding(.horizontal, 10)
            }
        }
    }
}

`;

export const News = `
struct NewsView: View {
    @StateObject var networkModel = NetworkCall()
    @State private var imageOpacity: Double = 0.0 // Declare opacity as a @State property
    var body: some View {
        VStack {
            ScrollView {
                ForEach(networkModel.worldState?.news.reversed() ?? [], id: \.id) { news in
                    Link(destination: URL(string: news.link)!, label: {
                        ZStack {
                            withAnimation(.easeIn(duration:0.5)) { // Wrap AsyncImage in a withAnimation block
                                ZStack {
                                    AsyncImage(url: URL(string: news.imageLink)) { image in
                                        image
                                            .resizable()
                                            .opacity(imageOpacity)
                                            .overlay {
                                                Color.black.opacity(0.5)
                                            }
                                            
                                    } placeholder: {
                                        Image("news-placeholder")
                                            .resizable()
                                            .overlay {
                                                Color.black.opacity(0.5)
                                            }
                                        
                                    }
                                    .frame(height: 180)
                                .opacity(imageOpacity)
                                } // Apply opacity directly to AsyncImage
                            }
                            .onAppear {
                                // Animate opacity to 1 when the image appears
                                withAnimation(.easeIn(duration: 0.5)) {
                                    imageOpacity = 1.0
                                }
                            }
                            
                            HStack {
                                VStack(alignment: .leading){
                                    Spacer()
                                    Text(news.message)
                                    ExpiryDateView(apiDateString: news.date)
                                        .font(.system(size: 15))
                                        .foregroundStyle(Color.silverChalice)
                                }
                                .padding()
                                Spacer()
                            }
                        }
                        .frame(maxWidth: .infinity)
                        .clipShape(RoundedRectangle(cornerRadius: 10))
                    })
                }
            }
            .padding(.horizontal, 10)
        }
        .foregroundStyle(Color.white)
        .background(Color.blackPearl)
        .task {
            do {
                try await networkModel.fetchWorldState()
            } catch APIError.invalidURL {
                print("invalid URL")
            } catch APIError.invaildClientResponse {
                print("invaild Client Response")
                networkModel.showError = true
            } catch APIError.invalidData {
                print("invaild Data")
            } catch APIError.invaildServerResponse {
                print("invaild Server Response")
            } catch {
                print("Unexcepted Error has appeared \(error)")
            }
        }
        .refreshable {
            do {
                try await networkModel.fetchWorldState()
            } catch APIError.invalidURL {
                print("invalid URL")
            } catch APIError.invaildClientResponse {
                print("invaild Client Response")
                networkModel.showError = true
            } catch APIError.invalidData {
                print("invaild Data")
            } catch APIError.invaildServerResponse {
                print("invaild Server Response")
            } catch {
                print("Unexcepted Error has appeared \(error)")
            }
        }
    }
}
`;
export const Codex = `
struct CodexView: View {
    @State private var searchText: String = ""
    @ObservedObject var networkModel = NetworkCall()
    
    @State private var showToast: Bool = false
    
    @State private var selectedCategory: Category = .all
    let categories: [Category] = Category.allCases
    
    @State private var favoriteItems: [ItemElement] = []
    @State private var showFavoritesOnly: Bool = false
    
//    @State private var selectedItem: ItemElement
    
    private var filteredItems: [ItemElement] {
        let itemsToShow = showFavoritesOnly ? favoriteItems : networkModel.items
        
        if selectedCategory.rawValue == Category.all.rawValue {
            return itemsToShow
        } else {
            return itemsToShow.filter { $0.category.contains(selectedCategory.rawValue) }
        }
    }
    
    private func performSearch()  {
        Task {
            do {
                guard !searchText.isEmpty else {
                    return
                }
                
                try await networkModel.fetchItem(searchTerm: searchText)
            } catch {
                showToast = true
                print("Error fetching item:", error)
            }
        }
    }
    
    var body: some View {
        NavigationStack {
            VStack {
                CustomSearchBar(searchText: $searchText, backgroundColor: .blueCharcoal, textColor: .silverChalice, cancelButtonColor: .silverChalice, isCategory: true, placeHolderText: "the codex", onCommit: { performSearch()})
                
                List(filteredItems, id:\.uniqueName) { item in
                    ZStack{
                        NavigationLink(destination:  CodexDetailView(name: item.name,
                                                                     desc: item.description ?? "",
                                                                     tradable: item.tradable,
                                                                     imageName: item.imageName ?? "",
                                                                     items: networkModel.items,
                                                                     category: Category(rawValue: item.category) ?? Category.all,
                                                                     selectedItem: item)) {EmptyView()}
                            
                            .listRowBackground(Color.clear)
                            .scrollIndicators(.hidden)
                            .listRowSeparator(.hidden)

                        CodexCard(name: item.name, desc: item.description ?? "", imageURL: item.imageName ?? "")
                            .contextMenu {
                                Button(action: {
                                    // Toggle favorite status
                                    if let index = favoriteItems.firstIndex(where: { $0.uniqueName == item.uniqueName }) {
                                        favoriteItems.remove(at: index)
                                    } else {
                                        favoriteItems.append(item)
                                    }
                                }) {
                                    // Display appropriate text based on favorite status
                                    Text(favoriteItems.contains(where: { $0.uniqueName == item.uniqueName }) ? "Remove from Favorites" : "Add to Favorites")
                                    Image(systemName: favoriteItems.contains(where: { $0.uniqueName == item.uniqueName }) ? "star.fill" : "star")
                                }
                            }
                            
                    }
                    
                }
                .listStyle(.plain)
                .scrollContentBackground(.hidden)
                .searchable(text: $searchText,placement: .navigationBarDrawer(displayMode: .always) ,prompt: "Search for Weapons" )
                
            }
            .frame(maxWidth: .infinity)
            .padding(.horizontal)
            .foregroundStyle(Color.white)
            .background(Color.blackPearl)
            .ignoresSafeArea(.keyboard)
            .onAppear {
                favoriteItems = UserDefaults.standard.loadFavoriteItems()
            }
            .onDisappear {
                UserDefaults.standard.saveFavoriteItems(favoriteItems)
            }
            .toolbar {
                ToolbarItem(placement: .primaryAction) {
                    Menu {
                        Section("Favorites") {
                            Button(action: { showFavoritesOnly.toggle() }) {
                                Label("Toogle Favorites", systemImage: showFavoritesOnly ? "heart.fill" : "heart")
                            }
                        }
                        
                        Section(header: Text("Filter")) {
                            ForEach(categories, id: \.self) { category in
                                Button {
                                    selectedCategory = category
                                } label: {
                                    HStack {
                                        if selectedCategory == category {
                                            Image(systemName: "checkmark")
                                        }
                                        Text(category.rawValue)
                                    }
                                }
                            }
                        }
                    }
                label: {
                    Label("Add", systemImage: "line.3.horizontal.decrease.circle")
                }
                }
            }
            
        }
    }
}

`;
export const Detail = `
struct CodexDetailView: View {
    var name: String
    var desc: String
    var tradable: Bool
    var imageName: String
    
    
    var items: [ItemElement]
    @State var category: Category
    var selectedItem: ItemElement
    
    
   
    
    var body: some View {
        VStack(spacing: 0){
            ScrollView() {
                detail
                switch category {
                case .all:
                    EmptyView()
                case .arcanes:
                    ArcaneDetailView(levelStats: selectedItem.levelStats ?? [] , drop: selectedItem.drops ?? [])
                case .archGun:
                    
                    weaponsStats
                case .archMelee:
                    
                    weaponsStats
                case .archwing:
                    EmptyView()
                    
                case .enemy:
                    EmptyView()
                case .fish:
                    EmptyView()
                case .gear:
                    EmptyView()
                case .glyphs:
                    EmptyView()
                case .melee:
                    weaponsStats
                case .misc:
                    EmptyView()
                case .mods:
                    EmptyView()
                case .node:
                    EmptyView()
                case .pets:
                    EmptyView()
                case .primary:
                    
                    weaponsStats
                case .quests:
                    EmptyView()
                case .relics:
                    EmptyView()
                case .resources:
                    EmptyView()
                case .secondary:
                    
                    weaponsStats
                case .sentinels:
                    EmptyView()
                case .sigils:
                    EmptyView()
                case .skins:
                    EmptyView()
                case .warframes:
                    
                    WarframeDetailView(
                        shield: selectedItem.shield ?? 0,
                        armor: selectedItem.armor ?? 0,
                        health: selectedItem.health ?? 0,
                        power: selectedItem.power ?? 0,
                        sprintSpeed: selectedItem.sprint ?? 0.0,
                        components: selectedItem.components ?? [],
                        aura: selectedItem.polarities ?? [],
                        ability: selectedItem.abilities ?? [],
                        passive: selectedItem.passiveDescription ?? ""
                    )
                }
                
            }
            .scrollIndicators(.hidden)
            
        }
        .padding()
        .background(
            Color.blackPearl
        )
    }
    
    
    var detail: some View {
        VStack {
            AsyncImage(url: URL(string: "https://raw.githubusercontent.com/wfcd/warframe-items/master/data/img/\(imageName)") ) { image in image.resizable() } placeholder: { ProgressView() } .frame(width: 100, height: 100)
                .clipShape(Circle())
                .aspectRatio(contentMode: .fit)
            VStack(alignment: .leading) {
                Text(name)
                    .fontWeight(.bold)
                    .font(.system(size: 20))
                Text(desc)
                    .foregroundStyle(.silverChalice)
                    .fontWeight(.semibold)
                
            }
            HStack {
                if tradable {
                    Text("Tradable")
                        .fontWeight(.semibold)
                        .font(.caption)
                        .foregroundStyle(.silverChalice)
                        .padding(10)
                        .background(Color.blackPearl)
                        .clipShape(RoundedRectangle(cornerRadius: 20))
                        
                }
                Spacer()
            }
        }
        .frame(maxWidth: .infinity)
        .padding()
        .background(Color.blueCharcoal)
        .clipShape(RoundedRectangle(cornerRadius: 10))
        
    }
   
    var weaponsStats: some View {
        VStack {
            Text("Weapons Stats")
        }
        .padding()
        .frame(maxWidth: .infinity)
        .background(Color.blueCharcoal)
        .clipShape(RoundedRectangle(cornerRadius: 10))
        .padding()
    }
    
    
}






struct PolarImage: View {
    let polarity: String
    @State private var image: UIImage? = nil
    
    var body: some View {
        if let image = image {
            Image(uiImage: image)
                .resizable()
                .frame(width: 30, height: 30)
                .clipShape(Circle())
                .aspectRatio(contentMode: .fit)
        } else {
            ProgressView()
                .onAppear {
                    loadImage()
                }
        }
    }
    
    private func loadImage() {
        guard let url = URL(string: "https://raw.githubusercontent.com/WFCD/genesis-assets/master/img/polarities/\(polarity).png") else { return }
        
        URLSession.shared.dataTask(with: url) { data, response, error in
            if let data = data, let loadedImage = UIImage(data: data) {
                DispatchQueue.main.async {
                    self.image = loadedImage
                }
            }
        }.resume()
    }
}

`;

export const SearchBar = `
struct CustomSearchBar: View {
    @Binding var searchText: String
    var backgroundColor: Color
    var textColor: Color
    var cancelButtonColor: Color
    
    @State private var isEditing: Bool = false
    @State var isCategory: Bool
    @State private var isMenuOpen: Bool = false
    
    let placeHolderText: String
    var onCommit: () -> Void
    var body: some View {
        HStack {
            
            HStack{
                Image(systemName: "magnifyingglass")
                TextField("", text: $searchText, onCommit: onCommit)
                    .placeholder(when: searchText.isEmpty) {
                        Text("Search \(placeHolderText)")
                    }
                    .onTapGesture {
                        isEditing = true
                    }
                Spacer()
                
                
                
                if isEditing {
                    Button {
                        searchText = ""
                    } label: {
                        Image(systemName: "xmark")
                    }
                }
            }
            .padding(10)
            .foregroundStyle(textColor)
            .background(backgroundColor)
            .clipShape(RoundedRectangle(cornerRadius: 15))
           
            .animation(.easeInOut, value: isEditing)
            
            if isEditing {
                
                Button(action: {
                    self.isEditing = false
                    self.searchText = ""
                    UIApplication.shared.sendAction(#selector(UIResponder.resignFirstResponder), to: nil, from: nil, for: nil)
                }) {
                    Text("Cancel")
                        .foregroundStyle(cancelButtonColor)
                }
                .padding(.trailing, 10)
                .transition(.move(edge: .trailing))
                .animation(.default)
                
                
            }
        }
        
    }
}



extension View {
    func placeholder<Content: View>(
        when shouldShow: Bool,
        alignment: Alignment = .leading,
        @ViewBuilder placeholder: () -> Content) -> some View {
            
            ZStack(alignment: alignment) {
                placeholder().opacity(shouldShow ? 1 : 0)
                self
            }
        }
}

struct CustomSearchBarTest: View {
    @Binding var searchText: String
    @State private var isEditing = false
    
    var body: some View {
        HStack {
            TextField("Search ...", text: $searchText)
                .padding(7)
                .padding(.horizontal, 25)
                .background(Color(.systemGray6))
                .cornerRadius(8)
                .padding(.horizontal, 10)
                .onTapGesture {
                    self.isEditing = true
                }
                .overlay(
                    HStack {
                        Image(systemName: "magnifyingglass")
                            .foregroundColor(.gray)
                            .frame(minWidth: 0, maxWidth: .infinity, alignment: .leading)
                            .padding(.leading, 8)
                            .padding(.trailing, 8)
                        
                        if isEditing {
                            Button(action: {
                                self.searchText = ""
                            }) {
                                Image(systemName: "multiply.circle.fill")
                                    .foregroundColor(.gray)
                                    .padding(.trailing, 8)
                            }
                        }
                    }
                )
            
            if isEditing {
                Button(action: {
                    self.isEditing = false
                    self.searchText = ""
                    UIApplication.shared.sendAction(#selector(UIResponder.resignFirstResponder), to: nil, from: nil, for: nil)
                }) {
                    Text("Cancel")
                }
                .padding(.trailing, 10)
                .transition(.move(edge: .trailing))
                .animation(.default)
            }
        }
    }
}

`;
export const Accordian = `
enum Standing: String, CaseIterable {
    case ostronsStanding
    case solarisStanding
    case entratiStanding
    case nightwaveStanding
    
}


struct CustomAccordion: View {
    let title: String
    let faction: String
    let factionColor: Color
    let textColor: Color
    let enemyLevel: [Int]
    let standingStages: [Int]
    let content: () -> AnyView // Use AnyView to allow any type of content
    
    
    // MARK: - information
    
    
    
    // MARK: - Colours
    
    
    var totalStanding: Int {
        return standingStages.reduce(0, +)
        
    }
    var enemyLevelString: String {
        return enemyLevel.map { String($0) }.joined(separator: " - ")
    }
    
    @State private var isExpanded: Bool = false
    
    var body: some View {
        VStack(alignment: .leading, spacing: 0) {
            Button(action: {
                withAnimation {
                    self.isExpanded.toggle()
                }
            }) {
                VStack(spacing: 0) {
                    HStack {
                        VStack(alignment: .leading){
                            Text(title)
                                .fontWeight(.semibold)
                                .font(.system(size: 18))
                                .foregroundStyle(textColor)
                            Text("Level: \(enemyLevelString)")
                                .fontWeight(.semibold)
                                .font(.subheadline)
                                .foregroundStyle(.silverChalice)
                        }
                        Spacer()
                        HStack {
                            Text("\(totalStanding)")
                                .fontWeight(.semibold)
                                .foregroundStyle(.silverChalice)
                            if let standing = SyndicateSigil(rawValue: faction) {
                                switch standing {
                                case .Ostrons:
                                    Image("ostronsStanding")
                                case .Solaris_United:
                                    Image("solarisStanding")
                                case .Entrati:
                                    Image("entratiStanding")
                                case.Nightwave:
                                    Image("nightwaveStanding")
                                case .Simaris:
                                    Image("")
                                }
                            }
                            
                            
                        }
                    }
                }
                .frame(height: 55)
                .padding()
                .background(factionColor.overlay(content: {
                    if isExpanded {
                        Color.black.opacity(0.4)
                    } else {
                        Color.black.opacity(0.2)
                    }
                    
                }))
                .clipShape(isExpanded ? UnevenRoundedRectangle(cornerRadii: .init(topLeading: 10, bottomLeading:0, bottomTrailing: 0, topTrailing: 10)) : UnevenRoundedRectangle(cornerRadii: .init(topLeading: 10, bottomLeading:10, bottomTrailing: 10, topTrailing: 10)))
                .padding(.horizontal, 10)
                
            }
            
            if isExpanded {
                content()
                    .transition(.opacity)
                    .background(factionColor.overlay(content: {
                        Color.black.opacity(0.2)
                    }))
                    .frame(maxWidth:.infinity)
                    .clipShape(UnevenRoundedRectangle(cornerRadii: .init(topLeading: 0, bottomLeading: 10, bottomTrailing: 10, topTrailing: 0)))
                    .padding(.horizontal, 10)
                    
                    
            }
            
        }
    }
}

`;
export const Tabbar = `
enum Tab: String, CaseIterable {
    case house
    case newspaper
    case book = "book.closed"
    
}

enum TabName: String, CaseIterable {
    case home
    case news
    case codex
    
}


struct CustomTabBar: View {
    @Binding var selectedTab: Tab
    
    private var fillImage: String {
        selectedTab.rawValue + ".fill"
    }
    
    private var bookAnimation: String {
        selectedTab == .book ? "book.fill" : "book.closed"
    }
    
    var body: some View {
        VStack {
            HStack(spacing: 0) {
                ForEach(Tab.allCases, id: \.rawValue) { tab in
                    Spacer()
                    VStack {
                        Image(systemName: tab == .book ? bookAnimation : (selectedTab == tab ? fillImage : tab.rawValue))
                            .contentTransition(.symbolEffect)
                            .scaleEffect(selectedTab == tab ? 1.25 : 1.0)
                            .foregroundStyle(selectedTab == tab ? .husk : .silverChalice)
                            .font(.system(size: 22))
                        
                        Text(tabName(for: tab))
                            .font(.caption)
                            .fontWeight(.semibold)
                            .foregroundColor(selectedTab == tab ? .husk : .silverChalice)
                            .padding(.top, 5)
                    }
                    .onTapGesture {
                        withAnimation(.easeIn(duration: 0.1)) {
                            selectedTab = tab
                        }
                    }
                    Spacer()
                    
                    
                    
                }
            }
            .frame(height: 80)
            .background(Color.woodsmoke)
            .clipShape(RoundedRectangle(cornerRadius: 10))
            .padding(.horizontal, 10)
            
        }
        
    }
    private func tabName(for tab: Tab) -> String {
        switch tab {
        case .house:
            return TabName.home.rawValue
        case .newspaper:
            return TabName.news.rawValue
        case .book:
            return TabName.codex.rawValue
        
        }
    }
}

`;
export const ProgressBar = `
struct CustomProgressBar: View {
    let completion: Double
    
    var body: some View {
        GeometryReader { geometry in
            ZStack(alignment: .leading) {
                RoundedRectangle(cornerRadius: 10)
                    .frame(width: geometry.size.width, height: 10)
                    .foregroundColor(Color.silverChalice.opacity(0.6))
                
                RoundedRectangle(cornerRadius: 10)
                    .frame(width: geometry.size.width * CGFloat(completion) / 100, height: 10)
                    .foregroundColor(Color.husk)
            }
        }
        .frame(height: 20)
    }
}
`;
export const CountdownView = `
struct VoidTraderCountDownTimer: View {
    @State private var timeRemaining: TimeInterval = 0
    let timer = Timer.publish(every: 1, on: .main, in: .common).autoconnect()
    let endOfDay: Date
    let expiryDate: Date

    init(expiryDateString: String) {
        let dateFormatter = ISO8601DateFormatter()
        dateFormatter.formatOptions = [.withInternetDateTime, .withFractionalSeconds]
        dateFormatter.timeZone = TimeZone(identifier: "UTC")


        
        if let date = dateFormatter.date(from: expiryDateString) {
            let calendar = Calendar.current
            let endOfDayComponents = calendar.dateComponents([.year, .month, .day], from: Date())
            let endOfDayDate = calendar.date(from: endOfDayComponents)?.addingTimeInterval(24 * 60 * 60) ?? Date()
            self.endOfDay = endOfDayDate < date ? endOfDayDate : date
            self.expiryDate = date
        } else {
            // If date parsing fails, fallback to current date
            self.endOfDay = Date()
            self.expiryDate = Date()
        }
    }

    var body: some View {
        ZStack{
          Text("\(daysRemaining())d \(timeString(time: timeRemaining))")
              .font(.system(size: 15).weight(.semibold))
      }
        .onReceive(timer) { _ in
            let now = Date()
            if now < endOfDay {
                timeRemaining = endOfDay.timeIntervalSince(now)
            } else {
                timeRemaining = 0
                timer.upstream.connect().cancel()
            }
        }
    }
    
    func daysRemaining() -> Int {
        let calendar = Calendar.current
        let components = calendar.dateComponents([.day], from: Date(), to: expiryDate)
        return components.day ?? 0
    }
    
    func hoursRemaining() -> Int {
        let calendar = Calendar.current
        let remainingHours = calendar.dateComponents([.hour], from: Date(), to: endOfDay).hour ?? 0
        return max(0, remainingHours) // Ensure remaining hours don't go negative
    }
    
    func timeString(time: TimeInterval) -> String {
        let hours = Int(time) / 3600
        let minutes = Int(time) / 60 % 60
        let seconds = Int(time) % 60
        return String(format:"%02i:%02i:%02i", hours, minutes, seconds)
    }
}

struct VoidTraderCountDownTimer_Previews: PreviewProvider {
    static var previews: some View {
        VoidTraderCountDownTimer(expiryDateString: "2024-04-07T13:00:00.000Z")
    }
}

`;
