import { Box, Text, useColorModeValue, Heading } from "@chakra-ui/react";

export const Summary = () => {
  return (
    <Box name="summary" mt={"30px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 1
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Summary
      </Heading>
      <Text mt={"5px"}>
        The first project of semester 2 was a mobile game application. We had to
        create a physical device along with custom software to go along with it.
        We were placed into groups with a minimum of one person from each
        pathway (course). My group ended up having one 3D artist, two designers,
        one developer, one CAD designer and one game developer.{" "}
      </Text>
      <Text mt={"10px"}>
        Since I was the developer of the group, my role was to create the
        website to promote the device and create the UI software that would run
        the device.{" "}
      </Text>
      <Text mt={"10px"}>
        We all agreed to call the device HIVE. The reason why we called the
        device Hive is for two reasons. The first one is to meet the sustainable
        development goal 2 which is world hunger. We all decided that we would
        donate a portion of the money from each sale to charity. The second
        reason was mainly due to a random design at James did when we were
        coming up with concepts and we went with it.{" "}
      </Text>
    </Box>
  );
};

export const ProductIdea = () => {
  return (
    <Box mt={'20px'}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 2
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        What we decided on
      </Heading>
      <Text>
        The project we all decided to do was a VR like switch. Basically, we
        took the idea of the docking system from the Nintendo Switch and made it
        into Virtual Reality. So, when to user wants to play VR, then they can
        place to device into the VR headset, and it will switch to the VR user
        interface. But the user can also play the device outside the VR headset
        with the included controllers that clip to the side of the device. These
        controllers are also used when it&#39;s in VR mode.
      </Text>
      <Text mt={'10px'}>
        The game that was decided was an exploration game that allows the user
        to explore ancient ruins from different point in time, such as the Great
        Pyramids of Egypt, the ancient city of Uruk and so on.
      </Text>
    </Box>
  );
};
