import { background, Text, VStack, Heading, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Colours from "../themes/colours";

export const Abouts = ({ background, silverToggle }) => {
  

  return (
    <VStack align={"start"} name="about" id="about" bg={background}>
      <Heading color={silverToggle}>About</Heading>
      <Text
        textTransform={"uppercase"}
        fontSize={{ base: "15px", md: "20px" }}
        color={Colours.peru}
        fontWeight={"extrabold"}
      >
        Who am I?
      </Text>
      
      <Text>
        My name is Bradlee King, I'm a IOS developer and Front-end web developer
        who is very passionate and intrigued on building excellant applications
        and websites that improves the lives of those around me.
      </Text>
      <Text>
        I specialise in creating front-end webpages and full-fledge IOS
        applications for clients ranging from individuals to small-businesses.
        What would you do if you had a IOS & Full-Stack Web developer at your
        fingertips?
      </Text>
    </VStack>
  );
};
