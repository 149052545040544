import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import { HiveRef } from "../../components/sub-components/Hive_Components/Section10_References";
import {
  ProductIdea,
  Summary,
} from "../../components/sub-components/Hive_Components/Section1_Summary";
import { TeamList } from "../../components/sub-components/Hive_Components/Section3_Team";
import {
  SoftwareDescription,
  SoftwareList,
} from "../../components/sub-components/Hive_Components/Section4_Software";
import { Hardware } from "../../components/sub-components/Hive_Components/Section5_Hardware";
import { Research } from "../../components/sub-components/Hive_Components/Section6_Research";
import {
  ColourScheme,
  WebsiteDesign,
} from "../../components/sub-components/Hive_Components/Section7_WebsiteDesign";
import { UserInterfaceDesign } from "../../components/sub-components/Hive_Components/Section9_UserInterfaceDesgin";

const Hive = ({ bg, tc }) => {
  useEffect(()=>{
    document.title = 'Bradlee King || Hive'
  })

  return (
    <Box
      name="hive"
      h={"100%"}
      w={"full"}
      justifyContent={"center"}
      bg={bg}
      color={tc}
      fontWeight={"semibold"}
    >
      <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
        <Box>
          <Text
            fontSize={"15px"}
            color={"#E5712F"}
            textTransform={"uppercase"}
            fontWeight={"extrabold"}
            letterSpacing={"1.5px"}
          >
            Project 2
          </Text>
          <Box display={{md:'flex'}} justifyContent={{md:'space-between'}}>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              borderBottom={"3px solid #E5712F"}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              The Hive
            </Heading>
            <Box display={{base: 'none', md:'block'}}>
            {/* <Button bg={useColorModeValue('','')} mr={'20px'}>Go to Website</Button> */}
            </Box>
          </Box>
          {/* <Button display={{md:'none'}} bg={useColorModeValue('','')} mr={'20px'}>Go to Website</Button> */}

        </Box>
        <Box name="category"></Box>
        <Box name="summary" mt={"30px"}>
          <Summary />
          <ProductIdea />
        </Box>

        <Box name="team" mt={"20px"}>
          <TeamList />
        </Box>
        <Box name="software" mt={"15px"}>
          <SoftwareList />
          <SoftwareDescription />
        </Box>
        <Box name="hardware">
          <Hardware />
        </Box>
        <Box name="Research">
          <Research />
        </Box>
        <Box name="Website Design">
          <WebsiteDesign />
          <ColourScheme />
        </Box>
        <Box name="UserInterfaceDesign">
          <UserInterfaceDesign />
        </Box>
        <Box>
          <HiveRef />
        </Box>
      </Box>
    </Box>
  );
};

export default Hive;
