import React from "react";
import LowPoly from "../assets/projects-imgs/Low-poly-asset-pack.png";
import ClutteredCharactors from "../assets/projects-imgs/ClutteredCharactors.png";
import HiveLogo from "../assets/projects-imgs/Hive.png";
import SlientMazeLogo from "../assets/projects-imgs/Slient Maze.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import Resume from "./Resume";
import { RecentCards } from "../components/sub-components/Cards";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  Text,
  Code,
  VStack,
  Heading,
  Link as ChakraLink,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Colours from "../themes/colours";

export const Work = ({ silverToggle }) => {
  var UniWorkPageLink = "/Projects";
  return (
    <Box w={"full"} name="work">
      <VStack align={"start"} spacing="15px" w={"inherit"}>
        <Heading fontSize="2.25rem" color={silverToggle}>
          Recent Work
        </Heading>
        <Text
          textTransform={"uppercase"}
          fontSize={{ base: "15px", md: "20px" }}
          color={Colours.peru}
          fontWeight={"extrabold"}
        >
          Some of my recent work
        </Text>
        <RecentCards />
        <VStack
          align={{ base: "center", md: "start" }}
          w="inherit"
          pl={{ md: "10px" }}
        >
          <ChakraLink
            as={Link}
            bg={"#E5712F"}
            rounded="7px"
            px={{ base: "20px", md: "30px" }}
            py={{ base: "10px", md: "14px" }}
            _hover={{ border: "none", color: "whiteAlpha.700" }}
            to={UniWorkPageLink}
          >
            <HStack>
              <Text>View More</Text>
              <HiArrowNarrowRight />
            </HStack>
          </ChakraLink>
        </VStack>
      </VStack>
    </Box>
  );
};
