import { extendTheme } from "@chakra-ui/react"


const theme = extendTheme({
    fonts: {
        body: `'M PLUS Rounded 1c', sans-serif`,
    }
})


export default theme