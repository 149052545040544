import {
  IconButton,
  useColorMode,
  Button,
  useColorModeValue,
  Text,
  Flex,
  Link,
  Box,
  Image,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Logo_Dark from "../../assets/Logo-Darkmode.svg";
import Logo_Light from "../../assets/Logo - Lightmode.svg";
import { Link as RouterLink } from "react-router-dom";

export const IconDarkMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const iconcolortoggle = useColorModeValue("gray.800", "white");

  return (
    <IconButton
      size="md"
      onClick={toggleColorMode}
      color={`mode.${colorMode}.text`}
      borderColor={`mode.${colorMode}.text`}
      aria-label="toggle dark mode"
      icon={
        colorMode === "light" ? (
          <MoonIcon color={iconcolortoggle} />
        ) : (
          <SunIcon color={iconcolortoggle} />
        )
      }
      zIndex="sticky"
    />
  );
};

export const TextDarkMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const iconcolortoggle = useColorModeValue("gray.800", "white");

  return (
    <Button
      size="md"
      onClick={toggleColorMode}
      color={`mode.${colorMode}.text`}
      borderColor={`mode.${colorMode}.text`}
      aria-label="toggle dark mode"
      icon={
        colorMode === "light" ? (
          <MoonIcon color={iconcolortoggle} />
        ) : (
          <SunIcon color={iconcolortoggle} />
        )
      }
      zIndex="sticky"
    >
      <Flex letterSpacing={"1.5px"} p={"1"} alignItems={"center"}>
        {colorMode === "light" ? (
          <MoonIcon color={iconcolortoggle} />
        ) : (
          <SunIcon color={iconcolortoggle} />
        )}
        <Text ml={"8px"}>
          {colorMode === "light" ? "Toggle Dark Mode" : "Toggle Light Mode"}
        </Text>
      </Flex>
    </Button>
  );
};

export const LogoToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box>
      <Link as={RouterLink} to="/">
        <Image src={colorMode === "light" ? Logo_Light : Logo_Dark} />
      </Link>
    </Box>
  );
};
