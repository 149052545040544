import {
  Box,
  Heading,
  Text,
  Image,
  useColorModeValue,
  Container,
  Center,
} from "@chakra-ui/react";
import UI1 from "../../../assets/projects-imgs/the-hive/First Screenshot.png";
import UI2 from "../../../assets/projects-imgs/the-hive/Second Screenshot.png";
import DUI1 from "../../../assets/projects-imgs/the-hive/Completed Version 1.png";
import DUI2 from "../../../assets/projects-imgs/the-hive/Completed Version 2.png";
export const WebsiteDesign = () => {
  return (
    <Box mt={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 7
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Website Design
      </Heading>
      <Box display={{ md: "flex", base: "block" }}>
        <Box px={"10px"}>
          <Text>
            Upon making the website for the project. I began making a wireframe
            within Figma.
          </Text>
          <Text my={"10px"}>
            For creating the style of the landing page, I researched and looked
            through many tech websites that promote their device and see how
            they did it. The navigation is very simple since it doesn&#39;t
            really need to stand out as it would drive the attention of the user
            away from the main product.
          </Text>
          <Text>
            The focus of the landing page was the section below the navigation.
            This would give the user the first look into the device and its
            style. The placement of the placeholder image would be where a
            render of the device will be. Next to it is the description of the
            device with a button saying, &#39;Pre-order now&#39;.
          </Text>
          <Text my={"10px"}>
            On the right-hand side of the page, is a honeycomb shape that I
            created using a bunch of hexagons. Originally, I was going to have a
            massive version of the honeycomb at the top-left hand side of the
            landing that would animate in as the webpage loaded in. however I
            decided against as the design was not going to flow with the rest of
            the design I was going for within the page. As for the middle
            section of the page below the viewport, I created a zig-zag pattern
            with the cards to give it some variety. Within each card, I included
            an image for the corresponding page that the link will take the user
            to
          </Text>
        </Box>
        <Image w={"400px"} rounded={"md"} src={UI1} />
      </Box>
      <Box mt={"10px"} display={{ md: "flex", base: "block" }}>
        <Image src={UI2} w={"400px"} rounded={"md"} />
        <Box px={"10px"}>
          <Text>
            Below the cards section, I included a quick about us section for the
            user to read. This would briefly state the reason why we created
            this device and what we plan to do with the percentage that is going
            to charity.
          </Text>
          <Text my={"10px"}>
            Just above the quick info section is another one of the honeycomb
            patterns but on the left-hand side, that is not visible in the
            screenshots.
          </Text>
          <Text>
            Up next is the application section that we did have a plan for but
            due to the time constraint that we had with the project, I was not
            able to build the application. However, at some point, I do plan to
            build the application for the learning experience within a language
            such as Java or Swift. The application will be linked on this
            webpage when it is developed.
          </Text>
          <Text my={"10px"}>
            Right below is just another text promoting the device even more.
          </Text>
          <Text>
            Then there&#39;s the footer. The footer is a very basic design same
            as the navigation otherwise if the footer was fully animated then it
            would take the users attention from the main product on the webpage.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export const ColourScheme = () => {
  return (
    <Box mt={"20px"}>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={useColorModeValue("green.600", "green.700")}
          fontWeight={"extrabold"}
        >
          Section 8
        </Text>
        <Heading
          letterSpacing={"1px"}
          fontSize={{ base: "2xl", md: "3xl" }}
          borderBottom={"3px solid #e5712f"}
          display={"inline-block"}
        >
          Colour Scheme
        </Heading>
      </Box>

      <Box mt={"10px"}>
        <Text>
          James created a colour scheme for us to use within the project. I
          applied that colour scheme within the website design whilst following
          the 60/30/10 rule to keep the webpage minimal but modern at the same
          time. The three colours that James selected for us are:
        </Text>
        <Container
          bg={useColorModeValue("gray.200", "blackAlpha.400")}
          rounded={"md"}
          display={"flex"}
          justifyContent={{ base: "space-between", md: "flex-start" }}
          p={"10px"}
        >
          <Box display={"block"} w={"33%"}>
            <Text color={"#e5712f"}>#FFF</Text>
            <Box w={"100%"} h={"33px"} bg={"#fff"} rounded={"sm"} />
          </Box>
          <Box display={"block"} px={"10px"} w={"33%"}>
            <Text color={"#e5712f"}>#FA9C1D</Text>
            <Box w={"100%"} h={"33px"} rounded={"sm"} bg={"#FA9C1D"} />
          </Box>
          <Box display={"block"} w={"33%"}>
            <Text color={"#e5712f"}>#E4901E</Text>
            <Box w={"100%"} h={"33px"} bg={"#E4901E"} rounded={"sm"} />
          </Box>
        </Container>
      </Box>
      <Text>
        The base colour that I used was White mainly since it works best when
        the orange is a sub colour and the active colour as well. All buttons
        within the webpage will have the lighter version of orange and then the
        darker version will be used for hover effects or drop-shadows for cards.
      </Text>
      <Text mt={"10px"}>Here are some Screenshots using the colour scheme</Text>
      <Box
        display={{ base: "block", md: "flex" }}
        justifyContent={"center"}
        gap={"40px"}
        w={"100%"}
      >
        <Box w={{ md: "40%" }}>
          <Image src={DUI1} roundedTop={"md"} />
        </Box>
        <Box w={{ md: "40%" }}>
          <Image src={DUI2} roundedBottom={"md"} />
        </Box>
      </Box>
    </Box>
  );
};



