import { Box, Heading, Link, Text, useColorModeValue } from "@chakra-ui/react";

export const HiveRef = () => {
  return (
    <Box mt={"10px"}>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={useColorModeValue("green.600", "green.700")}
          fontWeight={"extrabold"}
        >
          Section 10
        </Text>
        <Heading
          letterSpacing={"1px"}
          fontSize={{ base: "2xl", md: "3xl" }}
          borderBottom={"3px solid #e5712f"}
          display={"inline-block"}
        >
          References
        </Heading>
      </Box>

      <Box>
        <Text>
          Valve &#40;2021&#41; Steam Deck. Available at
          <Link
            color={"#e5712f"}
            px={"4px"}
            href="https://www.steamdeck.com/en/"
            target={"_blank"}
          >
            https://www.steamdeck.com/en/
          </Link>{" "}
          &#40;Accessed: 10/02/2022&#41;
        </Text>
        <Text my={"20px"}>
          Nintendo &#40;2018&#41; Nintendo Switch - OLED Model. Available at
          <Link
            color={"#e5712f"}
            px={"4px"}
            href="https://www.nintendo.com/switch/ "
            target={"_blank"}
          >
            https://www.nintendo.com/switch/
          </Link>{" "}
          &#40;Accessed: 10/02/2022&#41;
        </Text>
        <Text>
          Apple &#40;1997&#41; Apple &#40;United Kingdom&#41;. Available at
          <Link
            color={"#e5712f"}
            px={"4px"}
            href="https://www.apple.com/uk/"
            target={"_blank"}
          >
            https://www.steamdeck.com/en/
          </Link>{" "}
          &#40;Accessed: 11/02/2022&#41;
        </Text>
      </Box>
    </Box>
  );
};
