import React from "react";
import {
  Image,
  Text,
  useColorModeValue,
  Box,
  Tag,
  Link as ChakraLink,
  VStack,
  Heading,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  CardData,
  ResumeEducationData,
  ResumeExperienceData,
  ResumeResponsibilities,
  WorkCardData,
} from "../../Data/WorkData.d";
import { Link } from "react-router-dom";
import Colours from "../../themes/colours";

export const RecentCards = () => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");
  let mainText = useColorModeValue("gray.800", "white");
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={"30px"}>
      {WorkCardData &&
        WorkCardData.map((data) => {
          return (
            <ChakraLink
              href={data.link}
              target={data.targetblank ? "blank" : ""}
              _hover={{ border: "none" }}
            >
              <Box bg={boxBg} rounded="20px" w="full" h="full">
                <Box px="1rem" py="1rem" mx={"auto"}>
                  <VStack align="start" spacing={"10px"}>
                    <Heading
                      fontSize={"xl"}
                      color={mainText}
                      fontWeight="semibold"
                    >
                      {data.title}
                    </Heading>
                    <HStack justifyContent={"space-between"} w="full">
                      <HStack>
                        {data.tag1displayed ? (
                          <Tag fontSize={{base: 11, md: 14}} bg={"#E5712F"}>{data.tag1}</Tag>
                        ) : (
                          <Tag display={"none"} />
                        )}
                        <Tag bg="#E5712F" fontSize={{base: 11, md: 14}}>{data.tag2}</Tag>
                      </HStack>
                      <HStack>
                        <Tag fontSize={{base: 11, md: 14}}>{data.type}</Tag>
                        <Tag fontSize={{base: 11, md: 14}}>{data.language}</Tag>
                      </HStack>
                    </HStack>

                    <Image src={data.bgImage} rounded="10px" />

                    <Text fontWeight={"medium"} fontSize="16px">
                      {data.content}
                    </Text>
                    <ChakraLink
                      bg={"#E5712F"}
                      rounded="7px"
                      p={"7px"}
                      href={data.link}
                      _hover={{ border: "none", color: "whiteAlpha.700" }}
                    >
                      View work
                    </ChakraLink>
                    <Heading as="h6" size={"xs"}>
                      Edited: {data.lastModified}
                    </Heading>
                  </VStack>
                </Box>
              </Box>
            </ChakraLink>
          );
        })}
    </SimpleGrid>
  );
};

export const WorkCards = () => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");
  let mainText = useColorModeValue("gray.800", "white");
  const errorMsgColor = useColorModeValue("red.800", "red.400");
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={"30px"}>
      {CardData &&
        CardData.map((data) => {
          return (
            <ChakraLink as={Link} to={data.Link} _hover={{ border: "none" }}>
              <Box bg={boxBg} rounded="20px" w="full" h="full">
                <Box px="1rem" py="1rem" mx={"auto"}>
                  <VStack align="start" spacing={"10px"}>
                    <Heading
                      fontSize={"xl"}
                      color={mainText}
                      fontWeight="semibold"
                    >
                      {data.title}
                    </Heading>
                    <HStack justifyContent={"space-between"} w="full">
                      <HStack>
                        {data.tag1displayed ? (
                          <Tag bg={"#E5712F"}>{data.tag1}</Tag>
                        ) : (
                          <Tag display={"none"} />
                        )}
                        <Tag bg="#E5712F">{data.tag2}</Tag>
                      </HStack>
                      <HStack>
                        <Tag>{data.type}</Tag>
                        <Tag>{data.language}</Tag>
                      </HStack>
                    </HStack>
                    <Text color={errorMsgColor} fontWeight="600">
                      {data.errorMessage}
                    </Text>
                    <Image src={data.bgImage} rounded="10px" />

                    <Text fontWeight={"medium"} fontSize="16px">
                      {data.content}
                    </Text>
                    <ChakraLink
                      as={Link}
                      bg={"#E5712F"}
                      rounded="7px"
                      p={"7px"}
                      to={data.Link}
                      _hover={{ border: "none", color: "whiteAlpha.700" }}
                    >
                      View work
                    </ChakraLink>
                    <Heading as="h6" size={"xs"}>
                      Edited: {data.lastModified}
                    </Heading>
                  </VStack>
                </Box>
              </Box>
            </ChakraLink>
          );
        })}
    </SimpleGrid>
  );
};

export const ResumeExperinceCard = ({
  role,
  company,
  startYear,
  endYear,
  content,
  children,
}) => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");
  let mainText = useColorModeValue("gray.800", "white");
  let tagbg = useColorModeValue("gray.300", "gray.800");
  return (
    <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} spacing={"30px"}>
      <Box bg={boxBg} rounded="20px" w="full" h="full">
        <Box px="1rem" py="1rem" mx={"auto"}>
          <VStack align="start" spacing={"10px"}>
            <HStack justifyContent={"space-between"} w="full">
              <Heading fontSize={"xl"} color={mainText} fontWeight="semibold">
                {role}
              </Heading>
              <HStack>
                <Tag bg={tagbg}>Start: {startYear}</Tag>
                <Tag bg={tagbg}>End: {endYear}</Tag>
              </HStack>
            </HStack>
            <Tag bg={"#E5712F"}>{company}</Tag>
            <Text fontWeight={"medium"} fontSize="16px">
              {content}
            </Text>
            {children}
          </VStack>
        </Box>
      </Box>
    </SimpleGrid>
  );
};
