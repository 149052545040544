import {
  Box,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useColorModeValue,
  Text,
  Heading
} from "@chakra-ui/react";
import {
  SiVisualstudiocode,
  SiFigma,
  SiGithub,
  SiXcode,
  SiGooglechrome,
  SiFilezilla,
} from "react-icons/si";

export const SoftwareList = () => {
  return (
    <Box mt={"10px"}>
      <Text
            textTransform={"uppercase"}
            fontSize={"13px"}
            color={useColorModeValue("green.600", "green.700")}
            fontWeight={"extrabold"}
          >
            Section 4
          </Text>
          <Heading
            letterSpacing={"1px"}
            fontSize={{ base: "2xl", md: "3xl" }}
            borderBottom={"3px solid #e5712f"}
            display={"inline-block"}
          >
            Software Used
          </Heading>
          <Text>I have used a varity of software throughout the project.</Text>
      <Wrap spacingX={"-20px"} mt={'15px'}>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiVisualstudiocode} />
            <TagLabel>Visual Studio Code</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiFigma} />
            <TagLabel>Figma</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiGithub} />
            <TagLabel>Github</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiXcode} />
            <TagLabel>XCode</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiGooglechrome} />
            <TagLabel>Google Chrome</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={'default'}>
            <TagLeftIcon as={SiFilezilla} />
            <TagLabel>Filezilla</TagLabel>
          </Tag>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export const SoftwareDescription = () => {
  return (
    <Box mt={"20px"} maxW={"800px"}>
      {/* Card 1 */}
      <Box
        mb={"15px"}
        bg={useColorModeValue("gray.100", "blackAlpha.300")}
        p={"5px"}
        rounded={"md"}
      >
        <Tabs>
          <TabList flex={"1"} flexWrap={"wrap"} fontWeight={"extrabold"}>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Visual Studio Code
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Figma
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              XCode
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Github
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Google Chrome
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              FileZilla
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              Visual Studio Code &#45; I chose Visual Studio Code mainly due it
              being my most knowledgeable software at the time, and I knew/know
              most of the key bindings for shortcuts and it's very user
              friendly. However, for the User interface for the device, I had to
              switch to XCode.
            </TabPanel>
            <TabPanel>
              Figma &#45; I used Figma to design the User interface and Website
              layout along with James and Michael. We did it on my system so I
              would have access to the project designs. Figma allows other users
              to collaborate on a project and I connected James and Michael to
              the project.
            </TabPanel>
            <TabPanel>
              XCode &#45; I used XCode to create the user interface mainly due
              to the compatibility with pushing test applications to IOS devices
              for testing and debugging then with Android Studio. Also, I had
              more knowledge of using Swift than I did with Java.
            </TabPanel>
            <TabPanel>
              GitHub &#45; GitHub was used to allow to me to control the version
              of the files, save them to the cloud so no progress would be lost,
              but the main reason is to utilise GitHub pages to host the
              website. I could have used Netlify, however that would require a
              few more settings, so when I created the website, my knowledge of
              Netlify was non-existent but If was to do it again, I would use
              the skills I have learnt from more recent projects such as, React,
              Redux , Chakra-UI, Tailwind CSS and Netlify.
            </TabPanel>
            <TabPanel>
              Google Chrome &#45; I used Google Chrome to debug and test the
              website whilst I was working on it. Google chrome is my main
              browser, and it has some of the best debugging tools to offer for
              web development.
            </TabPanel>
            <TabPanel>
              FileZilla &#45; I utilised FileZilla to allow me to transfer the
              file between my laptop and the server that the website was hosted
              on. Despite I mentioned that I used GitHub Pages to host the
              website, I hosted them on my personal portfolio for quick access
              to travel to them. Since my knowledge has advanced since the
              project, I would not use FileZilla or any FTP and just GitHub and
              just host them through Netlify
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
