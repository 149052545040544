import React from "react";
import { FaGithub, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { BsBarChartFill, BsFillPersonLinesFill } from "react-icons/bs";
import { Link, Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SiGithub, SiInstagram, SiLinkedin, SiTwitter } from "react-icons/si";

export const Footer = ({ silverToggle, onClose, background, textcolor }) => {
  return (
    <VStack bg={background} color={textcolor} py={"2rem"}>
      <HStack spacing={"20px"} display={{ base: "flex", md: "none" }}>
        <Link
          href="https://www.linkedin.com/in/bradlee-king-514991163/"
          target={"_blank"}
        >
          <SiLinkedin size={30} color={silverToggle} />
        </Link>
        <Link href="https://github.com/kingofbrad" target="_blank">
          <SiGithub size={30} color={silverToggle} />
        </Link>
        <Link href="https://twitter.com/Bradlee47125130" target="_blank">
          <SiTwitter size={30} color={silverToggle} />
        </Link>
        <Link href="https://www.instagram.com/bradlee_king/" target={"_blank"}>
          <SiInstagram size={30} color={silverToggle} />
        </Link>
        <Link as={RouterLink} to="/resume" onClick={onClose}>
          <BsFillPersonLinesFill size={30} color={silverToggle} />
        </Link>
      </HStack>
      <Text align="center">&copy; Copyright: Bradlee King 2024</Text>
    </VStack>
  );
};
