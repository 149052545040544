import React from "react";
import { Box, VStack, HStack, Text, Heading, Link, Tag, useColorModeValue, useColorMode, SimpleGrid, Image, Tab } from "@chakra-ui/react";
import GithubLogoLight from "../../assets/GitHub-Light.png";
import GithubLogoDark from "../../assets/GitHub_Dark.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import { BsArrowReturnRight } from "react-icons/bs";
import { SiVisualstudiocode, SiArduino } from "react-icons/si";
import SculkSensorCoverArt from "../../assets/projects-imgs/Sculk-Sensor/Sculk-Sensor-in-Minecraft.jpg"
import ESP32WiringLayout from "../../assets/projects-imgs/Sculk-Sensor/ESP32-Ultrasonic-Sensor-Wiring-Fritzing-Diagram.webp";
import {
    ContentItem,
    ContentListItem,
    ReferenceCell,
    UnshackledImage,
} from "./Unshackled";
import MyCodeComponent from "../../components/CodeBlock";
export const SculkSensor = ({ bg, tc }) => {
    return (
        <React.Fragment>
            <Box
                onLoad={() => {
                    window.scrollTo({ top: 0, left: 0 });
                }}
                name="SculkSensor"
                h={"100%"}
                w={"full"}
                justifyContent={"center"}
                bg={bg}
                color={tc}
                fontWeight={"semibold"}
            >
                <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
                    <VStack align={"start"}>
                        <Text
                            fontSize={"15px"}
                            color={"#E5712F"}
                            textTransform={"uppercase"}
                            fontWeight={"extrabold"}
                            letterSpacing={"1.5px"}
                            
                        >
                            DM2111: Development Focus A 22/23 (S1)
                        </Text>
                        <Heading
                            letterSpacing={"1px"}
                            fontSize={{ base: "4xl", md: "5xl" }}
                            display={"inline-block"}
                            textTransform={"uppercase"}
                        >
                            Sculk Sensor Project
                        </Heading>
                        <HStack>
                            <Tag bg={"#E5712F"}>Individual Project</Tag>
                            {/* <Tag bg={"#E5712F"}>
                                <Link
                                    href="https://github.com/kingofbrad/Unshackled---Mobile-Project"
                                    target={"_blank"}
                                    _disabled={true}
                                    
                                >
                                    Link to Github
                                </Link>
                            </Tag> */}
                        </HStack>
                    </VStack>
                    <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
                        <VStack>
                            <Image
                                src={SculkSensorCoverArt}
                                borderRadius={{ base: "10px", md: "15px" }}
                            />
                            <Tag>Credits: Bradlee King</Tag>
                        </VStack>
                        <TableofContents />
                    </VStack>
                    <Section1 />
                    <Section2 />
                </Box>
            </Box>
        </React.Fragment>
    )
}


const TableofContents = () => {
    const { logoToggle, setLogoToggleMode } = useColorMode();
    const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

    return (
        <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
            <SimpleGrid
                columns={{ base: "1", md: "2", lg: "4" }}
                spacing={"20px"}
                name="contents"
                fontSize={"15px"}
            >
                <ContentItem title="Brief">
                    This is a device that is inspired by a item in the popular game Minecraft called the Sculk Sensor that takes a physical device and connects to a Amazon Alexa or Apple Homepod

                </ContentItem>
                <ContentItem title="End Goal">
                    The end goal of the project is to have a device that will take in certain range of decibel and then trigger an Amazon Alexa or the Apple Homepod with the sounds the sculk sensor in Minecraft makes
                </ContentItem>
                <ContentItem title="Software">
                    <VStack align={"start"}>
                        <HStack>
                            <SiVisualstudiocode size={18} />
                            <Text>
                                Visual Studio Code
                            </Text>
                        </HStack>
                        <HStack>
                            <SiArduino size="20px" />
                            <Text>Arduino IDE</Text>
                        </HStack>
                    </VStack>
                </ContentItem>
                <ContentItem title="Hardware">
                    <ContentListItem title="ESP32" />
                    <ContentListItem title="HC-SR04 Ultrasonic Sensor" />
                    <ContentListItem title="Amazon Alexa" />
                </ContentItem>
                <ContentItem title="Research & Prep">
                    <ContentListItem title="1.0 What is this project" />
                    <ContentListItem title="1.1 Initial concepts" />
                    <ContentListItem title="1.2 Target Audience" />
                    <ContentListItem title="1.3 Research | Arduino" />
                    <ContentListItem title="1.4 Research | AWS Lamba " />
                    <ContentListItem title="1.5 Research | AWS IOT " />
                </ContentItem>
                <ContentItem title={"Development"}>
                    <ContentListItem title="2.0 Setting up" />
                    <ContentListItem title="2.1 Wiring up the ESP32" />
                    <ContentListItem title="2.2 Connecting the ESP32 to AWS IOT" />
                    <ContentListItem title="2.3 Establish connection between IOT and Lamba" />
                    <ContentListItem title="2.4 Set up the Skill for the Alexa" />
                    <ContentListItem title="2.5 Testing and Debugging" />
                </ContentItem>
                <ContentItem title="Final Results">
                    <ContentListItem title="3.0 Final Outcome" />
                    <ContentListItem title="3.1 What has been accomplished" />
                    <ContentListItem title="3.2 Time Management" />
                    <ContentListItem title="3.3 Conclusion" />
                    <ContentListItem title="3.5 References" />
                </ContentItem>
            </SimpleGrid>
        </Box>
    );
};

const Section1 = () => {
    return (
        <VStack align={"start"} marginTop={"20px"} name="section1">
            <Text
                textTransform={"uppercase"}
                fontSize={"13px"}
                color={"#E5712F"}
                fontWeight={"extrabold"}
            >
                Section 1 Research & Prep
            </Text>
            <SectionHeading title="What is the project" />
            <Text>
                At the beginning of the semester, we were given a range of client projects to choose from, however, no projects catered to what I wanted to advance my skillset in, so I decided to do an individual project on my own. This project was an idea I had for a time before making this a project for university.
            </Text>
            <Text>
                The project is a creation of the in-game item from the popular game Minecraft, called a sculk sensor. This item in the game takes in sound or vibrations and emits a signal which can be used by a player with Redstone or when it naturally spawns to summon a boss.
            </Text>
            <Text>
                My take on the project is to use this concept and connect an Amazon Alexa via AWS and an Esp32 using an HCSR04 and have it act as a trigger system to alert the user.
            </Text>
            <SectionHeading title="Initial Concepts" />
            <Text>
                My initial concept with this project was to use some form of audio input device to take in sound and when it reached over a certain decibel then it would communicate with Firebase and would relay the signal over to them devices and alert them, however, I decided against as I had previous experience with Firebase and I wanted to learn something new so I settled with AWS and using an Amazon Alexa.
            </Text>
            <SectionHeading title="Target Audience" />
            <Text>
                The target audience for this type of device would consist of individuals who are massively into Minecraft and technology however it’s not strictly made for them as anyone could use it but won’t fully understand the background. The age range would be between 14-25 as this is the peak age for gamers to play Minecraft.
            </Text>
            <SectionHeading title="Research | Ardunio" />
            <Text>
                Upon moving into this project, I had to further my knowledge of the ESP32 as I had some background information regarding the device. However last time I used the ESP32, it was a simple webhook to connect to a SQL database and decipher a JSON file. In this project, I had to do furthermore research as I used AWS to connect the ESP32 to an Amazon Alexa via two different services.
            </Text>
            <Text>
                AWS provides all the necessary research I needed to set up the connection between the esp32 and AWS IOT CORE which is the service that allows external devices to be connected to the servers.
            </Text>
            <SectionHeading title="Research | AWS Lamba" />
            <Text>
                Coming into this project, I had little to no knowledge regarding AWS Lamba, but their documentation provided me with the required information I needed, and they also had the documentation I needed to connect the Lamba Server to the AWS IOT CORE which allowed me to communicate to the ESP32.
            </Text>
            <Text>
                AWS Lamba is Amazon’s version of allows the user to create a dedicated server and they host anything the user needs, for my instance, it was an Alexa Skill that could connect to the ESP32 and perform the action I needed.
            </Text>
            <SectionHeading title="Research | AWS IOT" />
            <Text>
                Similar to AWS Lamba, I also did not know AWS IOT Core, however after reading through all the documentation provided by AWS, I gained a better understanding of the service. AWS IOT Core is a managed cloud service that allows the user to connect devices easily and securely with their cloud applications.
            </Text>
            <Text>
                This became a vital part of the project it would allow me to have the connection to the ESP32 and let it communicate to the AWS Lamba server and the Alexa Skill.
            </Text>
        </VStack>
    )
}

const Section2 = () => {
    return (
        <VStack align={"start"} name="section2" mt={"20px"} >
            <Text
                textTransform={"uppercase"}
                fontSize={"13px"}
                color={"#E5712F"}
                fontWeight={"extrabold"}
            >
                Section 2 Development
            </Text>
            <SectionHeading title="Setting Up" />
            <Text>
                Before doing any type of development, I needed to make sure I had all the AWS accounts set up and they were all connected. By following the AWS documentation on connecting the IOT Core to the Lamba server, I successfully managed to secure the connection.
                Along with setting them up, I also did the Amazon Alexa skill to get it ready for when I need to use it.
            </Text>
            <SectionHeading title="Wiring up the ESP32" />
            <Text>
                I started by wiring up the ESP32 to an HCSR04 Ultrasonic sensor which was used as my input device as I had no type of audio input device available to be used on the ESP32.
                As I didn’t know much about circuits and the layout I needed. I used a layout schematic provided by a random website called “randomnerdtutorials.com”. This website gave me the precise layout I needed for the wiring. Here is the layout below.
            </Text>
            <UnshackledImage
                src={ESP32WiringLayout}
                alt="Table Creation Within SQL"
                ImgRev="1.0.0"
                credits="randomnerdtutorials"
            />

            <Text>
                I used a combination of the code they provided to read from the HC-SR04 and my own to allow the AWS IOT Core to read it as well.
            </Text>
            <MyCodeComponent language="c" code={ESP32HCSR04ConnectionToAWS} />
            <Text>
                The above code snippet is the connection Certificate file that is used to connect the ESP32 to AWS IOT, this is stored in a secret separate file to maintain security and integrity as this file holds all the keys to gain access to the AWS account.
            </Text>
            <Text>
                This was provided via the AWS documentation on how to connect the ESP32 to AWS IOT CORE
            </Text>
            <SectionHeading title="Connecting the ESP32 to AWS IOT" />
            <MyCodeComponent language="c" code={ESP32AWSConnection} />
            <Text>
                This code snippet is the base premise of how the HCSR04 and the ESP32 will connect and send data to AWS IoT to be received by the AWS Lambda Server.
            </Text>
            <SectionHeading title="Establish connection between IOT and Lamba" />
            <Text>
                Once I had the connection to the AWS IOT Core set up and ready from the ESP32 side it was time to allow the IOT COre to communicate with the Lambda server set up for the Alexa Skill.
            </Text>
            <Text>
                This is accomplished by setting up certain rule within IOT Core and converting the data provided from the esp32 into a message payload that can be read within a SQL-based setting. AWS Lambda can read the SQL database that comes created with the IOT Core, then the 
            </Text>
        </VStack>
    )
}


const SectionHeading = (props) => {
    return (
        <Heading
            letterSpacing={"1px"}
            fontSize={{ base: "2xl", md: "3xl" }}
            display={"inline-block"}
            name="Client Brief"
            paddingTop={"10px"}
            paddingBottom={"10px"}
        >
            {props.title}
        </Heading>
    );
};





let ESP32HCSR04ConnectionToAWS =
    `
#include <pgmspace.h>

#define SECRET
#define THINGNAME ""

const char WIFI_SSID[] = "";
const char WIFI_PASSWORD[] = "";
const char AWS_IOT_ENDPOINT[] = "xxxxx.amazonaws.com";

// Amazon Root CA 1
static const char AWS_CERT_CA[] PROGMEM = R"EOF(
-----BEGIN CERTIFICATE-----
-----END CERTIFICATE-----
)EOF";

// Device Certificate
static const char AWS_CERT_CRT[] PROGMEM = R"KEY(
-----BEGIN CERTIFICATE-----
-----END CERTIFICATE-----
)KEY";

// Device Private Key
static const char AWS_CERT_PRIVATE[] PROGMEM = R"KEY(
-----BEGIN RSA PRIVATE KEY-----
-----END RSA PRIVATE KEY-----
)KEY";
`;

let ESP32AWSConnection =
    `
#include "secrets.h"
#include <WiFiClientSecure.h>
#include <MQTTClient.h>
#include <ArduinoJson.h>
#include "WiFi.h"

const int trigPin = 5;
const int echoPin = 18;

//define sound speed in cm/uS
#define SOUND_SPEED 0.034
#define CM_TO_INCH 0.393701

long duration;
float distanceCm;
float distanceInch;

#define AWS_IOT_PUBLISH_TOPIC   "esp32/pub"
#define AWS_IOT_SUBSCRIBE_TOPIC "esp32/sub"

WiFiClientSecure net = WiFiClientSecure();
MQTTClient client = MQTTClient(256);

void connectAWS()
{
  WiFi.mode(WIFI_STA);
  WiFi.begin(WIFI_SSID, WIFI_PASSWORD);

  Serial.println("Connecting to Wi-Fi");

  while (WiFi.status() != WL_CONNECTED){
    delay(500);
    Serial.print(".");
  }

  // Configure WiFiClientSecure to use the AWS IoT device credentials
  net.setCACert(AWS_CERT_CA);
  net.setCertificate(AWS_CERT_CRT);
  net.setPrivateKey(AWS_CERT_PRIVATE);

  // Connect to the MQTT broker on the AWS endpoint we defined earlier
  client.begin(AWS_IOT_ENDPOINT, 8883, net);

  // Create a message handler
  client.onMessage(messageHandler);

  Serial.print("Connecting to AWS IOT");

  while (!client.connect(THINGNAME)) {
    Serial.print(".");
    delay(100);
  }

  if(!client.connected()){
    Serial.println("AWS IoT Timeout!");
    return;
  }

  // Subscribe to a topic
  client.subscribe(AWS_IOT_SUBSCRIBE_TOPIC);

  Serial.println("AWS IoT Connected!");
}

void publishMessage() {
    StaticJsonDocument<200> doc;
    doc["distance"] = distanceInch
    char jsonBuffer[512];
    serializeJson(doc, jsonBuffer);

    client.publish(AWS_IOT_PUBLISH_TOPIC, jsonBuffer);
}

void setup() {
    Serial.begin(9600);
    connectAWS()
    pinMode(trigPin, OUTPUT);
    pinMode(echoPin, INPUT);
}
void loop() {
    digitalWrite(trigPin, Low);
    delayMicroseconds(2);
    digitalWrite(trigPin, HIGH);
    delayMicroseconds(10);
    digitalWrite(trigPin, LOW);

    duration = pulseIn(echoPin, HIGH);
    distanceCm = duration * SOUNDSPEED/2;
    distanceInch = distanceCm = CM_TO_INCH;

    publishMessage();
    client.loop();
    delay(1000)


}
void messageHandler(String &topic, String &payload) {
    Serial.println("incoming: " + topic + " - " + payload);
  
  //  StaticJsonDocument<200> doc;
  //  deserializeJson(doc, payload);
  //  const char* message = doc["message"];
  }

`;