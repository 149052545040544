import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import Navbar from "../Pages/navbar";
import { Outlet } from "react-router";
import { Footer } from "../Pages/footer";

export const Root_Layout = () => {
  const background = useColorModeValue("white", "#19191B");
  const textcolor = useColorModeValue("gray.700", "gray.100");
  return (
    <>
      <Box>
        <Navbar background={background} textcolor={textcolor} />
        <Outlet />
        <Footer background={background} textcolor={textcolor} />
      </Box>
    </>
  );
};
