import {
  Box,
  HStack,
  Text,
  Image,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
  chakra,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React from "react";
import finalYearProjectImage from "../../assets/projects-imgs/Dissertation/MainWindow.png";
import showMenuOption from "../../assets/projects-imgs/Dissertation/Show Menu Option.png";
import PDF from "../../assets/projects-imgs/Dissertation/The Impact of Artificial Intelligence within the development workplace - Case Study - Bradlee King - Updated.pdf";
import { BsFilePdf } from "react-icons/bs";
import { ReferenceCell } from "./Unshackled";
const FinalYearProject = ({ bg, tc }) => {
  return (
    <React.Fragment>
      <Box
        name="FinalYearProject"
        h={"100%"}
        w={"full"}
        bg={bg}
        color={tc}
        justifyContent={"center"}
        fontWeight={"semibold"}
      >
        <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"}>
            <Text
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              DM3107: Major Research Project A 23/24 (S1/S2)
            </Text>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              Xcode Pilot
            </Heading>
            <HStack>
              <Tag bg={"#E5712F"}>Individual Project</Tag>
              <Tag>Word Count: 2000</Tag>
              <Tag>
                <a href={PDF} target="_blank">
                  <HStack>
                    <BsFilePdf />
                    <Text>Case Study - Research Proposal Report</Text>
                  </HStack>
                </a>
              </Tag>
            </HStack>
          </VStack>
          <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
            <VStack>
              <Image
                src={finalYearProjectImage}
                borderRadius={{ base: "10px", md: "15px" }}
              />
              <Tag>Credits: Bradlee King</Tag>
            </VStack>
          </VStack>
          <VStack name="section1" align="start" marginTop="20px">
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Introduction
            </Text>
            <Text>
              In this project, my goal is to create an Xcode extension that
              integrates AI services like GitHub Copilot to assist iOS
              developers. This extension aims to help developers debug their
              code and explain complex sections they may not understand,
              providing examples without fully writing the code for them.
            </Text>
            <Text>
              By leveraging AI, this tool seeks to enhance developers'
              productivity and comprehension while maintaining their active
              involvement in the coding process. The integration of AI in
              software development has seen significant advancements, offering
              tools that streamline workflows, automate repetitive tasks, and
              foster innovation.
            </Text>
            <Text>
              However, the adoption of AI also brings challenges such as data
              privacy concerns, potential job displacement, and ethical
              implications. This reflective piece examines both the benefits and
              challenges of integrating AI into iOS development, offering
              insights into its potential to support and transform the developer
              experience. Through real-world applications and developer
              feedback, this study aims to present a balanced view of AI's role
              in the future of iOS development.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Software Used
            </Text>
            <Text>
              For this project, I selected Xcode and GitHub Copilot. Xcode
              serves as the development environment for creating the extension,
              leveraging its robust set of features tailored specifically for
              iOS development.
            </Text>
            <Text>
              It provides the necessary tools and frameworks to build, test, and
              debug the extension effectively. GitHub Copilot, on the other
              hand, provides the AI capabilities that power the extension.
              Copilot uses advanced machine learning algorithms to offer code
              suggestions, automated error detection, and contextual
              explanations, making it an ideal choice for enhancing the
              developer's workflow.
            </Text>
            <Text>
              This combination of Xcode and GitHub Copilot ensures a seamless
              integration of AI into the iOS development process.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Research Topics
            </Text>
            <Text>
              Leading into this project, a comprehensive case study report was
              conducted, exploring the wide range of AI services available to
              developers. Both secondary and primary research were undertaken to
              gain a deeper understanding of several key topics:
            </Text>
            <Text color={"gray.400"}>
              Integration of AI in Code Debugging: Investigating how AI can
              streamline the debugging process, improve efficiency, and reduce
              the time developers spend on identifying and fixing errors.
            </Text>
            <Text color={"gray.400"}>
              Enhancing Code Comprehension: Exploring the role of AI in
              providing contextual explanations and examples that help
              developers understand complex code sections, facilitating learning
              and skill development.
            </Text>
            <Text color={"gray.400"}>
              Ethical Considerations: Examining the ethical implications of AI
              use, including data privacy issues, potential biases in AI
              algorithms, and the risk of job displacement within the industry.
            </Text>
            <Text color={"gray.400"}>
              User Experiences with AI Tools: Assessing the effectiveness and
              limitations of existing AI tools like GitHub Copilot based on
              feedback from developers, highlighting areas for improvement and
              potential benefits.
            </Text>
            <Text color={"gray.400"}>
              Collaboration and Knowledge Sharing: Analyzing how AI can foster
              collaboration among developers by providing shared insights and
              recommendations, enhancing team productivity and knowledge
              dissemination.
            </Text>
            <Text>
              These topics provided a foundational understanding that informed
              the development of the Xcode extension, ensuring it addresses
              real-world challenges and opportunities faced by iOS developers.
              This research aims to strike a balance between leveraging AI's
              capabilities and maintaining ethical standards and developer
              autonomy.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Industry Research
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Primary Research
            </Text>
            <Text>
              For primary research, I conducted interviews and surveys with iOS
              developers who have firsthand experience with AI tools like GitHub
              Copilot. The feedback from these developers was instrumental in
              understanding the practical implications of AI integration.
            </Text>
            <Text>
              Many developers reported that AI tools significantly enhanced
              their productivity by automating repetitive tasks, such as code
              completion and error checking. Additionally, AI was noted for its
              ability to assist in debugging, providing suggestions that helped
              identify and resolve issues more efficiently.
            </Text>
            <Text>
              Some developers highlighted the convenience of having AI-generated
              examples that offered different coding approaches, which not only
              sped up the development process but also served as a valuable
              learning resource.
            </Text>
            <Text>
              However, there were also concerns about the limitations of AI.
              Some developers expressed worries about over-reliance on AI,
              fearing it might lead to a decrease in critical thinking and
              problem-solving skills.
            </Text>
            <Text>
              Others pointed out that AI suggestions are not always perfect and
              require careful scrutiny to avoid introducing new errors or
              inefficiencies into the codebase.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Secondary Research
            </Text>
            <Text>
              Secondary research involved reviewing existing literature, case
              studies, and industry reports on AI in software development.
              According to several studies, AI has been a game-changer in the
              industry, particularly in areas like predictive analytics, code
              generation, and performance optimization.
            </Text>
            <Text>
              Reports from companies that have adopted AI tools highlighted a
              marked improvement in development speed and code quality. For
              instance, a case study on GitHub Copilot revealed that developers
              using the tool could complete tasks faster and with fewer errors
              compared to those who did not use AI assistance.
            </Text>
            <Text>
              Moreover, secondary research highlighted the broader impact of AI
              on the software development lifecycle. AI-powered tools are
              increasingly being used for tasks such as automated testing,
              continuous integration, and deployment, which further streamline
              the development process and reduce time to market.
            </Text>
            <Text>
              The ability of AI to analyze large datasets and identify patterns
              also contributes to more accurate and efficient performance
              optimization, ensuring that applications run smoothly and
              efficiently.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Key Findings:
            </Text>
            <Text color={"gray.400"}>
              Increased Efficiency: Both primary and secondary research
              consistently pointed to AI's role in boosting efficiency.
              Developers could delegate mundane tasks to AI, allowing them to
              focus on more complex and creative aspects of development. This
              not only speeds up the development process but also enhances the
              overall quality of the software.
            </Text>
            <Text color={"gray.400"}>
              Enhanced Code Quality: AI tools help maintain high code quality by
              providing real-time feedback and suggestions. This reduces the
              likelihood of bugs and errors in the final product. AI-powered
              code reviews and static analysis tools can detect potential issues
              early in the development cycle, saving time and resources in the
              long run.
            </Text>
            <Text color={"gray.400"}>
              Learning and Adaptation: AI systems like GitHub Copilot learn from
              the vast amounts of data they process, continually improving their
              suggestions and becoming more useful over time. This adaptability
              makes them valuable assets in a fast-evolving field like iOS
              development. Additionally, AI can help developers stay up-to-date
              with the latest coding practices and technologies by providing
              relevant examples and recommendations.
            </Text>
            <Text color={"gray.400"}>
              Ethical and Practical Concerns: Despite the benefits, there are
              significant ethical and practical concerns. Issues such as data
              privacy, the potential for job displacement, and the risk of
              decreased human expertise were prominent themes in both primary
              and secondary sources. Ensuring that AI tools are used responsibly
              and transparently is crucial to addressing these concerns.
            </Text>
            <Text color={"gray.400"}>
              Developer Experience: User experiences with AI tools were mixed.
              While many appreciated the productivity boost, others felt that AI
              could sometimes suggest incorrect or suboptimal solutions,
              requiring careful oversight. This highlights the importance of
              integrating AI tools in a way that complements and enhances human
              skills rather than replacing them.
            </Text>
            <Text>
              This research provided a nuanced understanding of AI's impact on
              iOS development. It underscored the importance of balancing AI
              integration with the need for maintaining and enhancing human
              skills and creativity in the development process.
            </Text>
            <Text>
              By addressing both the advantages and challenges of AI, developers
              can better harness its potential to improve their workflows and
              outcomes.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Project Rationale
            </Text>
            <Text>
              The bulk of my study has focused on analyzing the benefits,
              disadvantages, and various use cases of AI in the iOS development
              field. This comprehensive analysis was essential to understand the
              current landscape and identify areas where AI could be most
              effectively integrated.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Benefits:
            </Text>
            <Text>
              AI offers several advantages to iOS developers. It enhances
              productivity by automating routine tasks such as code completion,
              error detection, and debugging. AI tools like GitHub Copilot
              provide real-time suggestions and examples, which can help
              developers write more efficient and error-free code. Additionally,
              AI can assist in learning and skill development by offering
              explanations and contextual information, making it easier for
              developers to understand complex code sections.
            </Text>
            <Text>
              By reducing the cognitive load associated with repetitive tasks,
              developers can focus on more strategic and innovative aspects of
              their projects.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Disadvantages:
            </Text>
            <Text>
              Despite the numerous benefits, there are notable disadvantages to
              AI integration. Over-reliance on AI can lead to a decrease in
              critical thinking and problem-solving skills among developers.
              There are also significant ethical concerns, such as data privacy
              issues and the potential for job displacement.
            </Text>
            <Text>
              AI tools may sometimes provide incorrect or suboptimal solutions,
              necessitating careful oversight and validation by human
              developers. Furthermore, the "black box" nature of some AI
              algorithms can make it difficult to understand how certain
              recommendations are generated, leading to trust issues.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"gray.300"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Use Cases:
            </Text>
            <Text>
              Different use cases of AI in iOS development were examined to
              understand their practical applications and limitations. From
              automated testing and performance optimization to personalized
              learning and support, AI has the potential to transform various
              aspects of the development process.
            </Text>
            <Text>
              However, each use case comes with its own set of challenges and
              considerations, underscoring the need for a balanced approach to
              AI integration. For instance, while AI can greatly speed up
              testing by automating repetitive test cases, it may struggle with
              understanding the nuances of user experience and design, which
              require human intuition and creativity.
            </Text>
            <Text>
              This project rationale highlights the necessity of carefully
              weighing the pros and cons of AI use in iOS development. By doing
              so, the aim is to develop tools that enhance productivity and
              learning without compromising ethical standards or diminishing the
              value of human expertise.
            </Text>
            <Text>
              A thoughtful and measured approach to AI integration can help
              maximize its benefits while mitigating potential drawbacks.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Design Overview
            </Text>
            <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
              <VStack>
                <Image
                  src={finalYearProjectImage}
                  borderRadius={{ base: "10px", md: "15px" }}
                />
                <Image
                  src={showMenuOption}
                  borderRadius={{ base: "10px", md: "15px" }}
                />
                <Tag>Credits: Bradlee King</Tag>
              </VStack>
            </VStack>
            <Text>
              The design phase of the project involved creating detailed mockups
              to visualize the Xcode extension that integrates AI services like
              GitHub Copilot. These designs illustrate the user interface and
              key functionalities intended to assist iOS developers.
            </Text>
            <Text>
              The primary design showcases the main dashboard of the extension
              within Xcode, featuring a clean and intuitive layout. The
              dashboard includes a search function, allowing developers to
              quickly find and access relevant code snippets and solutions. The
              search results are displayed clearly, showing the relevant code
              sections and explanations.
            </Text>
            <Text>
              One section of the design highlights the creation of a user model
              and profile view using SwiftUI. It demonstrates how the extension
              provides real-time code suggestions and autocompletions, helping
              developers to write code efficiently. The code snippets are
              displayed with syntax highlighting, making it easier to read and
              understand the structure.
            </Text>
            <Text>
              The explanation panel on the right side of the dashboard provides
              detailed descriptions of the code, including the purpose and
              functionality of each section. This feature is particularly useful
              for developers who are learning new concepts or need clarification
              on specific parts of the code.
            </Text>
            <Text>
              To ensure the design is consistent with Apple's Human Interface
              Guidelines, I used elements from the Apple Figma components file.
              This includes standard UI components such as search bars, buttons,
              and text fields, which help maintain a familiar and user-friendly
              interface. These components ensure that the extension integrates
              seamlessly with the Xcode environment, providing a cohesive user
              experience (Apple Inc., n.d.).
            </Text>
            <Text>
              Despite time constraints preventing full implementation, the
              conceptual designs lay a solid foundation for future development.
              They reflect the core objectives of the project: to enhance
              productivity, provide educational support, and maintain user
              control over AI integration. By incorporating standardized design
              elements, the extension aims to deliver a professional and
              intuitive tool for iOS developers.
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={"13px"}
              color={"#E5712F"}
              fontWeight={"extrabold"}
              paddingTop={"15px"}
            >
              Critical Reflection
            </Text>
            <Text>
              In conclusion, my research and project development revealed
              multiple ways AI can be used to increase work productivity and
              help developers learn from their mistakes, as well as highlighted
              the ethical considerations of using AI within the development
              environment.
            </Text>
            <Text>
              The integration of AI tools like GitHub Copilot in iOS development
              showed significant potential in automating routine tasks,
              enhancing code quality, and providing valuable learning
              opportunities for developers.
            </Text>
            <Text>
              Through the project, I was able to gain new skills and a deeper
              understanding of both Xcode and GitHub Copilot, uncovering
              features and functionalities I was previously unaware of.
            </Text>
            <Text>
              One of the key takeaways from this project was the realization of
              AI's ability to assist in the debugging process and provide
              contextual explanations, which not only improves efficiency but
              also serves as an educational tool for developers. This dual
              benefit of productivity enhancement and learning support makes AI
              a valuable asset in the iOS development field.
            </Text>
            <Text>
              However, the project also underscored the importance of addressing
              ethical concerns, such as data privacy and the potential for AI to
              replace human judgment in critical areas of development.
            </Text>
            <Text>
              Reflecting on the project, one significant change I would consider
              is integrating the extension to read through all files in a
              project and provide suggestions for optimizations. This
              enhancement could help identify areas where code can be improved
              to run faster and more efficiently, further leveraging AI's
              capabilities to optimize performance.
            </Text>
            <Text>
              Additionally, incorporating a feature that highlights potential
              security vulnerabilities and suggests best practices for secure
              coding could be another valuable addition.
            </Text>
            <Text>
              Despite the promising findings and progress made, I was not able
              to fully complete the project due to time constraints. As a
              result, only the concept and design of the Xcode extension
              currently exist. This limitation highlights the need for careful
              project planning and time management in future endeavors. Moving
              forward, I aim to continue developing this project, implementing
              the planned features and addressing the identified challenges.
            </Text>
            <Text>
              Overall, this project has been an enlightening experience,
              providing insights into the practical applications and ethical
              implications of AI in iOS development. It has reinforced the
              importance of balancing AI integration with the need to maintain
              and enhance human skills and ethical standards. By continuing to
              refine and expand the capabilities of AI tools, we can ensure they
              remain supportive of developers' needs and contribute positively
              to the software development process.
            </Text>
          </VStack>
          <ReferenceList />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FinalYearProject;

const ReferenceList = () => {
  return (
    <Box pb="30px" paddingTop={"20px"}>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Year Project References"
      >
        References
      </Heading>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ReferenceCell
          author="Apple Inc."
          year="n.d."
          name="Human Interface Guidelines."
          link="https://developer.apple.com/design/human-interface-guidelines/"
          accessDate="06/06/2024"
        />
      </SimpleGrid>
    </Box>
  );
};
