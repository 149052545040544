import React, { useEffect, useState, useRef } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import {
  Box,
  Text,
  Heading,
  useColorModeValue,
  VStack,
  Link as ChakraLink,
  HStack,
  Image,
  Tag,
  Button
} from "@chakra-ui/react";
import { Abouts } from "./about";
import { Skill } from "./skills";
import { Work } from "./work";
import Contact, { Contacts } from "./Contact";
import Colours from "../themes/colours";
import { Link as LinkScroll } from "react-scroll";


export const Home = () => {
  useEffect(() => {
    document.title = "Bradlee King || Home";
  });

  const background = useColorModeValue("white", "#19191B");
  const silverToggle = useColorModeValue(Colours.darkGray, Colours.lightGray);
  const peru = "#E5712F";
  const darkMightnightBlue = "#003366";
  return (
    <Box name="Home" bg={background} pt={"120px"} w={"full"} h={"screen"}>
      <Box maxW="1000px" mx="auto" px="1rem" h="full">
        <VStack align={"start"} spacing={{ md: "30%", base: "20%" }}>
          <HomePage silverToggle={silverToggle} peru={Colours.peru} />

          <Divider />
          <Abouts silverToggle={silverToggle} />
          <Divider />
          <Skill silverToggle={silverToggle} />
          <Divider />
          <Work silverToggle={silverToggle} />
          <Divider />
          <Contact silverToggle={silverToggle} peru={peru} />
        </VStack>
      </Box>
    </Box>
  );
};

const HomePage = ({ silverToggle, peru }) => {
  var today = new Date();
  var curHr = today.getHours();

  let greeting;

  if (curHr >= 0 && curHr <= 12) {
    greeting = "Good Morning";
  } else if (curHr > 12 && curHr <= 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return (
    <VStack align="start" zIndex={100}>
      <Text fontWeight={"bold"} color={Colours.peru}>
        <Tag color={Colours.lightGray} fontWeight={"bold"} background={Colours.peru}>{greeting}</Tag> my name is
      </Text>

      <Heading
        fontSize={{ base: "2.25rem", sm: "4.5rem" }}
        lineHeight={{ base: "2.5rem", sm: "1" }}
        color={silverToggle}
      >
        Bradlee King
      </Heading>
      <Heading
        fontSize={{ base: "1.60rem", sm: "2.5rem" }}
        lineHeight={{ base: "2.5rem", sm: "1" }}
        color={Colours.peru}
      >
        IOS Developer + Front-End Web Developer
      </Heading>

      <Text py={"0.5rem"} maxW={"700px"} color={silverToggle}>
        Are you looking for a IOS or Front-end Web Developer, then you are in
        the right place. How would I fit within your team. Would you like a
        fully built application using Swift and SwiftUI or a Front-end website
        using React or standard Web technologies.
      </Text>
      <Text maxW={"700px"} pb={"1rem"} color={silverToggle}>
        What would you do with this type of developer at your finger tips ?
      </Text>
    </VStack>
  );
};

const Divider = () => {
  const silverToggle = useColorModeValue("#8C8C8C", "#C0C0C0");

  return (
    <Box display={{ md: "none", base: "none" }} py={"10%"} w={"100%"}>
      <Box bg={silverToggle} h={"4px"} />
    </Box>
  );
};
