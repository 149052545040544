import { Box, Button, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
const Error = () => {
  const navigate = useNavigate();
  const bg = useColorModeValue("white", "#19191B");


  return (
    <Box
      bg={bg}
      h={"100vh"}
      w={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Heading
        opacity={"0.2"}
        color={"gray.700"}
        fontSize={{ md: "500px", base: "200px" }}
        pos={"absolute"}
        zIndex={"0"}
        bottom={{ base: "500px", md: "100px" }}
      >
        404
      </Heading>
      <Box
        maxW={{ sm: "1000px", base: "300px" }}
        textAlign={"center"}
        pos={"absolute"}
      >
        <Heading
          size={{ md: "4xl", base: "2xl" }}
          bgGradient="linear(to-r, orange.200, pink.500)"
          bgClip="text"
        >
          Oops
        </Heading>
        <Text>The Webpage you are looking for has been deleted or moved</Text>
        <Button mt="10px" onClick={() => navigate(-1)}>
          Back to previous page
        </Button>
      </Box>
    </Box>
  );
};

export default Error;
