import React, { useEffect } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  useColorModeValue,
  HStack,
  Tag,
  SimpleGrid,
} from "@chakra-ui/react";
import { ContentListItem } from "./Sub-Pages/Unshackled";
import { ResumeExperinceCard } from "../components/sub-components/Cards";
import {
  SiGit,
  SiHtml5,
  SiSwift,
  SiXcode,
  SiVisualstudiocode,
  SiFirebase,
  SiFigma,
  SiNotion,
  SiJavascript,
  SiReact,
  SiPhp,
  SiLaravel,
} from "react-icons/si";
import { HiLocationMarker, HiOutlineMail, HiPhone } from "react-icons/hi";
import Colours from "../themes/colours";

export const Resume = () => {
  useEffect(() => {
    document.title = "Bradlee King || Resume";
  });
  const bg = useColorModeValue("white", "#19191B");
  const tc = useColorModeValue("gray.700", "gray.100");
  const secondary = useColorModeValue("gray.800", "gray.400");

  const silverToggle = useColorModeValue("#8C8C8C", "#C0C0C0");

  return (
    <React.Fragment>
      <Box
        name="Resume"
        h="100%"
        w="full"
        justifyContent="center"
        bg={bg}
        color={tc}
        fontWeight="semibold"
      >
        <Box pt={"120px"} maxW="1000px" mx="auto" px="1rem">
          <VStack align="start">
            <Heading fontSize={{ base: "2.25rem", md: "3rem" }}>
              Bradlee King
            </Heading>
            <Text color={secondary}>
              IOS Developer & Front-End Web Developer
            </Text>
            <Text
              textTransform={"uppercase"}
              fontSize={{ base: "15px", md: "20px" }}
              color={"#E5712F"}
              fontWeight={"extrabold"}
            >
              Contact Information
            </Text>
            <HStack>
              <HiLocationMarker />
              <Text>Winchester, UK</Text>
            </HStack>
            <HStack>
              <HiOutlineMail />
              <Text>Kingofbrad@gmail.com</Text>
            </HStack>
            <Text
              textTransform={"uppercase"}
              fontSize={{ base: "15px", md: "20px" }}
              color={"#E5712F"}
              fontWeight={"extrabold"}
            >
              Profile
            </Text>
            <Text>
              Confident young professional with a passion for learning and
              development. Eager to leverage education and training in the IOS
              development space to support the growth and success of a
              high-preforming organisation. Strong track record of setting
              effective goals and leading teams to achieve those goals.
              Committed to continuous improvement and driving team success.
            </Text>
            <Text>
              I am currently studying at the University of Winchester in Digital
              Media Development to pursue a career into IOS Development/
              Engineering
            </Text>
            <ComputerLanguages silverToggle={silverToggle} />
            <DevelopmentTools silverToggle={silverToggle} />
            <Text
              textTransform={"uppercase"}
              color={"#E5712F"}
              fontSize={{ base: "15px", md: "20px" }}
              fontWeight={"extrabold"}
            >
              Education
            </Text>
            <HStack>
              <Tag>D = Distinction</Tag>
              <Tag>M = Merit</Tag>
              <Tag>P = Pass</Tag>
            </HStack>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing="30px">
              <EducationItem
                school="University of Winchester"
                year="2021 - Present"
                course="BSC Digital Media Development"
                grade="N/A"
              />
              <EducationItem
                school="Sheppey College"
                year="2018 - 2021"
                course="BTEC Level 3 I.T National Extended Diploma"
                grade="DDM"
              />
            </SimpleGrid>
            <Text
              textTransform={"uppercase"}
              fontSize={{ base: "15px", md: "20px" }}
              color={"#E5712F"}
              fontWeight={"extrabold"}
            >
              Experience
            </Text>
            <ResumeExperinceCard
              role={"Crew Trainer"}
              company={"McDonald's"}
              startYear={"2023"}
              endYear={""}
              content={"My current role within Mcdonalds is providing excellent training for the crew and ensuring that the crew are following all procedures and delivering the gold standard that McDonalds provides"}
            >
              <ContentListItem title="Following Food & Safety procedures are followed and along with the crew and managers" />
              <ContentListItem title="Provide excellent training to our crew and ensure that they are following procedures to our gold standard" />
            </ResumeExperinceCard>
            <ResumeExperinceCard
              role="Back of House"
              company={"The Flying Dutchman"}
              startYear="2018"
              endYear={"2022"}
              content="Within The Flying Ducthman, I ensured the resturant operated smoothly and that no errors occurred. I would operate Front of House and Back of House depending on where I was needed the most. I was mostly in the kitchen as I was needed there."
            >
              <ContentListItem title="Ensured the Bar and Kitchen was prepred and ready for service that day" />
              <ContentListItem title="Stock Rotation" />
              <ContentListItem title="Ensuring excellant customer service" />
              <ContentListItem title="Resturant kept up to standards of food regulation" />
              <ContentListItem title="Training new employees" />
            </ResumeExperinceCard>
          </VStack>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const EducationItem = ({ school, year, course, grade }) => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <>
      <VStack bg={boxBg} rounded="10px" p="8px" align={"start"}>
        <HStack width="full" justifyContent={"space-between"}>
          <Text>{school}</Text>
          <Tag bg="#E5712F">{year}</Tag>
        </HStack>
        {/* <Text borderBottom="2px solid #E5712F">Course</Text> */}
        <Text>{course}</Text>
        <Tag>{grade}</Tag>
      </VStack>
    </>
  );
};

export const ComputerLanguages = ({ silverToggle }) => {
  return (
    <>
      <VStack align="start">
        <Text
          textTransform={"uppercase"}
          fontSize={{ base: "15px", md: "20px" }}
          color={Colours.peru}
          fontWeight={"extrabold"}
        >
          Computer Langauges
        </Text>
        <Text>
          Before starting IOS development and diving headfirst into the
          fascinating realm of the type-safe and protocol-oriented Swift
          Language, I have been experimenting with other langauges since 2017.
          Here are some programming, markup, and style-sheet langauges I use
        </Text>
        <SimpleGrid spacing="40px" p="20px" columns={{ base: 3, md: 5, lg: 6 }}>
          <VStack>
            <SiHtml5 size="60px" color={silverToggle} />
            <Text>HTML & CSS</Text>
          </VStack>
          <VStack>
            <SiJavascript size="60px" color={silverToggle} />
            <Text>Javascript</Text>
          </VStack>
          <VStack>
            <SiSwift size="60px" color={silverToggle} />
            <Text>Swift</Text>
          </VStack>
          <VStack>
            <SiLaravel size="60px" color={silverToggle} />
            <Text>Laravel</Text>
          </VStack>
          <VStack>
            <SiPhp size="60px" color={silverToggle} />
            <Text>PHP</Text>
          </VStack>
          <VStack>
            <SiReact size="60px" color={silverToggle} />
            <Text>React</Text>
          </VStack>
        </SimpleGrid>
      </VStack>
    </>
  );
};

export const DevelopmentTools = ({ silverToggle }) => {
  return (
    <VStack align="start">
      <Text
        textTransform={"uppercase"}
        fontSize={{ base: "15px", md: "20px" }}
        color={Colours.peru}
        fontWeight={"extrabold"}
      >
        Development Tools
      </Text>
      <Text>
        Throughout the years, I have gained incrediable experience using
        prototyping, collaborative, image manipulation software. The following
        are some of the development and collaboration tools I use
      </Text>
      <SimpleGrid spacing="40px" p="20px" columns={{ base: 3, md: 6, lg: 6 }}>
        <VStack>
          <SiXcode size="60px" color={silverToggle} />
          <Text fontWeight={"bold"}>XCode</Text>
        </VStack>
        <VStack>
          <SiGit size="60px" color={silverToggle} />
          <Text>Git & Github</Text>
        </VStack>
        <VStack>
          <SiVisualstudiocode size="60px" color={silverToggle} />
          <Text>VsCode</Text>
        </VStack>
        <VStack>
          <SiFirebase size="60px" color={silverToggle} />
          <Text>Firebase</Text>
        </VStack>
        <VStack>
          <SiFigma size="60px" color={silverToggle} />
          <Text>Figma</Text>
        </VStack>
        <VStack>
          <SiNotion size="60px" color={silverToggle} />
          <Text>Notion</Text>
        </VStack>
      </SimpleGrid>
    </VStack>
  );
};
