import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import {ChakraProvider} from '@chakra-ui/react'
import "@fontsource/m-plus-rounded-1c"
import theme from "./themes/theme"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme={theme}>
        <App />
    </ChakraProvider>
);


