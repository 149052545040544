import { Box, Heading, Link, Text, useColorModeValue } from "@chakra-ui/react";

export const MazeRef = () => {
  return (
    <Box mt={"10px"}>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={useColorModeValue("green.600", "green.700")}
          fontWeight={"extrabold"}
        >
          Section 10
        </Text>
        <Heading
          letterSpacing={"1px"}
          fontSize={{ base: "2xl", md: "3xl" }}
          borderBottom={"3px solid #e5712f"}
          display={"inline-block"}
        >
          References
        </Heading>
      </Box>

      <Box>
        <Text>
          Epic Game &#40;2021&#41; LEGO Star Wars Skywalker Saga. Available at
          <Link
            color={"#e5712f"}
            px={"4px"}
            href="https://store.epicgames.com/en-US/p/lego-star-wars-the-skywalker-saga"
            target={"_blank"}
          >
            https://store.epicgames.com/en-US/p/lego-star-wars-the-skywalker-saga
          </Link>{" "}
          &#40;Accessed: 18/04/2022&#41;
        </Text>
        <Text my={"20px"}>
          Bungie &#40;2007&#41; Bungie. Available at
          <Link
            color={"#e5712f"}
            px={"4px"}
            href="https://www.bungie.net/"
            target={"_blank"}
          >
            https://www.bungie.net/
          </Link>{" "}
          &#40;Accessed: 18/04/2022&#41;
        </Text>
      </Box>
    </Box>
  );
};
