import {
  Box,
  HStack,
  Text,
  Image,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { BsArrowReturnRight } from "react-icons/bs";
import React, { useEffect } from "react";
import UnshackledCover from "../../assets/projects-imgs/Unshackled/UnshackledCoverArt.svg";
import XcodeIcon from "../../assets/projects-imgs/Unshackled/XcodeIcon.png";
import FigmaIcon from "../../assets/figma-brands.png";
import MiroLogo from "../../assets/MiroLogo.png";
import BriefRef from "../../assets/projects-imgs/Unshackled/BriefRef.png";
import FlutterCover from "../../assets/projects-imgs/Unshackled/FlutterCoverArt.svg";
import FirebaseCoverArt from "../../assets/projects-imgs/Unshackled/FirebaseCoverArt.svg";
import FlowChart from "../../assets/projects-imgs/Unshackled/FlowChart.jpeg";
import MiroFlowChart from "../../assets/projects-imgs/Unshackled/MiroFlowChart.png";
import GithubLogoLight from "../../assets/GitHub-Light.png";
import GithubLogoDark from "../../assets/GitHub_Dark.png";
import FirstStepImage1 from "../../assets/projects-imgs/Unshackled/Image 2.0.1.1.png";
import FirstStepImage2 from "../../assets/projects-imgs/Unshackled/Image 2.0.1.2.png";
import FirebaseConnectionsImage1 from "../../assets/projects-imgs/Unshackled/Image 2.1.1.png";
import FirebaseConnectionsImage2 from "../../assets/projects-imgs/Unshackled/Image 2.1.2.png";
import FirebaseConnectionsImage3 from "../../assets/projects-imgs/Unshackled/Image 2.1.3.png";
import SettingUPFirestoreandFirebaseAuthImage1 from "../../assets/projects-imgs/Unshackled/Image 2.2.1.png";
import SettingUPFirestoreandFirebaseAuthImage2 from "../../assets/projects-imgs/Unshackled/Image 2.2.2.png";
import SettingUPFirestoreandFirebaseAuthImage3 from "../../assets/projects-imgs/Unshackled/Image 2.2.3.png";
import SettingUPFirestoreandFirebaseAuthImage4 from "../../assets/projects-imgs/Unshackled/Image 2.2.4.png";
import SettingUPFirestoreandFirebaseAuthImage5 from "../../assets/projects-imgs/Unshackled/Image 2.2.5.png";
import JournalEntriesImage1 from "../../assets/projects-imgs/Unshackled/Image 2.3.1.png";
import JournalEntriesImage2 from "../../assets/projects-imgs/Unshackled/Image 2.3.2.png";
import JournalEntriesImage3 from "../../assets/projects-imgs/Unshackled/Image 2.3.3.png";
import DirectMessagingImage1 from "../../assets/projects-imgs/Unshackled/Image 2.4.1.png";
import DirectMessagingImage2 from "../../assets/projects-imgs/Unshackled/Image 2.4.2.png";
import DirectMessagingImage3 from "../../assets/projects-imgs/Unshackled/Image 2.4.3.1.png";
import DirectMessagingImage4 from "../../assets/projects-imgs/Unshackled/Image 2.4.3.2.png";
import DirectMessagingImage5 from "../../assets/projects-imgs/Unshackled/Image 2.4.3.3.png";
import DirectMessagingImage6 from "../../assets/projects-imgs/Unshackled/Image 2.4.3.4.png";
import DirectMessagingImage7 from "../../assets/projects-imgs/Unshackled/Image 2.4.4.png";
import DirectMessagingImage8 from "../../assets/projects-imgs/Unshackled/Image 2.4.5.png";
import DirectMessagingImage9 from "../../assets/projects-imgs/Unshackled/Image 2.4.6.png";

import { CodeBlock } from "../../Data/CodeBlocks";
import AuthImage1 from "../../assets/projects-imgs/Unshackled/Image 2.5.1.png";
import AuthImage2 from "../../assets/projects-imgs/Unshackled/Image 2.5.2.png";
import AuthImage3 from "../../assets/projects-imgs/Unshackled/Image 2.5.3.png";
import MyCodeComponent from "../../components/CodeBlock";

const Unshackled = ({ bg, tc }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Box
        name="Unshackled"
        h={"100%"}
        w={"full"}
        justifyContent={"center"}
        bg={bg}
        color={tc}
        fontWeight={"semibold"}
      >
        <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"}>
            <Text
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              DM2111: Development Focus A 22/23 (S1)
            </Text>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              Unshackled
            </Heading>
            <HStack>
              <Tag bg={"#E5712F"}>Client Project</Tag>
              <Tag bg={"#E5712F"}>
                <Link
                  href="https://github.com/kingofbrad/Unshackled---Mobile-Project"
                  target={"_blank"}
                >
                  Link to Github
                </Link>
              </Tag>
            </HStack>
            <Tag>Word Count: 3,064</Tag>
          </VStack>
          <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
            <VStack>
              <Image
                src={UnshackledCover}
                borderRadius={{ base: "10px", md: "15px" }}
              />
              <Tag>Credits: Unshackled Team</Tag>
            </VStack>

            <TableofContents />
          </VStack>

          <UnshackledSection1 />
          <UnshackledSection2 />
          <UnshackledSection3 />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Unshackled;

const TableofContents = () => {
  const { logoToggle, setLogoToggleMode } = useColorMode();
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "4" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ContentItem title="Brief">
          An Application that will allow sexual assault victim to escape and to
          create their future by allowing them to create journals, track their
          mood, create goals to complete and chat to people anonymously
          regarding certain topics.
        </ContentItem>
        <ContentItem title="End Goal">
          The end goal for this project is to create a fully functional
          application on IOS and Android that will allow follow the brief set by
          the client.
        </ContentItem>
        <ContentItem title="Role">
          Within this project, I was part of a team of 5 people. I'm the
          developer who is bringing this application to life.
        </ContentItem>
        <ContentItem title="Software">
          <VStack align={"start"}>
            <HStack>
              <Image src={XcodeIcon} width={{ base: "8%", md: "12%" }} />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Xcode 14
              </Text>
            </HStack>
            <HStack>
              <Image
                paddingLeft={"3px"}
                src={FigmaIcon}
                width={{ base: "7%", md: "12%" }}
              />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Figma
              </Text>
            </HStack>
            <HStack>
              <Image
                paddingLeft={"3px"}
                src={MiroLogo}
                width={{ base: "7%", md: "12%" }}
              />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                Miro
              </Text>
            </HStack>
            <HStack>
              <Image
                paddingLeft={"3px"}
                src={logoToggle === "Light" ? GithubLogoLight : GithubLogoDark}
                width={{ base: "15%", md: "12%" }}
              />
              <Text fontSize={"18px"} fontWeight={"bold"}>
                GitHub
              </Text>
            </HStack>
          </VStack>
        </ContentItem>
        <ContentItem title="Research & Prep">
          <ContentListItem title="1.0 Client Brief" />
          <ContentListItem title="1.1 Device Target" />
          <ContentListItem title="1.2 Research | Swift Language" />
          <ContentListItem title="1.3 Research | Flutter Language" />
          <ContentListItem title="1.4 Research | Firebase " />
          <ContentListItem title="1.5 Research | Mobile Apps Layout " />
          <ContentListItem title="1.6 Wireframe | Flow Chart" />
          <ContentListItem title="1.7 Primary & Secondary Audience" />
        </ContentItem>
        <ContentItem title={"Development | Swift"}>
          <ContentListItem title="2.0 The first step" />
          <ContentListItem title="2.1 Firebase Connections" />
          <ContentListItem title="2.2 Setting Up Firestore and Firebase Auth" />
          <ContentListItem title="2.3 Journal Entries" />
          <ContentListItem title="2.4 Direct Messaging" />
          <ContentListItem title="2.5 Creating Auth Functions " />
          <ContentListItem title="2.6 Intergrating Designs " />
        </ContentItem>
        <ContentItem title="Final Results">
          <ContentListItem title="3.0 Final Outcome" />
          <ContentListItem title="3.1 What has been accomplished so far" />
          <ContentListItem title="3.2 Time Management" />
          <ContentListItem title="3.3 Conclusion" />
          <ContentListItem title="3.4 Handover Document" />
          <ContentListItem title="3.5 References" />
        </ContentItem>
      </SimpleGrid>
    </Box>
  );
};

export const ContentItem = (props) => {
  return (
    <Box width={"100%"} height={"100%"} fontWeight={"medium"}>
      <Text
        letterSpacing={"1px"}
        fontSize={{ base: "20px", md: "2xl" }}
        textTransform={"uppercase"}
        fontWeight={"bold"}
      >
        {props.title}
      </Text>
      {props.children}
    </Box>
  );
};

export const ContentListItem = (props) => {
  return (
    <HStack>
      <BsArrowReturnRight />
      <Text fontWeight={"semibold"}>{props.title}</Text>
    </HStack>
  );
};

const UnshackledSection1 = () => {
  return (
    <VStack name="Section 1" align={"start"} marginTop={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 1 Research & Prep
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
      >
        Client Brief
      </Heading>
      <Text>
        At the beginning of the semester, our cohort was given a selection of
        brief and the one that stood out to me was the Unshackled brief. The
        client wants an application that would be available on IOS and Android
        to help sexual assault survivors to begin their healing journey.
      </Text>
      <Text>
        The app will have features such as a password-protected journal for them
        to log their day, a mood tracker, chat rooms to talk to other survivors
        and a SOS feature to get in contact quickly.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Device Target"
      >
        Device Target
      </Heading>
      <Text>
        As mentioned in the brief, the application will be developed for IOS and
        Android. However, the application is currently being developed for IOS
        only as the developer(me) only knows Swift which the language is used to
        create IOS apps and doesn&#180;t know Kotlin or Java to create the
        android version.
      </Text>
      <Text>
        I could have used Flutter or React native as they are cross-platform
        frameworks to build but I was already learning Swift at the time, so I
        stuck with that as I want to pursue a career within IOS
        Development/Engineering.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Swift Language"
      >
        Research | Swift Language
      </Heading>
      <VStack>
        <Image
          borderRadius={"10px"}
          src="https://miro.medium.com/max/1400/1*4fDVdzPaeC7IqiW3R1YhAg.webp"
        />
        <Tag>Credits: kenanatmaca.com</Tag>
      </VStack>

      <Text>
        Swift is a language made by Apple and is used to create applications for
        IOS, iPadOS, TvOS, WatchOS and MacOS. Swift comes with a framework
        called SwiftUI which is the successor to UIKIT which is another
        framework to use for IOS development. Swift is built of Objective-C also
        made by Apple and use the C Syntax.
      </Text>
      <Text>
        Before starting the project, I was learning Swift and SwiftUI from
        Apple&#180;s official documentation. But when I started the project, my
        knowledge for Swift and SwiftUI expanded massively as I needed to learn
        how to certain things such as create View models to handle states and
        variables or controllers to handle functions that will be used across
        the app.
      </Text>
      <Text>
        I also had to learn how Swift handle the Hierarchy for the views/pages.
        Along with Apple&#180;s official documentation, I resourced Stack
        Overflow and YouTube as secondary research if Apple&#180;s documentation
        didn&#180;t cover. Also building native is much quicker for performance
        compared to cross-platform frameworks such as Flutter or React Native.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Flutter Langauge"
      >
        Research | Flutter Language
      </Heading>
      <VStack>
        <Image src={FlutterCover} borderRadius={"10px"} />
        <Tag>Credits: Flutter.dev</Tag>
      </VStack>
      <Text>
        Along with researching Swift, I also researched Flutter and tried to
        learn it but with the time frame I was given, I wouldn&#180;t have
        enough time to learn a whole new framework and build the application.
      </Text>
      <Text>
        Flutter is built on a language called Dart which uses similar syntax
        style to Swift as both are based of C. In the end I decided not to use
        Flutter as I was already learning Swift and already started the project
        and made good progress with and I would have to start from the beginning
        which I did not have time for.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        Research | Firebase
      </Heading>
      <VStack>
        <Image src={FirebaseCoverArt} borderRadius={"10px"} />
        <Tag>Credits: Firebase.google.com</Tag>
      </VStack>
      <Text>
        For the backend of the project, I decided to use Firebase as it was a
        backend service I already knew and it is perfect for this type of
        project as Firebase offers Realtime databases and since the application
        will have a messaging system, the Realtime database is perfect for it.
      </Text>
      <Text>
        I was originally going to use MySQL as I needed to learn that but after
        research the difference between NoSQL which is what firebase is and SQL
        which is what MySQL is, Firebase was the one I needed. Along with Swift,
        I used Google&#180;s documentation for Firebase to learn what I needed
        from it and that had example for Swift so how to lay it out and call for
        the database.
      </Text>
      <Text>
        Firebase also offered Authentication with email plus password or sign by
        google, apple and many more.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        Research | Mobile Apps Layout
      </Heading>
      <Text>
        Before creating the application, I research on how to layout the
        wireframe/flow chart for the application as I would need to know how the
        user would navigate throughout the application. Even through the
        designers was creating the wireframe and designers, at the time, I
        didn&#180;t have anything from them and needed a plan.
      </Text>
      <Text>
        I visited multiple websites but the one that stood out to me was “User
        Flow for App Development: A Beginners Guide.”. This was provided by my
        tutor and helped me to establish an understanding on how mobile app
        development is done with flow charts.
      </Text>
      <Text>
        The website goes on how to layout the flow chart and what shapes to use
        to correspond to the item in the flow chart so for example, if it is a
        decision like a Yes/No or True/False then the shape would be a diamond
        or rotated square.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        WireFrames/ Flow Chart
      </Heading>
      <Text>
        As stated in the Mobile app&#180;s layout section in my research, I
        looked at how to create a Flow chart for the application and I ended up
        creating one. At first, I make a very basic flow chart on a whiteboard
        as seen in image 1.6.1. I placed down all the features and functions I
        would need and drew a layout on how it will all connect with each other.
      </Text>
      <VStack>
        <Image src={FlowChart} rounded={"10px"} />
        <HStack>
          <Tag>Image 1.6.1 </Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
      <Text>
        The designers, I believe took inspiration on this and implemented into
        their work to create the UI views for the application.
      </Text>
      <Text>
        After I had a base layout, I went to a website called Miro to create a
        digital version of the flow chart as seen in Image 1.6.2 Using Miro
        allowed to collab with myself between my MacBook and iPad. Any changes
        will cascade through as Miro uses the internet to save and up-date.
      </Text>
      <VStack>
        <Image src={MiroFlowChart} rounded={"10px"} />
        <HStack>
          <Tag>Image 1.6.2</Tag>
          <Tag> Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
      <Text>
        Once I had a fully created flow chart/wireframe to go off for the
        project, I could start the development phase of the project.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        Audience
      </Heading>
      <Text>
        The targeted audience for this application is survivors of sexual
        assault of all genders/sexualities and age.
      </Text>
      <Text>
        The client specifies said that they want this application to be open to
        everyone that needs it and don&#180;t feel like they are being forced to
        use it out of their free will. This is further back up by the brief that
        was given out as seen in image 1.7.1
      </Text>
      <VStack>
        <Image src={BriefRef} rounded="10px" />
        <HStack>
          <Tag>Image 1.7.1</Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
    </VStack>
  );
};

const UnshackledSection2 = () => {
  return (
    <VStack name="Section 2" align={"start"} marginTop={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 2 Development | Swift
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Mobile Apps Layout"
      >
        The First Step
      </Heading>
      <Text>
        I began by creating the project within XCode14 along with IOS 16. I
        mentioned in the device target section, I&#180;m a IOS developer so I
        only begun development in Swift/SwiftUI, if I can finish the project
        before the end of the year, I will begin the development for the android
        version but for the time being it will only be for IOS.
      </Text>
      <Text>
        Once I had the project setup in XCode, GitHub was the next action for
        source control as I would need to have kind of source control to get
        track of progress and manage any bugs I find along the way. Within the
        XCode, they have a section dedicated to source control by connecting it
        to GitHub, they give you the option to commit, push, pull, fetch, and
        change branch but I would not use this as I would use git via the
        terminal. I just set it up so it would be connected to the repository.
      </Text>
      <Text>
        At this time of development, the designers in the group did not have a
        concept for UI, so my focus was getting the features created that were
        asked by the client as seen in image 2.0.1. But my next step before
        starting the features was to get Firebase installed and Setup.
      </Text>
      <VStack>
        <Image src={FirstStepImage1} rounded="10px" />
        <HStack>
          <Tag>Image 2.0.1.1</Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
        <Image src={FirstStepImage2} rounded="10px" />
        <HStack>
          <Tag>Image 2.0.1.2</Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
      <Text>
        But my next step before starting the features was to get Firebase
        installed and Setup.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Firebase Connections"
      >
        Firebase Connections
      </Heading>
      <Text>
        Firebase was used as the backend of the project as stated in the
        research section of Firebase. To install Firebase and all the
        dependencies need for this project, this was done via XCode&#180;s SPM
        &#40;Swift Package Manager&#41; using the GitHub link and importing the
        google plist provided by Firebase when setting up the project as seen in
        image 2.1.1 & Image 2.1.2
      </Text>
      <SimpleGrid columns={{ md: "2" }} spacing="10px">
        <UnshackledImage
          src={FirebaseConnectionsImage1}
          ImgRev="2.1.1"
          credits="Bradlee King"
        />
        <UnshackledImage
          src={FirebaseConnectionsImage2}
          ImgRev="2.1.2"
          credits="Bradlee King"
        />
      </SimpleGrid>
      <Text>
        Once Firebase was installed along with the dependencies needed, I set up
        a Firebase manager that will allow me to use Firebase without having to
        import every single time I need to use Firebase in each file. The
        manager can be seen below. The way I have gone about the manager is the
        standard method that other developers use.
      </Text>
      <MyCodeComponent code={FirebaseConnection} language="swift" />

      <Text>
        To call the Firebase Manager and to use the functions within. The
        calling method will be along on lines of this.
        <Tag py={"7px"} fontSize={"16px"}>
          guard let uid = FirebaseManager.shared.auth.currentUser?.uid else
          &#123;return&#125;
        </Tag>
        This will call the Firebase Manager to get the current Users unique
        identification and store in a variable called “uid”. The question mark
        is used to say there may not be a user logged in, so it won&#180;t
        return anything. The line code above is just an example on how to call
        something from Firebase.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Setting up Firestore and Firebase Auth"
      >
        Setting up FireStore and Firebase Auth
      </Heading>
      <Text>
        On the Dashboard for Firebase, you must enable auth to use it otherwise
        it will not work. To enable it you use click the “Get Started” and it
        set up the auth page for you. Same goes for Firestore. This can be seen
        in image 2.2.1
      </Text>
      <UnshackledImage
        src={SettingUPFirestoreandFirebaseAuthImage1}
        ImgRev="2.2.1"
        credits="Bradlee King"
      />
      <Text>
        The method to create an account within Firebase is done by the function
        as shown in the code block As shown below, the Firebase
        Manager is called along with the auth function. The create user with
        email and password property is added on.
      </Text>
      <Text>
        This will create an account on the Firebase servers with the email and
        password entered as displayed in image 2.2.3.
      </Text>
      <UnshackledImage
        src={SettingUPFirestoreandFirebaseAuthImage3}
        alt="Firebase Auth Dashboard"
        ImgRev="2.2.3"
        credits="Bradlee King"
      />
      <MyCodeComponent code={createAccountCode} language="swift" />
      <Text>
        Firestore is a Realtime NoSQL database that is used to provide backend
        support for the messaging system and journal entries. This will allow
        all the data to be accessible from all platforms. The database is
        displayed as a folder hierarchy as shown in image 2.2.4 and this allows
        me to navigate easily through the documents that are created from the
        function within the application.
      </Text>
      <UnshackledImage
        src={SettingUPFirestoreandFirebaseAuthImage4}
        alt="Firestore dashboard"
        ImgRev="2.2.4"
        credits="Bradlee King"
      />
      <Text>
        The method I have used to store data in the database is shown in image
        2.2.5. This method is used to store the user&#180;s information from
        when they create an account.
      </Text>
      <Text>
        It fetches for the user who is currently logged from the email section
        and then it takes the string values from the textfields and places them
        into a data modal. Then using the Firebase Manager calling Firestore
        along with the correct collection, it takes the uid from the current
        user and creates a document with that uid, then
        “.setdata&#40;userData&#41;” is then called which just places the data
        into the collection in Firestore.
      </Text>
      <UnshackledImage
        src={SettingUPFirestoreandFirebaseAuthImage5}
        alt="Firestore Saving Function"
        ImgRev="2.2.5"
        credits="Bradlee King"
      />
      <MyCodeComponent code={storeUserInformation} language="swift" />
      <Text>
        If there are no errors, then it will print in the terminal “Success”
        otherwise it will print the error. The same method is used for most of
        the features used in the application as they are doing the same thing.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Journal Entries"
      >
        Journal Entries
      </Heading>
      <Text>
        Whilst prototyping before getting the UI design from the designers. I
        created the functionality to add a new journal entry that is unique to
        the user, so if someone else logged in, they will not see that entry.
        The Function is shown in image 2.3.1.
      </Text>
      <UnshackledImage
        src={JournalEntriesImage1}
        alt="AddJournalEntryFunction"
        ImgRev="2.3.1"
        credits="Bradlee King"
      />
      <Text>
        Whilst creating the journal feature, I ran into a bug where the
        users&#180; entries would display for everyone, this was due to not
        having an if statement on the data view to display the content of the
        Journal. The fix was placing this line of code into the data view, shown
        in image 2.3.2.
      </Text>
      <UnshackledImage
        src={JournalEntriesImage2}
        alt="Journal Data View"
        ImgRev="2.3.2"
        credits="Bradlee King"
      />
      <Text>
        Within the Journal View model, there is a function to fetch all the data
        for the data. This uses a certain path for the documents. This can be
        seen in image 2.3.3, this will look through the collection of “journals”
        and using the uid defined above, it will find all the related data that
        is called in the data view.
      </Text>
      <UnshackledImage
        src={JournalEntriesImage3}
        alt="Journal Fetch Function"
        ImgRev="2.3.3"
        credits="Bradlee King"
      />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Direct Messaging / Chat Rooms"
      >
        Direct Messaging / Chat Rooms
      </Heading>
      <Text>
        Like the journal, I was waiting on a UI design, so in the meantime, I
        focused on getting the functionality working. I managed to get it
        working and to be in real-time between different devices. This was
        accomplished using the same method as the journal but more complicated
        as I would need to get the other user's uid and have the message
        rendered out from Firestore. At this current time, I have only got the
        direct messaging working, the chat rooms are being worked on.
      </Text>
      <Text>
        With the messaging system, I had to create two identifiable structures
        that would tell swift how I want the data to be processed as seen in
        image 2.4.1 and image 2.4.2.
      </Text>
      <SimpleGrid columns={{ md: "2" }}>
        <UnshackledImage
          src={DirectMessagingImage1}
          alt="Chat user identifiable struct"
          ImgRev="2.4.1"
          credits="Bradlee King"
        />
        <UnshackledImage
          src={DirectMessagingImage2}
          alt="Chat Message identifiable struct"
          ImgRev="2.4.2"
          credits="Bradlee King"
        />
      </SimpleGrid>
      <Text>
        Then within the ViewModal created for the messaging system, the
        identifiable structure is imported and declared as an empty array. The
        user declared as well to make it identifiable as well. The init()
        function is called to run the fetch messages functions that will get all
        the messages related to the users. The init() function runs when the
        view is being loaded and is always running in the background. The
        ViewModal can be seen in image 2.4.3.
      </Text>
      <SimpleGrid spacing={"10px"} columns={{ md: "2" }}>
        <UnshackledImage
          src={DirectMessagingImage3}
          alt="First image of View Model"
          ImgRev="2.4.3.1"
          credits="Bradlee King"
        />
        <UnshackledImage
          src={DirectMessagingImage4}
          alt="Second image of View Model"
          ImgRev="2.4.3.2"
          credits="Bradlee King"
        />
        <UnshackledImage
          src={DirectMessagingImage5}
          alt="Third image of View Model"
          ImgRev="2.4.3.3"
          credits="Bradlee King"
        />
        <UnshackledImage
          src={DirectMessagingImage6}
          alt="Fourth image of View Model"
          ImgRev="2.4.3.4"
          credits="Bradlee King"
        />
      </SimpleGrid>
      <Text>
        Within the fetch message’s function, I’m calling for the current user
        uid and the recipient uid then pulling all the documents related to the
        uids in the “fromId” and “toId”. This is done by pulling from the
        collection with the String property “Messages.”, and then find the
        document with the variable of the fromId along with the collection
        within that document. The function can be seen in image 2.4.3.2
      </Text>
      <Text>
        After I had the fetch message’s function done, I needed to have some
        kind way to add the message to the collection, so I ended up creating a
        function to handle this. The method is the almost the same as the
        journal but with a few extra steps, like having to add to the fromId
        collection then adding to the toId. This function can be seen in image
        2.4.3.3
      </Text>
      <Text>
        The function gets the ids from both ends. Using the same path as the
        fetch function of the collections. Instead of the querysnaphot, setdata
        is used. The FirebaseConstants are just variables written somewhere
        else, so I don’t have to write the same line of code every single time.
        The constants can be seen in image 2.4.4.
      </Text>
      <UnshackledImage
        src={DirectMessagingImage7}
        alt="Firebase Constants"
        ImgRev="2.4.4"
        credits="Bradlee King"
      />
      <Text>
        Then a data modal is made using the values from the ids, the textfield
        string state and a timestamp to keep track from when the documents was
        created and place it in order when the fetch function calls.
      </Text>
      <Text>
        To handle the recipient side of the messages, the properties in the
        collection section is flipped, so the toId will at the top then the
        fromId.
      </Text>
      <Text>
        The messages are displayed via importing the ViewModal and running a
        foreach loop with the array attached. The message array is then passed
        through into a component allowing it to have access to the array. This
        can be seen in image 2.4.5 and 2.4.6.
      </Text>
      <UnshackledImage
        src={DirectMessagingImage8}
        alt="Message List"
        ImgRev="2.4.5"
        credits="Bradlee King"
      />
      <UnshackledImage
        src={DirectMessagingImage9}
        alt="Message View"
        ImgRev="2.4.6"
        credits="Bradlee King"
      />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Direct Messaging / Chat Rooms"
      >
        Creating Auth Functions
      </Heading>
      <Text>
        As briefly mentioned in the Setting up Firestore and Firebase Auth
        section. The Firebase Authentication system is manged via the Firebase
        Manager. Now, I have only got sign up with email and password set up but
        am currently working on getting the other systems up and running.
      </Text>
      <Text>
        The Auth function takes two parameters, email, and password from the
        variables within the ViewModal I created for the whole sign-up process.
        The ViewModal is controlled by a state variable and gives all the
        variables within the ViewModal the ability to use state which is very
        helpful as the Firebase manager needs to get the string value from the
        textfields. The function can be seen in Image 2.5.1
      </Text>
      <UnshackledImage
        src={AuthImage1}
        alt="Auth Creation for user creation"
        ImgRev="2.5.1"
        credits="Bradlee King"
      />
      <Text>
        The ViewModal can be seen in image 2.5.2. All the variables I needed
        would be placed in here as they provide better state management instead
        of having duplicates of the same state in every file.
      </Text>
      <UnshackledImage
        src={AuthImage2}
        alt="Sign Up ViewModal"
        ImgRev="2.5.2"
        credits="Bradlee King"
      />
      <Text>
        To save the users details such as name, phone number, date of birth and
        more. I used the same method as the journal mainly because the function
        is just saving the data into the database. The function can be viewed at
        image 2.5.6
      </Text>
      <UnshackledImage
        src={AuthImage3}
        alt="Store User information Function"
        ImgRev="2.5.3"
        credits="Bradlee King"
      />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Direct Messaging / Chat Rooms"
      >
        Integrating Designs
      </Heading>
      <Text>
        I was able to view all the designs on Figma and give them feedback on
        what is and not possible to do with the designs. Most of the designs are
        doable however I had to mention that a few of the login view was
        redundant as the phone authentication systems automatically did them,
        such as FaceId and Touchid.
      </Text>
      <Text>
        However, after reading through apple's official documentation and a few
        posts on stack overflow I was able to fix the issue by changing the
        hierarchy of the views & components along with adding a modifier to
        ignore the safe areas of the components so the keyboard would hover over
        instead of pushing everything upwards.
      </Text>
    </VStack>
  );
};

const UnshackledSection3 = () => {
  return (
    <VStack name="Section 3" align={"start"} marginTop={"20px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 3 Final Results
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Outcome"
      >
        Final Outcome
      </Heading>
      <Text>
        The outcome of the project is to have a fully functional application
        that could be upload to the app stores. However as stated in the device
        target, I’m just an IOS developer/engineer so the application will only
        be for IOS devices unless I decide to make the android version at some
        point, or another team takes over and develops the android side.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Outcome"
      >
        What has been accomplished so far
      </Heading>
      <Text>
        So far, the login/sign up views and functions have been created along
        with its UI. The Messaging system needs to be tweaked before
        implementing the UI design for it and the same goes for the journal. I
        am currently working on getting the home screen sorted out having the
        functionality to have the mood tracker connected to it, so I would have
        to create the mood tracker before I do any further work on the home
        view.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Outcome"
      >
        Time Management
      </Heading>
      <Text>
        Throughout the course of this semester, I dedicated four out of the
        seven days in a week to working on this project and having a strict
        schedule for it. For example, out of the four days, I would give one day
        for bug testing, two days for creating/adding features for the app then
        day four (if applicable) for implementing the designs, and if not then
        more bug testing.
      </Text>
      <Text>
        Unfortunately, I did not have a tracker or a Gantt chart to track my
        progress, but I would create new issue tickets on GitHub every time I
        ran into a bug for the application and write my progress within the
        commits.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Outcome"
      >
        Conclusion
      </Heading>
      <Text>
        In conclusion for this project so far, I believe I have made a great
        success at the beginning of the project, I did not know much about Swift
        or Firebase but with the progress so far, I have a great understanding
        of how effectively and efficiently handle both and that can be seen with
        the progress of the project via the commits on GitHub. I also believe
        that the team I am currently working with have done a fantastic effort
        to create the UI designs. They have been approved by the client
        themselves.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Final Outcome"
      >
        Handover Document
      </Heading>
      <Text>
        The Repository for this project is hosted on GitHub and the ReadME will
        explain every part of the code of the application.
      </Text>
      <Link
        bg={"#E5712F"}
        rounded="7px"
        px={{ base: "20px", md: "30px" }}
        py={{ base: "10px", md: "14px" }}
        _hover={{ border: "none", color: "whiteAlpha.700" }}
        target="_blank"
        href="https://github.com/kingofbrad/Unshackled---Mobile-Project"
      >
        <HStack>
          <Text>View Repository</Text>
          <HiArrowNarrowRight />
        </HStack>
      </Link>

      <ReferenceList />
    </VStack>
  );
};

const ReferenceList = () => {
  return (
    <Box pb="30px">
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="UnshackledReferences"
      >
        References
      </Heading>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ReferenceCell
          author="Kenan"
          year="2019"
          name="SwiftUI ile UISearchBar kullanımı"
          link="https://kenanatmaca.com/swiftui-ile-uisearchbar-kullanimi/"
          accessDate="12/12/2022"
        />
        <ReferenceCell
          author="Apple Inc."
          year="2017"
          name="Human Interface Guidelines"
          link="https://developer.apple.com/design/human-interface-guidelines/foundations/app-icons/"
          accessDate="11/12/2022"
        />
        <ReferenceCell
          author="Apple Inc."
          year="2017"
          name="Human Interface Guidelines"
          link="https://www.stickpng.com/img/icons-logos-emojis/tech-companies/miro-app-logo"
          accessDate="11/12/2022"
        />
        <ReferenceCell
          author="Flutter"
          year="2017"
          name="Representing the Flutter brand"
          link="https://flutter.dev/brand"
          accessDate="12/12/2022"
        />
        <ReferenceCell
          author="Firebase"
          year="2012"
          name="Representing the Firebase brand"
          link="https://firebase.google.com/brand-guidelines"
          accessDate="14/12/2022"
        />
      </SimpleGrid>
    </Box>
  );
};

export const ReferenceCell = (props) => {
  return (
    <Box>
      <Text>
        {props.author} &#40;{props.year}&#41; "{props.name}", {props.blogname},{" "}
        {props.day} {props.month}. Available at:
        <Link color={"#e5712f"} px={"4px"} href={props.link} target={"_blank"}>
          {props.link}
        </Link>
        <br />
        &#40;Accessed: {props.accessDate}&#41;
      </Text>
    </Box>
  );
};

export const UnshackledImage = (props) => {
  return (
    <VStack>
      <Image src={props.src} rounded="10px" alt={props.alt} />
      <HStack>
        <Tag> Image: {props.ImgRev}</Tag>
        <Tag>Credits: {props.credits}</Tag>
      </HStack>
    </VStack>
  );
};

//Code Snippets Sections

let FirebaseConnection = `
Import Foundation
Import Firebase

class FirebaseManager: NSObject {
  let auth: Auth
  let firestore: Firestore


  static let shared = FirebaseManager()

  override init() {
    FirebaseApp.configure()

    self.auth = Auth.auth()
    self.firestore = Firestore.firestore()

    super.init()
  }
}
`;

let createAccountCode = `
func createNewAccount() {
  FirebaseManager.shared.auth.createUser(withEmail: SignUpVM.email, password: SignUpVM.password) { result,
    err in
    if let err = err {
      print("Couldn't Create user \(err)")
      return
    }
    print("Successfully created user")
    SignUpVM.nextStepView = true
  }
}


`;

let storeUserInformation = `
private func storeUserInformation() {
  guard let uid = FirebaseManager.shared.auth.currentUser?.uid else {return}

  let userData = [
    "email": FirebaseManager.shared.auth.currentUser?.email,
    "uid": uid,
    "name": SignUpVM.name,
    "dobDay": SignUpVM.dobDay,
    "dobMonth": SignUpVM.dobMonth,
    "dobYear": SignUpVM.dobYear,
    "location": SignUpVM.location,
    "mobileNumber": SignUpVM.mobileNumber,
  ]

  FirebaseManager.shared.firestore.collection("users")
  .document(uid).setData(userData as [String: Any]) { err in
    if let err = err {
      print(err)
      SignUpVM.errorMessage = "\(err)"
      return
    }
    print("Success")
  }
}
`;
