import ClutteredCharacters from "../assets/projects-imgs/ClutteredCharactors.png";
import HiveCoverArt from "../assets/projects-imgs/the-hive/HiveCoverArt.svg";
import SilentMaze from "../assets/projects-imgs/the-hive/uxtitle.jpg";
import ArdunioCover from "../assets/uni-work/ArdunioCover.png";
import NoteBookCover from "../assets/personal-work/NoteBookCover.png";
import UnshackledCover from "../assets/projects-imgs/Unshackled/UnshackledCoverArt.svg";
import RSAProjectCoverArt from "../assets/projects-imgs/RSA_Project/RSA_ VR Haptic Feedback Cover Art.svg";
import SculkSensorCover from "../assets/projects-imgs/Sculk-Sensor/Sculk-Sensor-in-Minecraft.jpg";
import orokinCollectiveCover from "../assets/projects-imgs/OrokinCollective/Orokin Collective.svg";
import finalyearProject from "../assets/projects-imgs/Dissertation/MainWindow.png";
export const CardData = [
  {
    title: "Cluttered Characters || 3D Modals",
    content:
      "Cluttered is a mobile game to help people understand the effect of the environment getting popluted from rubbish. I was the 3D Designer responable for creating all the Models of the players",
    lastModified: "21/07/2022",
    bgImage: ClutteredCharacters,
    Link: "",
    tag1: "Group Project",
    tag2: "University",
    tag1displayed: true,
    errorMessage:
      "Unfortunely this page is currently being worked on, access is not allowed",
    language: "Blender",
    type: "Models",
  },
  {
    title: "Hive Website",
    content:
      "The Hive is a Virtual Reality device that also splits into a tablet. I created the website to promote & sell the device",
    lastModified: "25/07/2022",
    bgImage: HiveCoverArt,
    Link: "hive",
    isActive: false,
    tag1: "Group Project",
    tag2: "University",
    tag1displayed: true,
    language: "HTML",
    type: "Ecommerce",
  },
  {
    title: "The Silent Maze Website",
    content:
      "The Slient maze is a Virtual Reality game that makes to the user to use their sense of touch and sight. For this project, i was responable for creating the website that would promote and allow people to download the game",
    lastModified: "27/07/2022",
    bgImage: SilentMaze,
    Link: "theslientmaze",
    isActive: false,
    tag1: "Group Project",
    tag2: "University",
    tag1displayed: true,
    language: "HTML",
    type: "Website",
  },
  {
    title: "DM1113: Development Projects",
    content: "All tasks during this module",
    lastModified: "28/07/2022",
    bgImage: ArdunioCover,
    Link: "DevelopmentProjects",
    tag1: "Individual Project",
    tag2: "University",
    tag1displayed: true,
    language: "JS | C++",
    type: "Hardware",
  },
  {
    title: "Client Project - Unshackled",
    content:
      "Unshackled is a Application that provides support for survivors of sexual assault.",
    lastModified: "10/12/2022",
    bgImage: UnshackledCover,
    Link: "Unshackled",
    tag1: "Client Project",
    tag2: "University",
    tag1displayed: true,
    language: "Swift",
    type: "Application",
  },
  {
    title: "RSA: VR Haptic Feedback",
    content:
      "This project is for my RSA Case study,, this is just the pratical side of the project.",
    lastModified: "14/12/2022",
    bgImage: RSAProjectCoverArt,
    Link: "RSA_Individual_Project",
    tag1: "Individual Project",
    tag2: "University",
    tag1displayed: true,
    isDisabled: true,
    language: "C++",
    type: "Hardware",
  },
  {
    title: "Notebook",
    content:
      "A Notebook Application that i created with React, Chakra-UI and vanilla JavaScript",
    lastModified: "03/08/2022",
    bgImage: NoteBookCover,
    Link: "https://notebookproject.netlify.app/",
    buttoncaption: "View Demo",
    isDisabled: false,
    tag1: "Individual Project",
    tag2: "Personal",
    tag1displayed: true,
    language: "React",
    type: "Website",
  },
  {
    title: "DM1113 Development Projects Trail Fail",
    content:
      "These are all the task that the class was assigned to complete to further our knowledge within different practices of using HTML, CSS, JS in the digtial Media world",
    lastModified: "9/05/2023",
    bgImage: "",
    Link: "DevelopmentProjects2",
    tag1: "Class Tasks",
    tag2: "University",
    isDisabled: false,
    language: "HTML, CSS, JS, C++",
    type: "Tasks",
  },
  {
    title: "Sculk Sensor",
    content:
      "A Sculk Sensor is a item from the game Minecraft. This project was created by using a ESP32 and AWS",
    lastModified: "5/12/2023",
    bgImage: SculkSensorCover,
    Link: "SculkSensor",
    tag1: "Individual Project",
    tag2: "University",
    isDisabled: true,
    language: "C++, JS",
    type: "Hardware",
  },
  {
    title: "Orokin Colletive",
    content:
      "Orokin Collective is a companion app for the video game Warframe, the app allows players to view in-games event in real-time and search for items or gear without having to google for it",
    lastModified: "26/04/2024",
    bgImage: orokinCollectiveCover,
    Link: "OrokinCollective",
    tag1: "Individual Project",
    tag2: "University",
    isDisabled: false,
    tag1displayed: true,
    language: "Swift",
    type: "IOS",
  },
  {
    title: "Final Year Project - Xcode Pilot",
    content:
      "Final Year Project reflective study on AI impacting and helping IOS Developers and creating a Xcode entension to integrage Github CoPilot",
    lastModified: "19/05/2024",
    bgImage: finalyearProject,
    Link: "FinalYearProject",
    tag1: "Individual Project",
    tag2: "University",
    isDisabled: false,
    tag1displayed: true,
    language: "Swift",
    type: "Xcode",
  },
];

export const WorkCardData = [
  {
    title: "Final Year Project",
    content:
      "Final Year Project reflective study on AI impacting and helping IOS Developers and creating a Xcode entension to integrage Github CoPilot",
    lastModified: "19/05/2024",
    bgImage: finalyearProject,
    link: "/Projects/FinalYearProject",
    tag1: "Individual Project",
    tag2: "University",
    tag1displayed: true,
    language: "Swift",
    type: "Xcode",
  },
  {
    title: "Orokin Colletive",
    content:
      "Orokin Collective is a companion app for the video game Warframe, the app allows players to view in-games event in real-time and search for items or gear without having to google for it",
    lastModified: "26/04/2024",
    bgImage: orokinCollectiveCover,
    link: "/Projects/OrokinCollective",
    tag1: "Individual Project",
    tag2: "University",
    tag1displayed: true,
    language: "Swift",
    type: "IOS",
  },
  {
    title: "Unshackled",
    content:
      "Unshackled is a Application that provides support for survivors of sexual assault.",
    lastModified: "13/12/2022",
    bgImage: UnshackledCover,
    link: "/Projects/Unshackled",
    tag1: "Client Project",
    tag2: "University",
    tag1displayed: true,
    language: "Swift",
    type: "IOS",
  },
  {
    title: "NoteBook",
    content:
      "A Notebook Application that i created with React, Chakra-UI and vanilla JavaScript",
    lastModified: "03/08/2022",
    bgImage: NoteBookCover,
    link: "https://notebookproject.netlify.app/",
    tag1: "Self Study",
    tag2: "Personal",
    tag1displayed: true,
    targetblank: true,
    language: "React",
    type: "Website",
  },
  {
    title: "The Silent Maze",
    content:
      "The Slient maze is a Virtual Reality game that makes to the user to use their sense of touch and sight. For this project, i was responable for creating the website that would promote and allow people to download the game",
    lastModified: "26/07/2022",
    bgImage: SilentMaze,
    link: "/Projects/theslientmaze",
    tag1: "Group Project",
    tag2: "University",
    tag1displayed: true,
    language: "HTML",
    type: "Ecommerce",
  },
  {
    title: "Sculk Sensor Device",
    content:
      "This is a recreation of the sculk sensor from the popular game Minecraft in real life.",
    lastModified: "10/01/2024",
    bgImage: SculkSensorCover,
    link: "/Projects/SculkSensor",
    tag1: "Individual Project",
    tag2: "University",
    tag1displayed: true,
    language: "C & Javascript",
    type: "Hardware",
  },
];
