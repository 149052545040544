import React from "react";
import {
  Input,
  Textarea,
  Box,
  VStack,
  Heading,
  Text,
  Link,
  HStack,
} from "@chakra-ui/react";
import "./Contact/Contact.css";
import { HiArrowNarrowRight } from "react-icons/hi";

const Contact = ({ background, textcolor, peru, silverToggle }) => {
  document.querySelectorAll(".text-input").forEach((element) => {
    element.addEventListener("blur", (event) => {
      if ((event.target.value = "")) {
        event.target.nextElementSibling.classList.add("filled");
      } else {
        event.target.nextElementSibling.classList.remove("filled");
      }
    });
  });

  return (
    <div
      name="contact"
      className="w-full h-screen flex  items-center pt-5 px-3"
      style={{ background: background, color: textcolor }}
    >
      <form
        action="https://getform.io/f/5c56ffe6-eff7-41a0-b2bf-116ee4f38da3"
        method="POST"
        className="flex flex-col max-w-[1000px] w-full"
      >
        <div className="pb-8">
          <Heading fontSize="2.25rem" color={silverToggle}>
            Contact
          </Heading>
          <Text
            textTransform={"uppercase"}
            fontSize={{ base: "12px", md: "15px" }}
            color={"#E5712F"}
            fontWeight={"extrabold"}
          >
            Feel free to contact about any questions you may have
          </Text>
        </div>

        <Input
          p={"0.5rem"}
          _dark={{ background: "#3f4043" }}
          bg={"gray.200"}
          type="text"
          placeholder="Please enter your name"
          name="name"
          required
        />

        <Input
          my={"1rem"}
          p={"0.5rem"}
          _dark={{ background: "#3f4043" }}
          bg={"gray.200"}
          type="email"
          placeholder="Email"
          name="email"
          required
        />
        <Textarea
          p={"0.5rem"}
          _dark={{ background: "#3f4043" }}
          bg={"gray.200"}
          placeholder="Type your message here"
          name="message"
          rows="10"
          required
          resize={"none"}
        ></Textarea>
        <button className=" border-2 hover:bg-[#E5712F] hover:border-[#E5712F] hover:text-white px-4 py-3 my-8 mx-auto flex items-center duration-300">
          Let's Get in Touch
        </button>
      </form>
    </div>
  );
};

export default Contact;
