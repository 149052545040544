import {
  Box,
  Wrap,
  WrapItem,
  useColorModeValue,
  Text,
  Heading,
  Flex,
} from "@chakra-ui/react";

const TeamData = [
  {
    role: "3D Artist",
    name: "Maximillian Le Riche",
    content:
      "Max was responsible for creating all the game assets for the project and ended up making an AR prototype for people to see the type of game we were designing and creating.",
  },
  {
    role: "Designers",
    name: "James Clarke & Michael Webb",
    content:
      "James was mostly focused on the logo and UI/UX interface for the website and software for the device. Michael was focused on making the social media aspects for the project and helped with the design on the UI/UX with James.",
  },
  {
    role: "CAD Designer",
    name: "Danny Leung",
    content:
      "Danny's role was to create the physical version of the device and 3D print it but due to budget and time, he was only able to make a smaller version.",
  },
  {
    role: "Game Developer",
    name: "Danial Lee",
    content:
      "Danial was our Game Developer, he was tasked to create the game that would be used on our device but due to an unknown reason, he was not able to complete it.",
  },
  {
    role: "Front-End Developer",
    name: "Bradlee King",
    content:
      "As the web developer of the group, my main responsibility was creating the website that would promote our product. however I also was responsiable for creating the User Interface for the device.",
  },
];

export const TeamList = () => {
  const cardbg = useColorModeValue("gray.200", "blackAlpha.300")
  return (
    <Box>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 3
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Who was in my Team
      </Heading>
      <Text mt={"6px"}>
        As mentioned in the summary, there was six people in total for our
        group.
      </Text>
      <Flex>
        <Box name="team_description" display={"flex"} my={"10px"}>
          <Wrap spacingX={"-20px"}>
            {TeamData.map((data, index) => {
              return (
                <WrapItem cursor={"default"}>
                  <Box
                    rounded={"md"}
                    p={"6px"}
                    bg={cardbg}
                    w={{ base: "auto", md: "400px" }}
                    h={{ base: "full", md: "240px" }}
                    mb={"10px"}
                  >
                    <Text
                      fontSize={"15px"}
                      color={"#E5712F"}
                      textTransform={"uppercase"}
                      fontWeight={"extrabold"}
                      letterSpacing={"1.5px"}
                    >
                      {data.role}
                    </Text>
                    <Heading borderBottom={"2px solid #E5712F"}>
                     {data.name}
                    </Heading>
                    <Text>
                      {data.content}
                    </Text>
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};
