import {
  Box,
  VStack,
  Text,
  Heading,
  HStack,
  Link,
  Tag,
  useColorModeValue,
  useColorMode,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { SiArduino, SiVisualstudiocode, SiXcode } from "react-icons/si";
import MyCodeComponent from "../../components/CodeBlock";
import Colours from "../../themes/colours";

const Development_Projects = ({ bg, tc }) => {
  useEffect(() => {
    document.title = "Bradlee King || DM1113 Development Projects";
  });

  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <React.Fragment>
      <Box
        name="DevelopmentProjects2"
        h={"100%"}
        w={"full"}
        bg={bg}
        color={tc}
        pt={"5rem"}
        px={"6%"}
        fontWeight={"semibold"}
        justifyContent={"center"}
      >
        <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"} spacing={"5%"}>
            <VStack align={"start"}>
              <Text
                fontSize={"15px"}
                color={"#E5712F"}
                textTransform={"uppercase"}
                fontWeight={"extrabold"}
                letterSpacing={"1.5px"}
              >
                DM1113: Development Projects A 22/23 (S1)
              </Text>
              <Heading
                letterSpacing={"1px"}
                fontSize={{ base: "4xl", md: "5xl" }}
                display={"inline-block"}
                textTransform={"uppercase"}
              >
                Set Class Exercises
              </Heading>
              <HStack>
                <Tag bg={"#E5712F"}>University set exercises</Tag>
                <Tag bg={"#E5712F"}>
                  <Link href="https://github.com/kingofbrad/" target={"_blank"}>
                    Link to Github
                  </Link>
                </Tag>
              </HStack>
            </VStack>
            <TableofContents />
          </VStack>

          <VStack align={"start"}>
            <Section1 boxBg={boxBg} />
            <Section2 boxBg={boxBg} />
            <Section3 boxBg={boxBg} />
          </VStack>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Development_Projects;

const Section1 = ({ boxBg }) => {
  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      document.getElementById("error").innerHTML =
        "The User GeoLocation is not supported on this device";
    }
  }

  function showPosition(position) {
    document.getElementById("coordsLat").innerHTML = position.coords.latitude;

    document.getElementById("coordsLong").innerHTML = position.coords.longitude;
  }

  return (
    <React.Fragment>
      <Box marginTop={"10%"}>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={"#E5712F"}
          fontWeight={"extrabold"}
        >
          Exercise 1
        </Text>
        <Heading
          letterSpacing={"1px"}
          fontSize={{ base: "2xl", md: "3xl" }}
          display={"inline-block"}
          name="Showing Latitude and Longitude"
          marginBottom={"10px"}
        >
          Showing Latitude and Longitude
        </Heading>
        <Text>
          Our first exercise was to obtain the longitude and latitude from the
          users device. This is achieved via getting the users GeoLocation from
          the navigator api that is included in javascript and many browsers.
          The function to obtain the latitude and longitude can be see below
        </Text>
        <Text marginTop={"10px"}>
          The second function displays the value returned from the api into a
          html element. In this case I have set the latitude and longitude to
          replace the innerHTML with the value that come back. It is controlled
          via the IDs set on the html element. If the api cannot retrieve a
          value for both latitude and longitude then an error will occur at the
          bottom the box.
        </Text>
        <VStack spacing={"30px"} marginTop={"15px"}>
          <MyCodeComponent language="javascript" code={geolocation} />
          <MyCodeComponent language="javascript" code={showPositioncode} />
        </VStack>
        <Tag
          padding={"10px"}
          marginTop={"10px"}
          marginBottom={"10px"}
          background={Colours.peru}
        >
          Disclaimer: The value returned it not saved and will be deleted when
          the page refreshes.
        </Tag>
        <Box w={"100%"}>
          <Button onClick={getUserLocation}>
            Click here to reveal your location
          </Button>
          <VStack paddingTop={"20px"}>
            <HStack
              w={"50%"}
              justifyContent={"space-between"}
              bg={boxBg}
              h={"60px"}
              borderRadius={"10px"}
              padding={"2%"}
            >
              <VStack>
                <Text>Longitude</Text>
                <Text id="coordsLong"></Text>
              </VStack>
              <Text id="error" />
              <VStack>
                <Text>Latitude</Text>
                <Text id="coordsLat"></Text>
              </VStack>
            </HStack>
            <Text id="error" />
          </VStack>
        </Box>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={"#E5712F"}
          fontWeight={"extrabold"}
          marginTop={"20px"}
        >
          The usage of the this function
        </Text>
        <Text marginTop={"10px"}>
          The usage for this function ranges from a varity of systems. Such as
          finding the nearest resturant to your location or using the
          geolocation to display where the user is on the map and can direct the
          user to their desired location.
        </Text>
        <Text>
          Some examples of websites using the geolocation includes: Google, Just
          Eat, Amazon, etc. There is no need for this function to be needed for
          my portfolio however its a nice bit of knowledge to know for when this
          may occurs in the projct.
        </Text>
      </Box>
    </React.Fragment>
  );
};

const Section2 = ({ boxBg }) => {
  function TextOverride() {
    document.getElementById("text").innerText = "General Kenobi";
  }

  return (
    <React.Fragment>
      <VStack paddingTop={"10%"}>
        <VStack spacing="20px" align={"start"}>
          <Text
            textTransform={"uppercase"}
            fontSize={"13px"}
            color={"#E5712F"}
            fontWeight={"extrabold"}
          >
            Exercise 2
          </Text>
          <Heading
            letterSpacing={"1px"}
            fontSize={{ base: "2xl", md: "3xl" }}
            display={"inline-block"}
            name="Showing Latitude and Longitude"
            marginBottom={"10px"}
          >
            Changing the Text with a Button Click
          </Heading>
          <Text marginBottom={"10px"}>
            Similar to how we added the longitude and latitude into the html,
            changing the text includes the same process which can be seen in the
            code below. The only thing that would be different is the
            'getElementById("text")' as it needs to match the ID set to the HTML
            element.
          </Text>
          <MyCodeComponent language="javascript" code={changeText} />
          <VStack marginTop={"30px"}>
            <Button onClick={TextOverride}> Click me to change the text</Button>
            <Text id="text">Hello There...</Text>
          </VStack>
          <Text
            textTransform={"uppercase"}
            fontSize={"13px"}
            color={"#E5712F"}
            fontWeight={"extrabold"}
          >
            The usage of this function.
          </Text>
          <Text>
            The main usage for this function within the real world would be
            change information that get retrieved from a server or when the user
            is doing a online quiz. The server example would be that the name
            would have a placeholder inside until they login and the function
            would update the text with the name pulled from the server. The
            other example is a quiz, the function can be used fro when the user
            wants to see the answer to the question so this function would
            change the text that provides the answer.
          </Text>
          <Text>
            At this current point of time, i have no need to use this function
            in any projects or future one to my understanding, however if the
            time comes to use something similar to this then it will be applied.
          </Text>
        </VStack>
      </VStack>
    </React.Fragment>
  );
};

const Section3 = () => {
  return (
    <React.Fragment>
      <VStack paddingTop={"10%"}>
        <VStack spacing={"20px"} align={"start"}>
          <Text
            textTransform={"uppercase"}
            fontSize={"13px"}
            color={"#E5712F"}
            fontWeight={"extrabold"}
          >
            Exercise 3
          </Text>
          <Heading
            letterSpacing={"1px"}
            fontSize={{ base: "2xl", md: "3xl" }}
            display={"inline-block"}
            name="Showing Latitude and Longitude"
            marginBottom={"10px"}
          >
            Button Ratios along with multiple choice.
          </Heading>
          <Text>
            Another one of our tasks was to create multiple choice ratio buttons
            to allow user to select one answer and submit it. Similar to quiz
            app/website. This task would expose us to using javascript for 
          </Text>
          <Box>
            
          </Box>
        </VStack>
      </VStack>
    </React.Fragment>
  );
};

const TableofContents = () => {
  const { logoToggle, setLogoToggleMode } = useColorMode();
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ContentItem title="What is this module">
          This module would help us to learn on how certain aspects of website
          worked and how we would implement them into our portfolio and any
          future web development
        </ContentItem>
        <ContentItem title="End Goal">
          The end goal of the module was help me gain a solid foundation of
          knowledge within the web development section and how to includes the
          newly founded knowledge in our own portfolios
        </ContentItem>
        <ContentItem title="Software">
          <VStack align={"start"}>
            <HStack>
              <SiVisualstudiocode size={"20px"} />
              <Text> Visual Studio Code</Text>
            </HStack>
            <HStack>
              <SiArduino size={"20px"} />
              <Text> Arduino</Text>
            </HStack>
          </VStack>
        </ContentItem>
      </SimpleGrid>
    </Box>
  );
};

export const ContentItem = (props) => {
  return (
    <Box width={"100%"} height={"100%"} fontWeight={"medium"}>
      <Text
        letterSpacing={"1px"}
        fontSize={{ base: "20px", md: "2xl" }}
        textTransform={"uppercase"}
        fontWeight={"bold"}
      >
        {props.title}
      </Text>
      {props.children}
    </Box>
  );
};

export const ContentListItem = (props) => {
  return (
    <HStack>
      <BsArrowReturnRight />
      <Text fontWeight={"semibold"}>{props.title}</Text>
    </HStack>
  );
};

let geolocation = `
function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      document.getElementById("error").innerHTML =
        "The User GeoLocation is not supported on this device";
    }
  }
`;

let showPositioncode = `
function showPosition(position) {
    document.getElementById("coordsLat").innerHTML = position.coords.latitude;

    document.getElementById("coordsLong").innerHTML = position.coords.longitude;
  }
`;

let changeText = `
function TextOverride() {
    document.getElementById("text").innerText = "General Kenobi";
  }
`;
