import React, { useRef } from "react";
import { FaBars, FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { BsBarChartFill, BsFillPersonLinesFill } from "react-icons/bs";
import { IoCodeWorkingSharp } from "react-icons/io5";
import { MdContactPage } from "react-icons/md";
import { AiFillHome, AiFillIdcard } from "react-icons/ai";
import { Link as LinkScroll } from "react-scroll";
import {
  Drawer,
  Link,
  useColorModeValue,
  useDisclosure,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Heading,
  Icon,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconDarkMode,
  TextDarkMode,
  LogoToggle,
} from "../components/sub-components/DarkModetoggle";
import { Footer } from "./footer";

const Navbar = ({ background, textcolor }) => {
  const navbg = useColorModeValue(
    "rgba(255, 255, 255, 0.2)",
    "rgba(25, 25, 27, 0.8)"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Box
      pos={"fixed"}
      w={"100%"}
      h={"80px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={"1rem"}
      bg={navbg}
      className="navbarBlur"
      color={textcolor}
      zIndex={"modal"}
    >
      <LogoToggle />
      <Box display={"flex"} gap={{ md: "5px", base: "10px" }}>
        <DeskTopNav />
        <MobileNav />
      </Box>
      <SocialIcons />
    </Box>
  );
};

export default Navbar;

const DeskTopNav = ({ background }) => {
  return (
    <Box display={{ base: "hidden", md: "flex" }} fontWeight={"semibold"}>
      <ul className=" hidden md:flex items-center">
        <li>
          <RouterLink
            to="/"
            duration={500}
            _hover={{ borderBottom: "2px solid white" }}
            onClick={() => window.scrollTo(0, 0)}
          >
            Home
          </RouterLink>
        </li>
        <li>
          <Link
            as={LinkScroll}
            to="about"
            smooth={true}
            duration={500}
            _hover={{ borderBottom: "2px solid white" }}
            transition="ease-in2s"
          >
            About
          </Link>
        </li>
        <li>
          <Link
            as={LinkScroll}
            to="skills"
            smooth={true}
            duration={500}
            _hover={{ borderBottom: "2px solid white" }}
            transition="ease-in2s"
          >
            Skills
          </Link>
        </li>
        <li>
          <Link
            as={LinkScroll}
            to="work"
            smooth={true}
            duration={500}
            _hover={{ borderBottom: "2px solid white" }}
            transition="ease-in2s"
          >
            Work
          </Link>
        </li>
        <li>
          <Link
            as={LinkScroll}
            to="contact"
            smooth={true}
            duration={500}
            _hover={{ borderBottom: "2px solid white" }}
            transition="ease-in2s"
          >
            Contact
          </Link>
        </li>
        <li>
          <IconDarkMode />
        </li>
      </ul>
    </Box>
  );
};

const MobileNav = ({ navbg }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const silverToggle = useColorModeValue("#8C8C8C", "#C0C0C0");
  const btnRef = useRef();
  return (
    <>
      <IconButton
        display={{ md: "none", base: "flex" }}
        icon={<FaBars />}
        onClick={onOpen}
        ref={btnRef}
      />

      <Drawer
        isOpen={isOpen}
        placement={"right"}
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent bg={"white"} _dark={{ background: "#19191B" }}>
          <DrawerCloseButton onClick={onClose} />
          <DrawerBody>
            <Box mt={"20px"} fontWeight={"semibold"}>
              <Box
                textAlign={"end"}
                display={"flex"}
                flexDir={"column"}
                gap={"2rem"}
              >
                <Heading textAlign={"center"} color={silverToggle}>
                  Bradlee King
                </Heading>
                <TextDarkMode />
                <Divider mt={"0.75rem"} />
                <Link
                  as={RouterLink}
                  alignItems={"center"}
                  letterSpacing={"2px"}
                  fontSize={"2xl"}
                  display={"flex"}
                  justifyContent={"end"}
                  to="/"
                  onClick={onClose}
                >
                  Home
                  <Icon ml={"10px"} as={AiFillHome} />
                </Link>
                <Divider />
                <Link
                  alignItems={"center"}
                  letterSpacing={"2px"}
                  fontSize={"2xl"}
                  display={"flex"}
                  justifyContent={"end"}
                  as={LinkScroll}
                  smooth={true}
                  duration={"300ms"}
                  to="about"
                  onClick={onClose}
                >
                  About
                  <Icon ml={"10px"} as={AiFillIdcard} />
                </Link>
                <Divider />
                <Link
                  alignItems={"center"}
                  letterSpacing={"2px"}
                  fontSize={"2xl"}
                  display={"flex"}
                  justifyContent={"end"}
                  as={LinkScroll}
                  smooth={true}
                  duration={"300ms"}
                  to="skills"
                  onClick={onClose}
                >
                  Skills
                  <Icon ml={"10px"} as={BsBarChartFill} />
                </Link>
                <Divider />
                <Link
                  alignItems={"center"}
                  letterSpacing={"2px"}
                  fontSize={"2xl"}
                  display={"flex"}
                  justifyContent={"end"}
                  as={LinkScroll}
                  smooth={true}
                  duration={"300ms"}
                  to="work"
                  onClick={onClose}
                >
                  Work
                  <Icon ml={"10px"} as={IoCodeWorkingSharp} />
                </Link>
                <Divider />
                <Link
                  alignItems={"center"}
                  letterSpacing={"2px"}
                  fontSize={"2xl"}
                  display={"flex"}
                  justifyContent={"end"}
                  as={LinkScroll}
                  smooth={true}
                  duration={"300ms"}
                  to="contact"
                  onClick={onClose}
                >
                  Contact
                  <Icon ml={"10px"} color={"white"} as={MdContactPage} />
                </Link>
                <Divider />
                <Footer onClose={onClose} silverToggle={silverToggle} />
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const SocialIcons = () => {
  return (
    <Box
      display={{ base: "none", md: "none", lg: "flex" }}
      pos={"fixed"}
      flexDir={"column"}
      top={"300px"}
      left={"0%"}
    >
      <Box>
        <Link
          w={"160px"}
          h={"45px"}
          display={"flex"}
          alignItems={"center"}
          ml={"-120px"}
          _hover={{ marginLeft: "-10px" }}
          transition={"300ms"}
          bg={"rgb(37, 99, 235)"}
          p={2}
          href="https://www.linkedin.com/in/bradlee-king-514991163/"
          target={"_blank"}
          rel={"noreferrer"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
            color={"gray.200"}
            fontSize={"1xl"}
            pl={"0.75rem"}
          >
            {" "}
            LinkedIn <FaLinkedin size={30} />
          </Box>
        </Link>
        <Link
          w={"160px"}
          h={"45px"}
          display={"flex"}
          alignItems={"center"}
          ml={"-120px"}
          _hover={{ marginLeft: "-10px" }}
          transition={"300ms"}
          bg={"black"}
          p={2}
          href="https://github.com/kingofbrad"
          target={"_blank"}
          rel={"noreferrer"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
            color={"gray.200"}
            fontSize={"1xl"}
            pl={"0.75rem"}
          >
            GitHub <FaGithub size={30} />
          </Box>
        </Link>
        <Link
          w={"160px"}
          h={"45px"}
          display={"flex"}
          alignItems={"center"}
          ml={"-120px"}
          _hover={{ marginLeft: "-10px" }}
          transition={"300ms"}
          bg={"rgb(96 165 250)"}
          p={2}
          href="https://twitter.com/Bradlee47125130"
          target="_blank"
          rel={"noreferrer"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
            color={"gray.200"}
            fontSize={"1xl"}
            pl={"0.75rem"}
          >
            Twitter <FaTwitter size={30} />
          </Box>
        </Link>
        <Link
          w={"160px"}
          as={RouterLink}
          h={"45px"}
          display={"flex"}
          alignItems={"center"}
          ml={"-120px"}
          _hover={{ marginLeft: "-10px" }}
          transition={"300ms"}
          bg={"gray.400"}
          p={2}
          to="/resume"
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"100%"}
            color={"gray.200"}
            fontSize={"1xl"}
            pl={"0.75rem"}
          >
            Resume <BsFillPersonLinesFill size={30} />
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
