import { Box, Heading, Text, Image, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import GeolocationCode from "../../assets/uni-work/GeoLocationCode.png";
import TextOverWriteCode from "../../assets/uni-work/TextOverWriteCode.png";
import ArdunioCode from "../../assets/uni-work/ArdunioCode.png";

export const DM1113DevelopmentProject = ({ bg, tc }) => {
  useEffect(() => {
    document.title = "Bradlee King || DM1113 Development Projects";
  });

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      document.getElementById("error").innerHTML =
        "Geolocation is not supported on this device";
    }
  }
  function showPosition(position) {
    document.getElementById("coordinates").innerHTML =
      "Lat" +
      position.coords.latitude +
      "<br> Long:" +
      position.coords.longitude;
  }

  function TextReWrite() {
    document.getElementById("text").innerHTML = "Hello There";
  }
  return (
    <Box
      onLoad={() => {
        window.scrollTo({ top: 0, left: 0 });
      }}
      name="DevelopmentProjects"
      h={"100%"}
      w={"full"}
      bg={bg}
      color={tc}
      pt={"5rem"}
      px={"6%"}
      fontWeight={"semibold"}
      justifyContent={"center"}
    >
      <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
        <Box>
          <Text
            fontSize={"15px"}
            color={"#E5712F"}
            textTransform={"uppercase"}
            fontWeight={"extrabold"}
            letterSpacing={"1.5px"}
          >
            DM1113 Tasks
          </Text>
          <Box
            display={{ md: "flex" }}
            justifyContent={{ md: "space-between" }}
          >
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              borderBottom={"3px solid #E5712F"}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              Development Projects
            </Heading>
          </Box>
          <Box>
            <Text mt={"0.75rem"}>
              Throughout the course of Semester 2, we were shown multiple
              interactive components within HTML and how to style them using
              CSS. Ww also dabbled into using Arduinos with Scalextric. This was
              to help us for the upcoming projects that we had to do throughout
              semester 2
            </Text>
            <Text mt={"0.75rem"}>
              At the beginning of the semester we started with some Javascript
              to help us get used to way the langauge operates We built a
              Geolocation script and a overwriting script that would show the
              users current Longitude, Latitude and rewreite some text via a
              button
            </Text>
            <Text
              mt={"10px"}
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              Geolocation
            </Text>
            <Text mt={"0.75rem"}>
              To begin with, we started with the GeoLocation script. The
              Geolocation script would be used for users to get their location
              data for finding their home address, displaying the users current
              location via a map application.
            </Text>
            <Text>
              The code for the Geolocation would look something like this:
            </Text>
            <Image mt={"20px"} rounded={"md"} src={GeolocationCode} />
            <Text mt={"10px"}>
              In the example above, I used a simple HMTL page as this was just
              to learn on how to get it working. Within the body, there would be
              a button to run the function to get the location and a &#60;p&#62;
              tag to display the information once acquired.{" "}
            </Text>
            <Text mt={"10px"}>
              Basically, the first function is simply using the navigator object
              for browser detection then it’s using the ‘geolocation’ property
              by getting the current position of the computer. Attached to the
              Current Position function is another function which will get the
              longitude and latitude. The second function will display the
              longitude and latitude within the &#60;p&#62; tag using the inner
              HTML property and the position object.
            </Text>
            <Box my={"10px"}>
              <Heading fontSize={{ base: "23px", md: "28px" }}>
                Live Demo
              </Heading>
              <Button onClick={getLocation}>Click Here to see location</Button>
              <Text id="coordinates">...</Text>
              <Text id="error"></Text>
            </Box>
          </Box>
          <Box>
            <Text
              mt={"10px"}
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              Overwritting Text
            </Text>
            <Text mt={"10px"}>
              The Second task was a text overwrite when the user will click a
              button and the text will change to what has been hardcoded in the
              string.{" "}
            </Text>
            <Text mt={"10px"}>
              This can be used within many situations such as: changing
              infomation on a profile, have a looping text where the text
              changes automatically or create dummy text to fill gaps
            </Text>
            <Text mt={"10px"}>
              The code for the text overwrite can be seen in the image below.
            </Text>
            <Image mt={"20px"} rounded={"md"} src={TextOverWriteCode} />
            <Text>
              The code above executes the overwriting text function. Its a
              simple function where its takes the id from the &#60;p&#62; tag
              and plants the new text within. its does this my taking the Id
              assigned to the &#60;p&#62; and running it through the document
              object with 'getElementbyID'. In the brackets is the assigned ID
              with the '.innerHTML' property bouncing off it with the new text
              in brackets{" "}
              <Box>
                <Heading fontSize={{ base: "23px", md: "28px" }}>
                  Live Demo
                </Heading>
                <Text>This is where the fun begins</Text>
                <Text id="text">Click button to change the text</Text>
                <Button onClick={TextReWrite}>Click Me</Button>
              </Box>
            </Text>
          </Box>
          <Box>
            <Text
              mt={"10px"}
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              Controlling a Scalextric with an Arduino
            </Text>
            <Text mt={"10px"}>
              During week 8 we were introduced to ardunios. Ardunios are
              basically micro computers that will execute any code that you pass
              through it. It use a combination of C# and C++. As one of the
              developers of the class, me and the other developers worked
              together in order to create the script to control the Car using a
              distance sensor. We managed to get the script working with some
              help from our Tutor but we mostly came up the code.
            </Text>
            <Text mt={"10px"}>Here is the Code that was used.</Text>
            <Image mt={"20px"} rounded={"md"} src={ArdunioCode} />
            <Text mt={"10px"}>
              The code is basically a simple loop script that will be constantly
              looking for movement from a sensor that is hooked to pin 3,
              trigPin. At the top of the script is all the const definitions
              that define what pins we are using to do, also defined a float
              numeral.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
