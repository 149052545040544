import { background, Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Routes, Route } from "react-router";
import Unshackled from "./Unshackled";
import { RSA_Individual_Project } from "./RSA_Individual_Project";
import { ScrollToTop } from "../../components/ScrolltoTop";

import { WorkCards } from "../../components/sub-components/Cards";
import Hive from "./Hive";
import The_Slient_Maze from "./The_Slient_Maze";
import { DM1113DevelopmentProject } from "./DM1113_Development_Projects";
import Unshackled2 from "./Unshackled2";
import Development_Projects from "./Development_Projects";
import { SculkSensor } from "./SculkSensorProject";
import OrokinCollective from "./Orokin Collective";
import FinalYearProject from "./Final Project";

export const Projects = () => {
  const bg = useColorModeValue("white", "#19191B");
  const tc = useColorModeValue("gray.700", "gray.100");
  useEffect(() => {
    document.title = "Bradlee King || Projects";
  });

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ProjectsBody bg={bg} tc={tc} />} />
        <Route path="Unshackled" element={<Unshackled bg={bg} tc={tc} />} />
        <Route
          path="RSA_Individual_Project"
          element={<RSA_Individual_Project bg={bg} tc={tc} />}
        />
        <Route path="hive" element={<Hive bg={bg} tc={tc} />} />
        <Route
          path="theslientmaze"
          element={<The_Slient_Maze bg={bg} tc={tc} />}
        />
        <Route
          path="DevelopmentProjects"
          element={<DM1113DevelopmentProject bg={bg} tc={tc} />}
        />
        <Route
          path="DevelopmentProjects2"
          element={<Development_Projects bg={bg} tc={tc} />}
        />
        <Route path="Unshackled2" element={<Unshackled2 bg={bg} tc={tc} />} />
        <Route path="SculkSensor" element={<SculkSensor bg={bg} tc={tc} />} />
        <Route path="OrokinCollective" element={<OrokinCollective bg={bg} tc={tc} />} />
        <Route path="FinalYearProject" element={<FinalYearProject bg={bg} tc={tc}/>} />
      </Routes>
    </>
  );
};

const ProjectsBody = ({ bg, tc }) => {
  return (
    <>
      <Box
        w={"full"}
        h={{ base: "100%", md: "auto" }}
        name="Projects"
        bg={bg}
        color={tc}
        pt={"150px"}
      >
        <Box
          maxW={"1000px"}
          mx={"auto"}
          px={"1rem"}
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          textAlign={{ base: "start", md: "start" }}
        >
          <Heading textAlign="start" mb={"10px"}>
            All Projects
          </Heading>
          <WorkCards />
        </Box>
      </Box>

      <Routes>
        <Route path="Unshackled" element={<Unshackled />} />
        <Route
          path="RSA_Individual_Project"
          element={<RSA_Individual_Project />}
        />
      </Routes>
    </>
  );
};
