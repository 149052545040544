import {
  Box,
  HStack,
  Text,
  Image,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
  chakra,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import flowchart from "../../assets/projects-imgs/OrokinCollective/Wireframe.png";
import orokinCollectiveCover from "../../assets/projects-imgs/OrokinCollective/Orokin Collective.svg";
import { ReferenceCell } from "./Unshackled";
import { ContentItem, ContentListItem } from "./Unshackled";
import { SiFigma, SiIterm2, SiPostman, SiXcode } from "react-icons/si";
import MyCodeComponent from "../../components/CodeBlock";
import {
  PublishedProperties,
  Endpoint,
  FetchWorldStateAPI,
  FetchSynthTargets,
  FetchItem,
  FetchData,
  APIError,
  APIPlatformPathEndPoint,
  APIPathEndPoint,
  ContentView,
  DashBoardView,
  WorldState,
  CyclesView,
  VoidTrader,
  CircuitChoices,
  Invasions,
  Syndicates,
  SyndicateCardView,
  SyndicateBountyView,
  Nightwave,
  Simaris,
  News,
  Codex,
  Detail,
  Fissures,
  SearchBar,
  Accordian,
  Tabbar,
  ProgressBar,
  CountdownView,
} from "../../Data/OrokinCollectiveData";
const CustomLinkButton = chakra("a", {
  baseStyle: {
    _hover: {
      color: "#E5713F",
    },
  },
});

const OrokinCollective = ({ bg, tc }) => {
  return (
    <React.Fragment>
      <Box
        name="OrokinCollective"
        h={"100%"}
        w={"full"}
        bg={bg}
        color={tc}
        justifyContent={"center"}
        fontWeight={"semibold"}
      >
        <Box pt={"130px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"}>
            <Text
              fontSize={"15px"}
              color={"#E5713F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              DM3104: Negottiated Development Final Project (S3)
            </Text>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              Orokin Collective
            </Heading>
            <HStack>
              <Tag bg={"#E5713F"}>Individual Project</Tag>
              <Tag bg={"#E5713F"}>
                <Link
                  href="https://github.com/kingofbrad/Orokin_Collective"
                  target={"_blank"}
                >
                  Link to Github
                </Link>
              </Tag>
            </HStack>
            <Tag>Word Count: 5267</Tag>
          </VStack>
          <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
            <VStack>
              <Image
                src={orokinCollectiveCover}
                borderRadius={{ base: "10px", md: "15px" }}
              />
              <Tag>Credits: Bradlee King</Tag>
            </VStack>
            <TableofContents />
          </VStack>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <ReferenceList />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OrokinCollective;

const TableofContents = () => {
  const boxBg = useColorModeValue("gray.300", "blackAlpha.300");

  return (
    <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
      <SimpleGrid
        columns={{ base: "1", md: "3", lg: "4" }}
        spacing={"30px"}
        name="contents"
        fontSize={"15px"}
      >
        <ContentItem title="Brief">
          Orokin Collective is an IOS app inspired by Warframe Hub and Navis.
          Orokin Collective uses the Warframe.us API to display useful and
          necessary information to help players on their travel in the solar
          system without leaving the game.
        </ContentItem>
        <ContentItem title="End Goal">
          The end goal for this project was to create a mobile application to
          display as much information possible for the game Warframe.
        </ContentItem>
        <ContentItem title="Role">
          I was the only individual who worked on this project, I made the
          designs within Figma and developed the app within Xcode.
        </ContentItem>
        <ContentItem title="Software">
          <VStack align={"start"}>
            <HStack>
              <SiXcode size={"30px"} />
              <Text fontSize={"14px"} fontWeight={"bold"}>
                Xcode 15
              </Text>
            </HStack>
            <HStack>
              <SiFigma size={"30px"} />
              <Text fontSize={"14px"} fontWeight={"bold"}>
                Figma
              </Text>
            </HStack>
            <HStack>
              <SiPostman size={"30px"} />
              <Text fontSize={"14px"} fontWeight={"bold"}>
                Postman
              </Text>
            </HStack>
            <HStack>
              <SiIterm2 size={"30px"} />
              <Text fontSize={"14px"} fontWeight={"bold"}>
                Iterm2
              </Text>
            </HStack>
          </VStack>
        </ContentItem>
        <ContentItem title="Research & Prep">
          <ContentListItem title="1.0 Brief" />
          <ContentListItem title="1.1 Device Target" />
          <ContentListItem title="1.3 Research | SwiftUI" />
          <ContentListItem title="1.4 Research | Warframe API" />
          <ContentListItem title="1.5 Research | Design Principles" />
          <ContentListItem title="1.6 Wireframe | Figma Design Layout " />
        </ContentItem>
        <ContentItem title="Design | Figma">
          <ContentListItem title="2.0 Wireframe" />
          <ContentListItem title="2.1 Color palette" />
          <ContentListItem title="2.2 Apple Resources" />
          <ContentListItem title="2.3 Creating UI Elements" />
        </ContentItem>
        <ContentItem title="Development | Swift">
          <ContentListItem title="3.0 Setting Up the network Calls" />
          <ContentListItem title="3.1 Home Screen View" />
          <ContentListItem title="3.2 Dashboard View" />
          <ContentListItem title="3.2.1 WorldState Timers Views" />
          <ContentListItem title="3.2.2 Void Trader & Inventory View" />
          <ContentListItem title="3.2.3 Circuit Choices View" />
          <ContentListItem title="3.2.4 Invasions View" />
          <ContentListItem title="3.2.5 Fissures View" />
          <ContentListItem title="3.2.6 Syndicate View" />
          <ContentListItem title="3.3 News View" />
          <ContentListItem title="3.4 Codex" />
          <ContentListItem title="3.4.1 Detail View" />
          <ContentListItem title="3.5 Custom Components" />
        </ContentItem>
        <ContentItem title="End Results">
          <ContentListItem title="4.0 Final Outcome" />
          <ContentListItem title="4.1 What has been accomplished so far" />
          <ContentListItem title="4.2 Time Management" />
          <ContentListItem title="4.3 Conclusion" />
          <ContentListItem title="4.4 References" />
        </ContentItem>
      </SimpleGrid>
    </Box>
  );
};

const Section1 = () => {
  return (
    <VStack name="section1" align={"start"} marginTop={"30px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5713F"}
        fontWeight={"extrabold"}
        paddingTop={"15px"}
      >
        Research & Prep
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Brief
      </Heading>
      <Text>
        This is an individual project for my final year at university. The
        project is an IOS mobile application inspired by the video game,
        Warframe. The application provides the player with useful and relevant
        information to help the player on their travel in the solar system
        without having to leave their game.
      </Text>
      <Text>
        This app has many features including, live events happening in-game,
        Warframe news, daily deals, vendor inventories, certain missions with
        their timer and many more which will listed later. The app utilises a
        community API provided by players of the game as Digital Extremes
        (Developers of the game) have not released an official API as of this
        date (30/04/3034).
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Device Target"
        paddingTop={"15px"}
      >
        Device Target
      </Heading>
      <Text>
        The device target for this mobile app will IOS only as this is project
        for me to improve my IOS development skills. I may consider creating a
        android version at some point in the future, however for this current
        time, it will be IOS only.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Swift Language"
        paddingTop={"15px"}
      >
        Research | Swift Language
      </Heading>
      <VStack>
        <Image
          borderRadius={"10px"}
          src="https://miro.medium.com/max/1400/1*4fDVdzPaeC7IqiW3R1YhAg.webp"
        />
        <Tag>Credits: kenanatmaca.com</Tag>
      </VStack>
      <Text>
        Swift is a language developed by Apple and is used to create
        applications for IOS, iPadOS, TvOS, WatchOS and MacOS. Swift come with a
        framework called SwiftUI which is the successor to UIKIT which is
        another framework used within IOS development. Swift is built of
        Objective-C which Apple also made, it also uses the C syntax.
      </Text>
      <Text>
        In my exploration of SwiftUI, I embarked with an intermediate
        understanding but aimed to deepen my expertise. My primary focus during
        this endeavour centred on seamlessly integrating network calls and APIs
        into SwiftUI applications. Navigating the landscape of SwiftUI
        declarative syntax revealed a world where UI components harmonized
        effortlessly, enhancing user experience.
      </Text>
      <Text>
        However, the main challenge of my research lies in mastering the ways of
        network calls, which are pivotal in contemporary app development. Each
        API integration unveiled the symbiotic relationship between data
        retrieval and interface presentation, emphasising the significance of
        their seamless fusion. Exploring frameworks such as Combine provided
        invaluable tools for asynchronous data fetching and reactive
        programming, enriching my understanding of SwiftUI capabilities. Through
        iterative experimentation, I refined my skills in managing network
        responses, parsing JSON data, and implementing robust error-handling
        mechanisms.
      </Text>
      <Text>
        This journey wasn't merely about mastering a framework; it was a
        transformative experience, expanding my proficiency in crafting
        immersive user experiences through the adept incorporation of network
        calls and APIs.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "3xl", md: "3xl" }}
        display={"inline-block"}
        name="Research | Warframe API"
        paddingTop={"15px"}
      >
        Research | Warframe API
      </Heading>
      <Tag>
        <CustomLinkButton href="https://docs.warframestat.us" target="_blank">
          WarframeStat.us
        </CustomLinkButton>
      </Tag>
      <Text>
        In the research phase of my portfolio project, I immersed myself in the
        exploration of the Warframe API, a pivotal endeavor that profoundly
        impacted the development trajectory of the application. Beginning with a
        fundamental understanding, I embarked on a comprehensive investigation
        to unearth the full scope and potential of this powerful toolset.
      </Text>
      <Text>
        Through meticulous examination of the API documentation and hands-on
        experimentation, I gained insights into its extensive functionalities,
        which spanned from retrieving detailed game-related data to accessing
        dynamic world state information and game alerts.
      </Text>
      <Text>
        The profound impact of the Warframe API on the application's development
        cannot be overstated. By leveraging its capabilities, I was able to
        seamlessly integrate real-time game data and updates into the
        application, enriching the user experience with timely and relevant
        information.
      </Text>
      <Text>
        The API facilitated the retrieval of crucial details regarding in-game
        items, such as weapons, mods, and Warframes, allowing for comprehensive
        feature implementations within the application. Additionally, the API's
        support for dynamic world state information enabled the application to
        reflect the ever-evolving game environment accurately.
      </Text>
      <Text>
        Furthermore, the incorporation of supplementary resources like
        WarframeStat.us extended the application's functionality, providing
        additional tools and utilities for streamlined development. Through
        iterative exploration and experimentation with the Warframe API, I honed
        my proficiency in API integration and expanded my capabilities in
        crafting dynamic and immersive applications.
      </Text>
      <Text>
        This research phase not only deepened my understanding of the Warframe
        ecosystem but also underscored the importance of leveraging robust APIs
        to enhance application functionality and user engagement.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "2xl" }}
        display={"inline-block"}
        name="Research | Design Principles"
        paddingTop={"15px"}
      >
        Research | Design Principles
      </Heading>
      <Text>
        In designing the iOS application, I prioritized adherence to Apple Human
        Interface Guidelines and drew inspiration from past experiences and the
        mobile app Navis. The aim was to create a professional dashboard
        seamlessly integrating Warframe API features. This approach ensured
        familiarity for users while incorporating effective design elements from
        successful applications.
      </Text>
      <Text>
        The dashboard design emphasized clarity, consistency, and
        user-centricity, presenting key information accessibly. Clear navigation
        pathways facilitated easy feature access. An image was integrated to
        enhance visual appeal and contextualize the application.
      </Text>
      <Text>
        In summary, the design process harmonized established guidelines with
        innovative inspiration, resulting in a refined and intuitive dashboard
        that optimally utilized Warframe API features.
      </Text>
    </VStack>
  );
};

const Section2 = () => {
  return (
    <VStack name="Section2" align="start" marginTop="20px">
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
        paddingTop={"15px"}
      >
        Section 2 Design | Figma
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        2.0 Wireframe
      </Heading>
      <VStack>
        <Image src={flowchart} rounded="10px" />
        <HStack>
          <Tag>Image 2.0</Tag>
          <Tag>Credits: Bradlee King</Tag>
        </HStack>
      </VStack>
      <Text>
        The wireframe for Orokin Collective reflects a structured and
        user-friendly design, aimed at providing seamless navigation and easy
        access to essential features. At the top of the hierarchy is the "Home"
        screen, serving as the main entry point for users. From here, users can
        navigate to three primary sections: Dashboard, News, and Codex.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Dashboard
      </Heading>
      <Text>
        The dashboard is the core of the application, divided into serveral
        sub-sections for specific functionalities:
      </Text>
      <VStack>
        <UnorderedList>
          <ListItem>
            Fissures: Provides information on active fissures in the game.
          </ListItem>
          <ListItem>Invasions: Displays current invasion missions.</ListItem>
          <ListItem>
            Syndicates: Offers a detailed view of syndicate missions and
            standings. Selecting "Syndicates View" leads to more in-depth
            information.
          </ListItem>
          <ListItem>
            <Text>
              Timers: This crucial section includes various timers for in-game
              events and rewards:
            </Text>
            <UnorderedList>
              <ListItem>
                Void Trader: Shows the availability of the Void Trader and
                includes a link to the "Void Trader Inventory."
              </ListItem>
              <ListItem>
                WorldState Timers: Displays timers for different in-game events.
              </ListItem>
              <ListItem>
                Daily Deals, Steel Path Rewards, Sortie, Archon Hunt,
                Arbitration, Circuit Choices, and Events: Each provides specific
                details and countdowns related to their respective categories.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </VStack>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        News Section
      </Heading>
      <Text>
        This section keeps users updated with the latest game news, ensuring
        they are informed about recent developments and announcements.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Codex Section
      </Heading>
      <Text>
        The Codex serves as a primary view that allows users to search the
        entire API to retrieve information they may need, with two secondary
        views:
      </Text>
      <VStack>
        <UnorderedList>
          <ListItem>
            Search View: Allows users to search for specific information or
            items within the Codex.
          </ListItem>
          <ListItem>
            Detail View: Provides detailed information on selected items or
            topics.
          </ListItem>
        </UnorderedList>
      </VStack>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Design Process
      </Heading>
      <Text>
        In designing the iOS application, I prioritized adherence to Apple Human
        Interface Guidelines and drew inspiration from past experiences and the
        mobile app Navis. The aim was to create a professional dashboard
        seamlessly integrating Warframe API features. This approach ensured
        familiarity for users while incorporating effective design elements from
        successful applications.
      </Text>
      <Text>
        The dashboard design emphasized clarity, consistency, and
        user-centricity, presenting key information accessibly. Clear navigation
        pathways facilitated easy feature access.
      </Text>
      <Text>
        In summary, the design process established guidelines with greatt
        inspiration, resulting in a refined and intuitive dashboard that
        optimally utilized Warframe API features. Following the Apple Human
        Interface Guidelines ensured a familiar interface for iOS users, while
        design elements from Navis inspired the clean layout, typography, and
        color scheme, contributing to a polished and professional look.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        2.1 Colour Scheme
      </Heading>
      <SimpleGrid columns={{ base: "3", md: "4", lg: "4" }} spacing="20px">
        <Box
          bg="#05161A"
          width="120px"
          height="60px"
          borderRadius="10px"
          padding="10px"
        >
          BlackPearl
        </Box>
        <Box
          bg="#072E33"
          width="120px"
          height="60px"
          borderRadius="10px"
          padding="10px"
        >
          Tiber
        </Box>
        <Box
          bg="#6DA5C0"
          width="120px"
          height="60px"
          borderRadius="10px"
          padding="10px"
        >
          Glacier
        </Box>
        <Box
          bg="#A5A5A5"
          width="120px"
          height="60px"
          borderRadius="10px"
          padding="10px"
        >
          Silver Chalice
        </Box>
      </SimpleGrid>
      <Text>
        The color scheme for the iOS application was carefully selected to
        create a visually appealing and user-friendly interface. The colors were
        inspired by an Instagram post from the account "ui_gradient" (Instagram,
        2024), which featured a combination of deep, muted tones and vibrant
        accents.
      </Text>
      <VStack>
        <UnorderedList>
          <ListItem>
            Background (#05161A): This dark, almost black hue serves as the
            primary background color, providing a sleek and modern foundation
            for the app. It ensures that other elements stand out clearly and
            reduces eye strain during prolonged use.
          </ListItem>
          <ListItem>
            Card Background (#072E33): Slightly lighter than the main
            background, this color is used for card backgrounds. It creates a
            subtle contrast that distinguishes card elements while maintaining a
            cohesive look throughout the app.
          </ListItem>
          <ListItem>
            Titles (#6DA5C0): This light blue shade is used for titles, adding a
            pop of color that draws attention to key headings and important
            information. It contrasts well with the darker background, ensuring
            readability and visual interest.
          </ListItem>
          <ListItem>
            Sub-Heading/Text (#A5A5A5): A soft grey tone is used for
            sub-headings and general text. It provides a balanced contrast
            against the background, maintaining readability without overwhelming
            the user.
          </ListItem>
        </UnorderedList>
      </VStack>
      <Text>
        The chosen color palette not only aligns with modern design trends but
        also enhances the overall user experience by creating a visually
        harmonious interface. Each color was selected to serve a specific
        purpose, contributing to the app’s functionality and aesthetic appeal.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        2.2 Apple Resources
      </Heading>
      <Text>
        To streamline the design process and ensure consistency with iOS
        standards, I utilized Apple resources available on Figma to create the
        application's frame. Figma's comprehensive collection of Apple design
        templates and UI components provided a robust foundation, allowing me to
        align the app's design with the latest iOS guidelines effortlessly.
      </Text>
      <Text>
        These resources included pre-designed elements such as navigation bars,
        tab bars, buttons, and other UI components, which were crucial in
        maintaining a cohesive and professional look throughout the app. By
        leveraging these templates, I was able to focus on customizing and
        enhancing the visual aspects of the app, ensuring a polished and
        user-friendly interface.
      </Text>
    </VStack>
  );
};

const Section3 = () => {
  return (
    <VStack name="Section3" align="start" marginTop="20px">
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
        paddingTop={"15px"}
      >
        Section 3 Development | Swift
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.0 Setting up the network calls
      </Heading>
      <Text>
        The NetworkCall class, annotated with @MainActor, is designed to handle
        various network requests in the iOS application, utilizing the Combine
        framework for reactive programming. This class conforms to the
        ObservableObject protocol, allowing SwiftUI views to reactively update
        based on changes in its properties.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Properties
      </Heading>
      <VStack>
        <UnorderedList>
          <ListItem>
            @Published Properties
            <UnorderedList>
              <ListItem>
                worldState, synthTargets, events, items: These properties hold
                the fetched data for different game elements, such as the world
                state, synthesis targets, events, and items. Each property is a
                model that conforms to the Codable protocol, allowing easy JSON
                decoding.
              </ListItem>
              <ListItem>
                showError: A Boolean to indicate whether an error has occurred
                during data fetching.
              </ListItem>
              <ListItem>
                isFetchingData: A Boolean to track the data fetching process,
                helping in displaying loading indicators.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            API Endpoint
            <UnorderedList>
              <ListItem>
                endPoint: This is the base URL for the Warframe API.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </VStack>
      <MyCodeComponent code={PublishedProperties} language="swift" />
      <Tag>Published Properties</Tag>
      <MyCodeComponent code={Endpoint} language="swift" />
      <Tag>End Point</Tag>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Methods
      </Heading>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        fetchWorldState()
      </Heading>
      <MyCodeComponent code={FetchWorldStateAPI} language="swift" />
      <Text>
        This asynchronous method fetches the world state data from the API. It
        constructs a URL using the base endpoint and the specific path for the
        world state (APIPlayformPathEndPoint.pc). The method performs a network
        request, checks the response status, and decodes the received JSON data
        into a WorldState object. It handles various HTTP status codes to ensure
        robust error handling.
      </Text>
      <Text>
        The JSON response is decoded into the WorldState model and assigned to
        the worldState property, which SwiftUI views can observe and display.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        fetchItem(searchTerm:String)
      </Heading>
      <MyCodeComponent code={FetchItem} language="swift" />
      <Text>
        This method searches for items based on a provided search term. It
        constructs the URL using the base endpoint and the items path. After
        performing the network request and handling the response, it decodes the
        JSON data into an Item object. The method includes detailed error
        handling, specifically addressing type mismatches during JSON decoding.
      </Text>
      <Text>
        The decoded Item object is assigned to the items property, making it
        available for SwiftUI views to use and display.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        fetchSynthTargets()
      </Heading>
      <MyCodeComponent code={FetchSynthTargets} language="swift" />
      <Text>
        Similar to the previous methods, this function fetches synthesis targets
        data. It constructs the URL, makes the network request, checks the
        response, and decodes the data into a Synthtargets object.
      </Text>
      <Text>
        The Synthtargets object is assigned to the synthTargets property, ready
        for use in the UI.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        fetchData()
      </Heading>
      <MyCodeComponent code={FetchData} language="swift" />
      <Text>
        This method initiates the data fetching process for the world state. It
        encapsulates the network request in a Task, handles potential errors,
        and updates the UI accordingly using a Toast component for user
        notifications.
      </Text>
      <Text>
        Upon successful data retrieval, the worldState property is updated,
        triggering UI updates.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Error Handling
      </Heading>
      <MyCodeComponent code={APIError} language="swift" />
      <Text>
        The APIError enum defines various error types that might occur during
        network requests, including invalid URLs, client or server errors,
        invalid data, and type mismatches during JSON decoding.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        API Endpoint Enums
      </Heading>
      <MyCodeComponent code={APIPlatformPathEndPoint} language="swift" />
      <Text>
        APIPlayformPathEndPoint: Defines endpoints for different platforms (PC,
        Xbox, PlayStation, Nintendo Switch).
      </Text>
      <MyCodeComponent code={APIPathEndPoint} language="swift" />
      <Text>
        APIPathEndPoint: Specifies paths for various data types like weapons,
        Warframes, events, synthesis targets, and items.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Connecting Models with API Data
      </Heading>
      <Text>
        Each model, such as WorldState, Synthtargets, Event, and Item, is
        designed to match the structure of the JSON data returned by the
        Warframe API. They conform to the Codable protocol, facilitating
        straightforward decoding from JSON.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Models and Variable Types
      </Heading>
      <Text>
        WorldState, Synthtargets, Event, and Item are models structured to hold
        specific data from the API responses. These models define properties
        that map directly to the JSON fields, ensuring accurate data
        representation.
      </Text>
      <Text>
        The @Published properties in the NetworkCall class are of these model
        types, allowing the SwiftUI views to reactively update based on changes
        in the fetched data.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Data flow in functons
      </Heading>
      <Text>
        When a network request is made, the corresponding function processes the
        response data as follows:
      </Text>
      <VStack>
        <OrderedList>
          <ListItem>
            Network Request: The URL is constructed and the request is made
            using URLSession.shared.data(from:).
          </ListItem>
          <ListItem>
            Response Handling: The response is validated, and HTTP status codes
            are checked.
          </ListItem>
          <ListItem>
            JSON Decoding: The data is decoded into the respective model using
            JSONDecoder().
          </ListItem>
          <ListItem>
            Model Assignment: The decoded data is assigned to the appropriate
            @Published property, making it available for the SwiftUI views to
            observe and use.
          </ListItem>
        </OrderedList>
      </VStack>
      <Text>
        By leveraging Combine and Swift’s async/await syntax, this class ensures
        efficient and responsive data fetching, essential for creating a dynamic
        and user-friendly application.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.1 Home Screen View
      </Heading>
      <MyCodeComponent code={ContentView} language="swift" />
      <Text>
        The ContentView in this SwiftUI application serves as the main
        interface, orchestrating navigation and content display. It utilizes the
        NetworkCall class to manage data fetching and state transitions within
        the app.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Initialization and Appearance
      </Heading>
      <Text>
        At initialization, the appearance of UITabBar is configured to be
        translucent with a background color of woodsmoke, a dark shade enhancing
        the app's visual theme. This customization applies globally within the
        app, ensuring a consistent aesthetic across all views.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Layout and Navigation
      </Heading>
      <Text>
        The view displays different content based on whether data is being
        fetched. If networkModel.isFetchingData is false, it shows the TabView
        with tabs for Dashboard, News, and Codex. Otherwise, it presents a
        SplashScreen, indicating ongoing data retrieval.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        TabView Setup
      </Heading>
      <Text>
        DashBoardView: Displays the main dashboard with relevant game data
        fetched from the API.
      </Text>
      <Text>
        NewsView: Lists the latest news and updates related to the game.
      </Text>
      <Text>
        CodexView: Provides detailed information and a searchable interface for
        game lore and items.
      </Text>
      <Text>
        Each tab is represented by a unique icon and label, with dynamic icons
        for the Codex based on the selected tab state.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Background and Refresh
      </Heading>
      <Text>
        The entire NavigationStack and its components are set against a
        blackPearl background, maintaining the dark theme throughout the app.
      </Text>
      <Text>
        Upon the view’s appearance, a timer is started to fetch data at regular
        intervals (every 60 seconds), ensuring the app remains up-to-date. This
        timer is invalidated when the view disappears to prevent unnecessary
        data fetching and resource usage.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Dynamic and Responsive Design
      </Heading>
      <Text>
        Dynamic Icons: The Codex tab icon changes from "book.closed" to "book"
        based on whether it is the currently selected tab, providing a visual
        cue to the user about its status.
      </Text>
      <Text>
        By using NavigationStack, the app facilitates efficient navigation and
        state management, adapting seamlessly to user interactions and data
        updates.
      </Text>
      <Text>
        This structured approach in ContentView ensures a user-friendly
        experience by managing the flow of data and the visibility of components
        based on network activity, thereby creating a responsive and engaging
        user interface.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2 Dashboard View
      </Heading>
      <MyCodeComponent code={DashBoardView} language="swift" />
      <Text>
        The DashBoardView is designed as the central hub of the application,
        offering users a clear and accessible overview of key game features
        through a tabbed interface. It is built around the NetworkCall class,
        which fetches and manages the data needed for each component of the
        dashboard.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Core Features
      </Heading>
      <Text>
        Utilizing a TabView with four tabs, the view allows users to navigate
        between different game states—World State, Fissures, Invasions, and
        Syndicates—each represented by a separate view. The tabs are managed by
        currentTab, a state variable that controls the active tab.
      </Text>
      <Text>
        The interface adopts a page-style tab view, which is always visible,
        facilitating user interaction and enhancing the navigational experience.
        The use of .ignoresSafeArea ensures that the tabs utilize the full
        screen, providing a more immersive user experience.
      </Text>
      <Text>
        Transitions between tabs are animated with an ease-in-out effect, making
        the interaction smooth. The overall foreground style is set to white,
        contrasting sharply against the blackPearl background, which maintains
        the app’s dark theme consistency.
      </Text>
      <Text>
        This view is pivotal in providing a structured and user-friendly
        interface that allows players to quickly and efficiently access detailed
        information about various aspects of the game without leaving the
        application.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.1 WorldState View
      </Heading>
      <MyCodeComponent code={WorldState} language="swift" />
      <Text>
        The WorldStateView within the app primarily hosts the TimerScrollView, a
        component designed to display various timers and game state updates. It
        leverages data from the NetworkCall class to show time-sensitive events
        and cycles impacting gameplay.
      </Text>
      <Text>
        The view structure is straightforward, using a vertical stack to house
        the scroll view that presents multiple game-related views, each linked
        to specific world states and events.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Functionality and Interaction
      </Heading>
      <Text>
        The TimerScrollView aggregates different game events like Void Trader
        appearances, Arbitration, and specific missions, each rendered within
        their respective view components like VoidTraderView and
        ArbitrationView.
      </Text>
      <Text>
        Elements within the scroll view are dynamically adjusted for visibility
        based on data validity and error states, ensuring a seamless user
        experience even during data fetching errors.
      </Text>
      <Text>
        The views maintain a clean and thematic design, adhering to the game's
        aesthetic with a consistent use of the blackPearl color for backgrounds
        and white for text, ensuring readability and visual consistency.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Cycles View
      </Heading>
      <MyCodeComponent code={CyclesView} language="swift" />
      <Text>
        The CyclesView displays planetary cycles such as day and night or
        weather conditions, which are critical for planning game activities. It
        dynamically renders information based on the state of various planetary
        cycles fetched from the Warframe game data.
      </Text>
      <Text>
        The view uses the CyclesCard (View 3.5 Custom Componets for code)
        component to represent different planetary conditions, such as day or
        night and weather phases, which are essential for gameplay strategies.
      </Text>
      <Text>
        Each cycle card displays a countdown to the next phase, helping players
        prepare for changes in the game environment.
      </Text>
      <Text>
        The view maintains a thematic consistency with dark backgrounds and
        white text, enhancing the visual impact and readability of the cycle
        information.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.2 Void Trader
      </Heading>
      <MyCodeComponent code={VoidTrader} language="swift" />
      <Text>
        The VoidTraderView within the application provides users with dynamic
        updates about the Void Trader's status in the Warframe game,
        specifically focusing on Baro Ki'Teer.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Interface and Interaction
      </Heading>
      <Text>
        The view showcases the Void Trader’s character, Baro Ki'Teer, and
        location in a visually appealing layout. An image of "Baro Ki'Teer"
        serves as a background, overlaid with a dark opacity layer to enhance
        text readability.
      </Text>
      <Text>
        A custom TimeRemainingTimer_Days_View component (View 3.5 Custom
        Components for code) displays the time until the Void Trader's next
        activation and subsequent expiry, helping players plan their
        interactions.
      </Text>
      <Text>
        Users can tap on the view to display the full inventory of the Void
        Trader in a fullscreen cover. This modal view presents detailed
        information about available items, including their cost in credits and
        ducats, enabling users to make informed trading decisions.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Visual Design
      </Heading>
      <Text>
        The use of rounded corners and shadow effects on the image adds depth to
        the layout, while a consistent color scheme of silver chalice for text
        ensures readability against the darker background.
      </Text>
      <Text>
        The fullscreen cover is designed with a gradient background from colors
        "affair" to "mirage," providing a rich visual experience. Inventory
        items are listed in a scrollable view, each separated by a colored
        divider for clear distinction.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        User Interaction
      </Heading>
      <Text>
        A tap gesture is implemented to check if the inventory is not empty,
        allowing users to view detailed inventory if available. This interaction
        is animated to enhance user experience, emphasizing the application’s
        dynamic and responsive design.
      </Text>
      <Text>
        This view not only informs players about the Void Trader's status but
        also enriches the user experience with detailed inventory management,
        seamlessly integrating essential game information into the user
        interface.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.3 Circuit Choices
      </Heading>
      <MyCodeComponent code={CircuitChoices} language="swift" />
      <Text>
        The CircuitChoicesView is a specialized component within the app that
        offers users insights into the possible choices within the Duviri cycle
        from the Warframe game. This view leverages data fetched by the
        NetworkCall class, providing a user-friendly interface for exploring
        different game options.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        User Interface
      </Heading>
      <Text>
        The view begins with a clean and informative layout. The text "Circuit"
        prominently displays at the top, with a subtle invitation to "View the
        possible choices" and an interactive prompt "Tap to View." This setup
        encourages user interaction.
      </Text>
      <Text>
        The interface uses a dark theme with text in silver chalice on a blue
        charcoal background, consistent with the app's overall dark mode theme.
        The rounded rectangle shape for the view container enhances visual
        appeal and matches the app's design language.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Functionality and Interaction
      </Heading>
      <Text>
        A tap gesture on the main view toggles the openChoices state, which
        triggers a sheet to present detailed options available within the Duviri
        cycle.
      </Text>
      <Text>
        Upon interaction, the sheet displays a structured view with categorized
        choices such as modes and specifics about Warframes and weapons. Each
        choice is detailed with its own background and styling, making the
        options clear and distinct.
      </Text>
      <Text>
        The view dynamically renders information based on the current data from
        the NetworkCall instance. It shows the first and last choices from the
        Duviri cycle, providing a comprehensive view of the available options.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.4 Invasions
      </Heading>
      <MyCodeComponent code={Invasions} language="swift" />
      <Text>
        The InvasionsView in the app serves as a central hub for displaying
        current invasions in the Warframe game, utilizing data from the
        NetworkCall class to provide up-to-date information. This view plays a
        crucial role in keeping players informed about ongoing conflicts and
        opportunities within the game.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Design and Layout
      </Heading>
      <Text>
        The InvasionsView encapsulates the InvasionsList, a scrollable view that
        dynamically displays each ongoing invasion not yet completed.
      </Text>
      <Text>
        Each invasion is represented by an InvasionCard, which details key
        aspects such as the location (node), description, attacking and
        defending factions, and their respective rewards. Background images for
        these cards are sourced from a collection of Warframe planetary
        skyboxes, enhancing the thematic engagement of the interface.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Dynamic Content and User Interaction
      </Heading>
      <Text>
        The invasions are fetched and updated in real time, reflecting the
        game's current state. This ensures players have the latest information
        to strategize their gameplay.
      </Text>
      <Text>
        A special component within the list, the ConstructionProgressView, shows
        the progress of major constructions like the Fomorian and Razorback.
        This view not only informs players of the construction status but also
        engages them with graphical progress bars and faction-specific imagery.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Visual Aesthetics and Functionality
      </Heading>
      <Text>
        The design maintains a consistent theme with dark backgrounds and
        highlighted text to enhance readability. Rounded corners and structured
        layouts in the cards create a clean and organized appearance.
      </Text>
      <Text>
        This view effectively marries functionality with aesthetics, creating a
        compelling and informative section that enhances player engagement and
        game planning.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.5 Fissures View
      </Heading>
      <MyCodeComponent code={Fissures} language="swift" />
      <Text>
        The FissureView in the app provides players with a specialized interface
        to track and engage with fissure events in Warframe. This view uses the
        NetworkCall class to fetch and display real-time fissure information,
        ensuring players are up-to-date with the latest game dynamics.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Design and User Interaction
      </Heading>
      <Text>
        Central to the view is the FissuresList, which dynamically lists all
        current fissures based on user-selected filters such as all fissures,
        void storms, or steel path challenges. This allows players to customize
        their view according to their gameplay preferences.
      </Text>
      <Text>
        Each fissure is detailed in a FissureCard, which provides essential
        information such as location, enemy type, and the specific relic linked
        to the fissure. The card design incorporates background images
        reflective of the fissure’s planetary location, enhancing the thematic
        relevance and visual engagement.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Visual Elements and Accessibility
      </Heading>
      <Text>
        Background images for the fissures, sourced from Warframe planetary
        skyboxes, help contextualize each event, making the interface not only
        informative but also immersive.
      </Text>
      <Text>
        The design prioritizes easy navigation through a scrollable list and
        clear, concise information displays, ensuring that players can quickly
        grasp the status and requirements of each fissure event.
      </Text>
      <Text>
        This view effectively blends functionality with visual storytelling,
        providing a compelling user experience that aligns with the dynamic
        nature of Warframe’s game environment.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.2.6 Syndicates View
      </Heading>
      <MyCodeComponent code={Syndicates} language="swift" />
      <Text>
        The SyndicatesView in the application serves as a portal for players to
        interact with various factions within the Warframe universe. This view
        effectively leverages data from the NetworkCall class to display
        real-time information about syndicate missions and their bounties.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Design and Interaction
      </Heading>
      <Text>
        The view employs a ScrollView that houses multiple SyndicateCard
        elements. Each card represents a different faction such as the Ostrons,
        Solaris United, Entrati, Nightwave, and Simaris.
      </Text>
      <Text>
        The view dynamically updates based on the current state of syndicate
        missions fetched from the Warframe API. It includes conditional displays
        that toggle bounty views specific to each syndicate, enhancing user
        engagement through interactive elements.
      </Text>
      <Text>
        For the Nightwave syndicate, a specific card is configured to show
        important season information and time-sensitive challenges, with a
        full-screen cover for detailed mission tasks upon interaction.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Functionality
      </Heading>
      <Text>
        Each syndicate card can be tapped to display detailed bounties or
        challenges, reflecting the game's current offerings and player
        requirements.
      </Text>
      <Text>
        The design allows for flexibility in adding or modifying syndicate
        entries as new content becomes available, ensuring the application
        remains relevant and up-to-date.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Syndicate Card View
      </Heading>
      <MyCodeComponent code={SyndicateCardView} language="swift" />
      <Text>
        The SyndicateCard component is designed to provide a concise and
        informative snapshot of each syndicate's current status and offerings
        within the game.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Layout and Design
      </Heading>
      <Text>
        Each card features a faction-specific color scheme and iconography,
        making it visually distinct and aligned with the faction’s theme.
      </Text>
      <Text>
        The card details the syndicate's name, any special notes like seasonal
        updates, and a call to action for viewing more detailed content.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Interaction
      </Heading>
      <Text>
        Tapping on the card expands to a full-screen view where specific bounty
        details or challenges related to the syndicate are displayed, offering
        players a deeper dive into the content.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Syndicate Bounty View
      </Heading>
      <MyCodeComponent code={SyndicateBountyView} language="swift" />
      <Text>
        The SyndicateBountyView enhances the interactive experience by providing
        detailed information about the specific tasks and rewards available from
        the syndicate's bounties.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Functionality and Design
      </Heading>
      <Text>
        The view lists all available jobs or bounties associated with the
        syndicate, including enemy levels, reward stages, and the type of
        activity.
      </Text>
      <Text>
        The design is user-friendly, encouraging players to explore various
        bounties and understand their objectives and rewards.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Nightwave Card View
      </Heading>
      <MyCodeComponent code={Nightwave} language="swift" />
      <Text>
        The NightwaveCardView focuses on displaying special missions and
        challenges associated with the Nightwave syndicate, a critical aspect of
        player progression.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Features and Layout
      </Heading>
      <Text>
        The view highlights active challenges, providing details such as mission
        objectives, reputation gains, and time until expiry, critical for
        players aiming to maximize their rewards.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Simaris View
      </Heading>
      <MyCodeComponent code={Simaris} language="swift" />
      <Text>
        The SimarisTargetView offers a specialized interface for players to
        track down synthesis targets assigned by Simaris, enhancing the lore and
        mission aspects of the game.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Features and Interaction
      </Heading>
      <Text>
        Players can search for specific targets, with the view updating
        dynamically to show locations and details about where and how to find
        these targets.
      </Text>
      <Text>
        Provides comprehensive data on spawn rates, mission types, and enemy
        levels, crucial for planning and executing synthesis tasks.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.3 News View
      </Heading>
      <MyCodeComponent code={News} language="swift" />
      <Text>
        The NewsView in the app is designed to keep players informed about the
        latest updates and announcements in Warframe. It uses the NetworkCall
        class to fetch news items dynamically and display them in a
        user-friendly format.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Features and Functionality
      </Heading>
      <Text>
        The view presents news items in a scrollable list, each item linked
        directly to its source via a clickable link for detailed reading.
      </Text>
      <Text>
        News articles are displayed with accompanying images that fade in with a
        smooth animation, enhancing visual appeal. The images, if not loaded,
        show a placeholder with a dark overlay to maintain the design
        consistency.
      </Text>
      <Text>
        Users can interact with the news items, which are displayed with an
        animated increase in opacity to draw attention. The layout is designed
        to be visually appealing, using a black pearl background that
        complements the dark mode theme of the app.
      </Text>
      <Text>
        This compact and efficient presentation of news ensures that players are
        always just a tap away from the latest game-related information,
        seamlessly integrating real-time news updates within the game
        environment.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.4 Codex View
      </Heading>
      <MyCodeComponent code={Codex} language="swift" />
      <Text>
        The CodexView in the application serves as a comprehensive reference for
        players, providing detailed information about various game elements.
        This view utilizes the NetworkCall class to dynamically fetch and
        display items from the Warframe codex.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        User Interface and Functionality
      </Heading>
      <Text>
        The view incorporates a custom search bar and category filters, allowing
        players to search for specific items or filter content based on
        categories such as weapons, mods, or relics. This enhances user
        experience by enabling targeted searches.
      </Text>
      <Text>
        Items are presented in a scrollable list, where each entry is clickable,
        leading to a detailed CodexDetailView. This detail view provides
        extensive information about each item, including descriptions,
        tradability, and related images.
      </Text>
      <Text>
        Players can mark items as favorites, which are then accessible via a
        toggle in the toolbar. This personalized approach helps players track
        their most referenced or liked items easily.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Visual Design and Interaction
      </Heading>
      <Text>
        A context menu on each item offers options to add or remove it from
        favorites, demonstrating the app’s interactive capabilities.
      </Text>
      <Text>
        The design maintains a dark theme consistent with the overall app, using
        colors like black pearl to ensure readability and visual comfort.
      </Text>
      <Text>
        This view not only acts as an essential tool for information but also
        engages players with its interactive features, making it a vital part of
        the gaming experience.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.4.1 Codex Detail View
      </Heading>
      <MyCodeComponent code={Detail} language="swift" />
      <Text>
        The CodexDetailView offers a detailed look at specific items from the
        Warframe Codex, providing players with extensive information including
        stats, descriptions, and applicable images. This view is essential for
        players seeking a deeper understanding of items' functions and
        characteristics.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Features and User Interaction
      </Heading>
      <Text>
        The view presents comprehensive details such as item name, description,
        and trade status, enhanced with high-quality images sourced directly
        from an official repository.
      </Text>
      <Text>
        Depending on the item's category, the view adapts to show relevant
        additional details. For warframes, this includes statistics like health,
        armor, and abilities; for weapons, it displays specific weapon stats.
      </Text>
      <Text>
        The interface uses a consistent theme with backgrounds in shades of blue
        and black, maintaining aesthetic alignment with the rest of the app. The
        detailed section is well-organized, ensuring that information is easy to
        read and visually appealing.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Development Note
      </Heading>
      <Text>
        Currently, this view is not fully complete, with some categories like
        mods and resources not yet implemented. This ongoing development will
        enhance functionality and provide even more detailed data as it becomes
        available.
      </Text>
      <Text>
        This detailed view not only enriches the user experience by providing
        thorough information about each item but also complements the broader
        Codex interface by allowing users to dive into specifics, making it a
        critical component of the in-game knowledge base.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        3.5 Custom Componets
      </Heading>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Search Bar
      </Heading>
      <MyCodeComponent code={SearchBar} language="swift" />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Accordian
      </Heading>
      <MyCodeComponent code={Accordian} language="swift" />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        TabBar
      </Heading>
      <MyCodeComponent code={Tabbar} language="swift" />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        ProgressBar
      </Heading>
      <MyCodeComponent code={ProgressBar} language="swift" />
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        CountdownView
      </Heading>
      <MyCodeComponent code={CountdownView} language="swift" />
    </VStack>
  );
};

const Section4 = () => {
  return (
    <VStack name="section1" align={"start"} marginTop={"30px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5713F"}
        fontWeight={"extrabold"}
        paddingTop={"15px"}
      >
        End Results
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        4.0 Final Outcome
      </Heading>
      <Text>
        The final outcome of this project is a robust and user-friendly iOS
        application tailored to Warframe players, designed to enhance their
        gaming experience by providing real-time data and detailed information.
        The application serves as a multi-functional tool, integrating various
        features such as dynamic news feeds, detailed codex entries, live event
        tracking, and syndicate missions, all within a cohesive user interface.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Achievements and User Experience
      </Heading>
      <Text>
        The application successfully integrates multiple Warframe game aspects,
        including fissures, invasions, and syndicate details. Each feature is
        thoughtfully presented with real-time updates, ensuring players have the
        latest information at their fingertips.
      </Text>
      <Text>
        The design incorporates interactive elements like search functions,
        filterable lists, and expandable details, which significantly improve
        navigation and user engagement. The ability to mark favorites and
        customize views personalizes the experience, catering to individual
        player preferences.
      </Text>
      <Text>
        A consistent and appealing visual theme complements the dark aesthetic
        of Warframe, while clear layouts and adaptive content displays ensure
        accessibility and ease of use.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "1xl", md: "2xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        Future Enchancements
      </Heading>
      <Text>
        Certain areas of the application, such as the Codex Detail View, are
        slated for further development to expand functionality and provide even
        more comprehensive data.
      </Text>
      <Text>
        This project not only meets its initial goals but also sets a foundation
        for future enhancements that will continue to support the Warframe
        community.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        4.1 What has been accomplished so far
      </Heading>
      <Text>
        The project has successfully integrated key features such as live event
        tracking, detailed codex information, and interactive news updates into
        the Warframe app, enhancing user engagement and providing a seamless,
        informative experience for players navigating the diverse elements of
        the game.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        4.2 Time Management
      </Heading>
      <Text>
        Throughout the development of the Warframe iOS application, a structured
        time management approach was crucial. The strategy involved dedicating
        one week to the development of a new view, followed by another week
        specifically allocated for debugging and refining the implemented
        features.
      </Text>
      <Text>
        This cyclical pattern ensured a steady progression while maintaining
        high-quality standards in the app’s functionality.
      </Text>
      <Text>
        Adopting a method akin to Agile methodology, this approach allowed for
        flexibility, iterative improvements, and the integration of feedback,
        mirroring the Agile practice of iterative development and responsiveness
        to change.
      </Text>
      <Text>
        This methodical process not only streamlined development but also
        enhanced the app’s stability and user experience with each cycle.
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="Client Brief"
        paddingTop={"15px"}
      >
        4.3 Conclusion
      </Heading>
      <Text>
        This project has successfully delivered a feature-rich iOS application
        for Warframe players, enhancing their engagement with the game through a
        seamless integration of essential information and real-time updates.
      </Text>
      <Text>
        By combining functionalities like event tracking, syndicate missions,
        and a dynamic codex, the application not only improves accessibility to
        game resources but also enriches the user experience.
      </Text>
      <Text>
        The adaptive and user-friendly interface ensures that players of all
        levels can navigate and utilize the app effectively. Moving forward,
        continued updates and expansions will aim to address user feedback and
        introduce new features, further solidifying the app as an indispensable
        tool for the Warframe community.
      </Text>
    </VStack>
  );
};

const ReferenceList = () => {
  return (
    <Box pb="30px" paddingTop="20px">
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="UnshackledReferences"
      >
        References
      </Heading>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ReferenceCell
          author="Kenan"
          year="2019"
          name="SwiftUI ile UISearchBar kullanımı"
          link="https://kenanatmaca.com/swiftui-ile-uisearchbar-kullanimi/"
          accessDate="4/05/2024"
        />
        <ReferenceCell
          author="Apple Inc."
          year="2017"
          name="Human Interface Guidelines"
          link="https://developer.apple.com/design/human-interface-guidelines/foundations/app-icons/"
          accessDate="4/05/2024"
        />
        <ReferenceCell
          author="Ludwig Rodriguez"
          year="2019"
          name="Cephalon Navis"
          link="https://github.com/WFCD/navis"
          accessDate="15/4/2024"
        />
        <ReferenceCell
          author="Turtle Beach"
          year="2022"
          name="Warframe Baro Ki'Teer."
          link="https://uk.turtlebeach.com/blog/warframe-baro-kiteer "
          accessDate="17/4/2024"
        />
        <ReferenceCell
          author="RedStarRocket91"
          year="2018"
          name="Warframe Planetary SkyBoxes"
          link="https://imgur.com/gallery/warframe-planetary-skyboxes-YktJ8  "
          accessDate="17/4/2024"
        />
        <ReferenceCell
          author="WFCD"
          year="2022"
          name="Genesis-assets"
          link="https://github.com/WFCD/genesis-assets "
          accessDate="17/4/2024"
        />
        <ReferenceCell
          author="ui_gradient"
          year="2024"
          name="Best color palette"
          link="https://www.instagram.com/p/CxS4DmmSP3j/?img_index=5"
          accessDate="15/4/2024"
        />
      </SimpleGrid>
    </Box>
  );
};
