import React from "react";
import {
  Box,
  HStack,
  Text,
  Image,
  Heading,
  Tag,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { BsGithub } from "react-icons/bs";
import RSAProjectCoverArt from "../../assets/projects-imgs/RSA_Project/RSA_ VR Haptic Feedback Cover Art.svg";
import {
  ContentItem,
  ContentListItem,
  ReferenceCell,
  UnshackledImage,
} from "./Unshackled";
import {
  SiArduino,
  SiFirefox,
  SiMamp,
  SiSublimetext,
  SiUnity,
  SiVisualstudiocode,
} from "react-icons/si";
import { HiArrowNarrowRight } from "react-icons/hi";
import Image1_9_1 from "../../assets/projects-imgs/RSA_Project/Image 1.9.1.png";
import Image2_0_1 from "../../assets/projects-imgs/RSA_Project/Image 2.0.1.png";
import Image2_0_2 from "../../assets/projects-imgs/RSA_Project/Image 2.0.2.png";
import Image2_1_1 from "../../assets/projects-imgs/RSA_Project/Image 2.1.1.png";
import Image2_1_2 from "../../assets/projects-imgs/RSA_Project/Image 2.1.2.png";
import Image2_2_1 from "../../assets/projects-imgs/RSA_Project/Image 2.2.1.png";
import Image2_3_1 from "../../assets/projects-imgs/RSA_Project/Image 2.3.1.png";

export const RSA_Individual_Project = ({ bg, tc }) => {
  return (
    <React.Fragment>
      <Box
        name="RSA_Individual_Project"
        h={"100%"}
        w={"full"}
        justifyContent={"center"}
        bg={bg}
        color={tc}
        fontWeight={"semibold"}
      >
        <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
          <VStack align={"start"}>
            <Text
              fontSize={"15px"}
              color={"#E5712F"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              letterSpacing={"1.5px"}
            >
              DM2112: Project Research A 22/23 (S1)
            </Text>
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              RSA Case Study Project
            </Heading>
            <HStack>
              <Tag bg={"#E5712F"}>Individual Project</Tag>
              <Tag bg={"#E5712F"}>
                <Link
                  href="https://github.com/kingofbrad/RSA-Brief---Amplify-Connections"
                  target={"_blank"}
                  display="flex"
                  alignItems={"center"}
                  gap="5px"
                  _hover={{ border: "none" }}
                >
                  <BsGithub />
                  Link to Github
                </Link>
              </Tag>
            </HStack>
            <Tag>Word Count: 2,390</Tag>
          </VStack>
          <VStack paddingY={"30px"} spacing={"50px"} name="cover art">
            <VStack>
              <Image
                src={RSAProjectCoverArt}
                borderRadius={{ base: "10px", md: "15px" }}
              />
              <Tag>Credits: Bradlee King</Tag>
            </VStack>
            <TableofContents />
          </VStack>
          <Section1 />
          <Section2 />
          <Section3 />
        </Box>
      </Box>
    </React.Fragment>
  );
};

const TableofContents = () => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.300");

  return (
    <Box bg={boxBg} px={"10px"} py={"5px"} borderRadius={"10px"}>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "4" }}
        spacing="20px"
        name="contents"
        fontSize="15px"
      >
        <ContentItem title="Brief">
          <Text>
            The brief provided by the Royal Society of Arts(RSA) in partnership
            with Sky broadband states that Sky broadband would like a device or
            some kind of software to help families that cannot visit each other
            to communicate and interact more.
          </Text>
          <Text>
            The device/software must be within the comfort and safety of the
            family's home otherwise it will defeat the purpose of the brief.
          </Text>
          <Text>
            Sky broadband presented this brief to tackle the isolation of
            COVID-19, even though the restrictions have been lifted, there are
            still families that cannot go out and become distant from other
            families.
          </Text>
        </ContentItem>
        <ContentItem title="End Goal">
          The end goal for this project is to create a device/piece of software
          that will help families communicate more and bond more easily as
          stated in the brief. My end goal is to create a fully functional
          device or application to meet the condition of the brief. My ideas can
          be seen within the initial ideas section.{" "}
        </ContentItem>
        <ContentItem title="Software">
          <VStack align="start">
            <HStack>
              <SiSublimetext size={"20px"} />
              <Text>Sublime Text</Text>
            </HStack>
            <HStack>
              <SiArduino size="20px" />
              <Text>Arduino IDE</Text>
            </HStack>
            <HStack>
              <SiMamp size="20px" />
              <Text>MAMP</Text>
            </HStack>
            <HStack>
              <SiFirefox size="20px" />
              <Text>FIreFox</Text>
            </HStack>
            <HStack>
              <SiUnity size="20px" />
              <Text>Unity</Text>
            </HStack>
            <HStack>
              <SiVisualstudiocode size="20px" />
              <Text>VsCode</Text>
            </HStack>
          </VStack>
        </ContentItem>
        <ContentItem title="Hardware">
          <ContentListItem title="ESP32" />
          <ContentListItem title="Stepper Motor 28byj-48" />
        </ContentItem>
        <ContentItem title="Research & Prep">
          <ContentListItem title="1.0 RSA Brief" />
          <ContentListItem title="1.1 Initial Concepts" />
          <ContentListItem title="1.2 Target Audience" />
          <ContentListItem title="1.3 Research | Arudinos" />
          <ContentListItem title="1.4 Research | PHP" />
          <ContentListItem title="1.5 Research | SQL" />
          <ContentListItem title="1.6 Research | Virtual Reality" />
          <ContentListItem title="1.9 Flowchart of logistics" />
        </ContentItem>
        <ContentItem title="Development">
          <ContentListItem title="2.0 Creating database on phpMyAdmin" />
          <ContentListItem title="2.1 PHP webhook" />
          <ContentListItem title="2.2 Ardunio JSON decoder from webhook" />
          <ContentListItem title="2.3 Unity + Arduino" />
        </ContentItem>
        <ContentItem title="Results">
          <ContentListItem title="3.0 Final Outcome" />
          <ContentListItem title="3.1 Progress so far" />
          <ContentListItem title="3.2 Time Management" />
          <ContentListItem title="3.3 Conculsion" />
          <ContentListItem title="3.4 Handover Document" />
          <ContentListItem title="3.5 References" />
        </ContentItem>
      </SimpleGrid>
    </Box>
  );
};

const Section1 = () => {
  return (
    <VStack align="start" marginTop="20px" name="section1">
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 1 Research & Prep
      </Text>
      <SectionHeading title="RSA Brief" />
      <Text>
        At the beginning of the semester, we were given a range of client projects to choose from, however, no projects catered to what I wanted to advance my skillset in, so I decided to do an individual project on my own. This project was an idea I had for a time before making this a project for university.

        The project is a creation of the in-game item from the popular game Minecraft, called a sculk sensor. This item in the game takes in sound or vibrations and emits a signal which can be used by a player with Redstone or when it naturally spawns to summon a boss.

        My take on the project is to use this concept and connect an Amazon Alexa via AWS and have it act as a trigger system to alert the user.

      </Text>
      <Text>
        This will help those that cannot visit family, for example, if a family
        lives in Scotland and the related family lives in the far south of
        England, travelling is not much of an option most of the time, so have
        this device or application will allow these two families to communicate
        far more and bond just as easily.
      </Text>
      <SectionHeading title="Initial Concepts" />
      <Text>
        When coming up with concepts for this project, my first thought was to
        have a web/application that will help families learn a new language
        whilst playing a game. The player will have to play a card that matches
        the card in the middle in the different languages they have selected.
        The player to lose all cards will win, just like UNO. The cards in the
        player's hand will help them understand what a word or phrase says in a
        different language but not directly translate it.
      </Text>
      <Text>
        My second concept was to have a virtual reality haptic feedback system
        that will relay feedback from the virtual world. For this concept to fit
        the brief, the haptic feedback system would be used with the virtual
        reality headset to allow family members to hug each other and feel the
        presence of the hug.
      </Text>
      <Text>
        In the end, I decided to proceed with the virtual reality concept as
        this project would allow me to improve my knowledge within the
        programming world and I would get to work with new technologies if I
        went with the other concept, I would just be using the same technologies
        I have used in the past. I wanted to challenge myself.
      </Text>
      <SectionHeading title="Target Audience" />
      <Text>
        The main target audience for this project would be families that cannot
        visit each other, this will also apply to certain family members as
        well, like children of the family. As creating a bond with other
        relatives is crucial when they are younger. However, they will need to
        be supervised as they are dealing with expensive technology.
      </Text>
      <SectionHeading title="Research | Arduino" />
      <Text>
        During the progress phase of creating this project. I needed a device
        that would allow me to control the haptic motors. I was looking through
        a combination of Arduinos to use and my tutor recommended using the
        ESP32 board as it provided support for WIFI and Bluetooth and since the
        end project is going to be wireless, this board was perfect for the
        role.
      </Text>
      <Text>
        I could have used an Arduino Uno however the product will be bound by a
        USB serial connection and would not leave much room for the user to move
        around the room. The Arduino board also did not have WIFI or Bluetooth
        functionality, which ruled the Arduino UNO board out.
      </Text>
      <Text>
        Once I knew what board I need, I began to research how to use the ESP32
        board and what software I would need. All my research for the board came
        from a website called “randomnerdtutorials.com”.
      </Text>
      <SectionHeading title="Research | SQL" />
      <Text>
        When working out what software & hardware, I discovered a method to send
        and retrieve data through the network. My tutor mentioned SQL database
        since I was using a NoSQL database for my other project, the SQL
        database would allow me to read and write via a PHP webhook request.
      </Text>
      <Quote
        author="Joe Carder"
        quote="SQL stands for Structured Query Language. It's used for relational databases. A SQL database is a collection of tables that stores a specific set of structured data."
      />
      <SectionHeading title="Research | PHP" />
      <Text>
        Once I knew what services I would need for the project, I research how
        to pull data from the SQL database to the ESP32 board and came back to
        using PHP. PHP stands for “Hypertext Preprocessor”. It is mainly used in
        web development and used to communicate with the backend of the website
        to retrieve data from a database or server.
      </Text>
      <Text>
        In my case, it was used to communicate with the SQL database. Since PHP
        is a loosely typed language, having multiple variables of the same value
        is possible compared to a language such as Swift being quite strict on
        its variable names.
      </Text>
      <SectionHeading title="Research | Virtual Reality" />
      <Text>
        Since this project will include virtual reality, I needed to better
        understand how VR can be used in collaboration with Unity and the ESP32
        system. My focus on VR was Oculus Quest 2 as it’s the newest and most
        budget Virtual reality headset on the market my product was to be
        intended to be cheap enough for those who are on a tight budget.
      </Text>
      <Text>
        The Oculus Quest 2 uses android-based source code and supports a range
        of games and apps. Unity works well with the oculus quest 2 as unity can
        export to android and android has had support for virtual reality for a
        while since google cardboard was released.
      </Text>
      <SectionHeading title="Wireframe of logistics" />
      <Text>
        I created a wireframe for the logic behind the project. The wireframe
        was created within Miro as it provides excellent tools for wireframes
        and prototypes for devices. This wireframe can be seen in image 1.9.1.
      </Text>
      <UnshackledImage
        src={Image1_9_1}
        alt="Wireframe of the logic behind the system"
        ImgRev="1.9.1"
        credits="Bradlee King"
      />
      <Text>
        Within the wireframe, I did both wired and wireless routes as I was not
        100% sure what method I wanted to proceed with. For the wireless, the VR
        headset will communicate with the SQL database to retrieve the value it
        needs. Then the SQL will talk to the ESP32 board via the PHP webhook to
        get the value that the ESP32 needs to send the haptic system.
      </Text>
      <Text>
        The logic behind the wired system is the same for the hardware side but
        for the software side, the communication is handled in the Unity game
        logic as Unity has a set of triggers that will detect when the value
        needs to be changed on the ESP32.
      </Text>
    </VStack>
  );
};

const Section2 = () => {
  return (
    <VStack align={"start"} name="Section2" mt="20px">
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 2 Development
      </Text>
      <SectionHeading title="Creating a database on phpMyAdmin" />
      <Text>
        To begin the project, I needed to create a database within SQL and the
        best option is phpMyAdmin. Due to security restrictions on the
        University server for students, my tutor had to create a database for
        me, however, I do have privileges to modify and add new/ delete tables
        to the database. A table was created to store the value need to handle
        the haptic feedback. The table creation can be seen in image 2.0.1 and
        then the table cells in image 2.0.2.
      </Text>
      <UnshackledImage
        src={Image2_0_1}
        alt="Table Creation Within SQL"
        ImgRev="2.0.1"
        credits="Bradlee King"
      />
      <UnshackledImage
        src={Image2_0_2}
        alt="Adding/Modifying Cells"
        ImgRev="2.0.2"
        credits="Bradlee King"
      />
      <Text>
        New cells are added to the structure page for the SQL database. These
        cells can be configured at any point and change their type of data as
        well, so a cell can go from a String cell to an Int cell or a Boolean
        cell.
      </Text>
      <SectionHeading title="PHP | Webhooks" />
      <Text>
        I would need a webhook to read from the SQL database to get the data.
        The code can be seen in Image 2.1.1. The values in the variables are
        just dummy data to protect the student server at the university.
      </Text>
      <UnshackledImage
        src={Image2_1_1}
        alt="PHP webhook"
        ImgRev="2.1.1"
        credits="Bradlee King"
      />
      <Text>
        The method to use to connect to the SQL database was provided by
        W3Schools. Within the webhook file, I have defined the server’s name,
        username, password, and what database it will need to investigate to
        retrieve the data. Along with the main credentials, an auth variable was
        added for a bit of security using the “_GET” function. It will look
        through the URL, mainly the file name section for the set auth value
        that is defined in the webhook.
      </Text>
      <Text>
        The value needed is then defined and this will be the value to be placed
        into the SQL database with the Webhook. This is also using the “_GET”
        function as well to look in the URL for the value.
      </Text>
      <Text>
        Once everything has been defined, the connection to the actual database
        is required. A function is created to establish a connection to the
        database using the mysqli function, this takes the variables that were
        created at the top of the webhook and connects to the database.
      </Text>
      <Text>
        An if statement controls the connection to the SQL database, if the
        connection comes back with no errors, then the data is inserted into the
        database. This can be seen on line 22 in Image 2.1.1. It takes the value
        needed and places it into the needed cell. Another if statement looks to
        see if a connection is made and runs the query to insert the data. This
        can be seen in lines 25 to 31. The closing else is for the auth.
      </Text>
      <Text>
        To retrieve the data from the SQL database, another webhook had to be
        created, the process is the same until it reaches the end of the
        connection if statement. Instead of inserting it into the database, it
        is selected from the value.
      </Text>
      <Text>
        From lines, 22 to 31 is the function that will fetch the data value and
        convert it to JSON ready for the Arduino to decode. The fetch webhook
        can be seen in image 2.1.2. same as the other webhook, the closing else
        is for the auth.
      </Text>
      <UnshackledImage
        src={Image2_1_2}
        alt="PullRequestWebhook"
        ImgRev="2.1.2"
        credits="Bradlee King"
      />
      <SectionHeading title="Arduino JSON decoder from webhook" />
      <Text>
        To access the JSON created from the webhook on the ESP32 board, a
        third-party library had to be installed called “ArduinoJSON” created by
        Benoît Blanchon. This will allow the ESP32 script to decode the JSON and
        pull the value.
      </Text>
      <Text>
        The script for the ESP32 board can be seen in image 2.2.1. For privacy
        reasons, the WIFI-SSID and WIFI-PASSWORD values were changed for this.
        All the required libraries were imported at the top of the script along
        with any values that needed to be defined.
      </Text>
      <UnshackledImage
        src={Image2_2_1}
        alt="ESP32 Script"
        ImgRev="2.2.1"
        credits="Bradlee King"
      />
      <Text>
        To get the ESP32 board to work with the stepper motor, the steps per
        revolution were defined. The limit for the value from the database was
        also defined as well, the limit will determine what happens later down
        in the script.
      </Text>
      <Text>
        In the setup of the script, the connection to the WIFI is made from the
        values of WIFI-SSID and WIFI-PASSWORD. The connection to the
        pullRequestwWebHook.php is also made via the http.begin function.
      </Text>
      <Text>
        The loop function is constantly running the request script to watch for
        changes within the SQL database. The JSON decoder is also running within
        the loop function to constantly decode the value and make it a useful
        variable for the script.
      </Text>
      <Text>
        The decoded value is placed into a document ready for the if statement.
        This can be seen in lines, 46 to 51. Within the if statement below, it
        looks at the limit and value from the SQL database. If the value is
        greater then, then the stepper motor will start moving, however, if the
        value goes below then the limit, then the stepper motor stops.
      </Text>
      <SectionHeading title="Unity + Arduino" />
      <Text>
        The final system to establish was the communication between unity and
        the ESP32 board. This is a very simple method to do as it only needs a
        few lines of code.
      </Text>
      <Text>
        Using the colliders enter the function to activate the function, the
        example I made was a cube being able to move and collide will another
        cube. when the t colliders activate, they run the coroutine function
        with the Get Request function.
      </Text>
      <Text>
        The example I made to test the system, used two collision functions,
        enter and exit. This function ran the Start Coroutine with the Get
        Request to the URL of the webhook request. Within the example, I had a
        cube that could move and a static cube. The movable cube had the webhook
        script attached. When the movable cube collided with the static cube, it
        would send a web request to the URL along with a value greater than the
        one set in the ESP32 script and on exit, it will do it again but with a
        value less than the limit. This can be seen in image 2.3.1 and in lines
        19 to 29.
      </Text>
      <UnshackledImage
        src={Image2_3_1}
        alt="Unity Script to Update SQL Database"
        ImgRev="2.3.1"
        credits="Bradlee King"
      />
      <Text>
        The URL web request can be seen in lines 31 to 59. This will function
        will just send a web request to the URL placed in the String section.
      </Text>
    </VStack>
  );
};

const Section3 = () => {
  return (
    <VStack align={"start"} name="Section 3" mt="20px">
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={"#E5712F"}
        fontWeight={"extrabold"}
      >
        Section 3 Results
      </Text>
      <SectionHeading title="Outcome" />
      <Text>
        The outcome of this project for this semester was a haptic feedback
        system that can communicate to a virtual world built in the Unity game
        engine.
      </Text>
      <SectionHeading title="Progress so far" />
      <Text>
        The progress that has been made so far is that the haptic feedback is
        provided from the stepper motor when the cube in the virtual world
        touches a static cube. The base template for the system has been created
        and is ready to be expanded on.
      </Text>
      <SectionHeading title="Time Management" />
      <Text>
        Throughout my session this semester, I dedicated three of the seven days
        of the week to working on the project. As this project was not as
        heavily demanding as my other project this semester, it felt that I did
        not need to allocate as much time, unlike the first project.
      </Text>
      <Text>
        The first two days, these days were the developing days, so I would try
        to get the system, the last day was a bug testing day so I would just
        run tests and fix the code that had bugs within.
      </Text>
      <SectionHeading title="Conclusion" />
      <Text>
        To sum up, what has been stated so far, my end goal for the brief was to
        create a device or software to allow families to connect and interact
        from the safety and comfort of their homes. I ended up creating a
        virtual reality haptic feedback system that would allow those families
        to interact within the virtual world.
      </Text>
      <SectionHeading title="Handover Document" />
      <Text>
        The repository for this is hosted on GitHub and the README will explain
        every part of the code of the system and how it works.
      </Text>
      <Link
        bg={"#E5712F"}
        rounded="7px"
        px={{ base: "20px", md: "30px" }}
        py={{ base: "10px", md: "14px" }}
        _hover={{ border: "none", color: "whiteAlpha.700" }}
        target="_blank"
        href="https://github.com/kingofbrad/RSA-Brief---Amplify-Connections"
      >
        <HStack>
          <Text>View Repository</Text>
          <HiArrowNarrowRight />
        </HStack>
      </Link>
      <RSAReferenceList />
    </VStack>
  );
};
const RSAReferenceList = () => {
  return (
    <Box pb="30px">
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        display={"inline-block"}
        name="UnshackledReferences"
      >
        References
      </Heading>
      <SimpleGrid
        columns={{ base: "1", md: "2", lg: "3" }}
        spacing={"20px"}
        name="contents"
        fontSize={"15px"}
      >
        <ReferenceCell
          author="Carder, J."
          year="2020"
          name="What Is SQL Database?"
          blogname="openlogic"
          day="8"
          month="July"
          link="https://www.openlogic.com/blog/what-sql-database"
          accessDate="12/11/2022"
        />
        <ReferenceCell
          author="Donvukovic"
          year="2019"
          name="ESP32/ESP8266 Insert Data into MySQL Database using PHP and Arduino IDE"
          blogname="Random Nerd Tutorials"
          day="7"
          month="June"
          link="https://randomnerdtutorials.com/esp32-esp8266-mysql-database-php/"
          accessDate="14/11/2022"
        />
      </SimpleGrid>
    </Box>
  );
};

const SectionHeading = (props) => {
  return (
    <Heading
      letterSpacing={"1px"}
      fontSize={{ base: "2xl", md: "3xl" }}
      display={"inline-block"}
      name="Client Brief"
    >
      {props.title}
    </Heading>
  );
};

const Quote = (props) => {
  const boxBg = useColorModeValue("gray.200", "blackAlpha.500");

  return (
    <VStack w={"full"} align="center">
      <Box bg={boxBg} px="20px" py="20px" w={{ md: "70%" }} rounded="10px">
        <Text>“{props.quote}”</Text>
        <VStack align={"start"}>
          <Text as="cite" color="gray.500">
            {props.author}
          </Text>
        </VStack>
      </Box>
    </VStack>
  );
};
