import React, {useEffect} from "react";
import {
  Box,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import {
  ProductIdea,
  Summary,
} from "../../components/sub-components/Slient_Maze_Components/Section1_Summary";
import { TeamList } from "../../components/sub-components/Slient_Maze_Components/Section3_Team";
import {
  Software,
  SoftwareDescription,
} from "../../components/sub-components/Slient_Maze_Components/Section4_Software";
import { Research } from "../../components/sub-components/Slient_Maze_Components/Section5_Research";
import { WebsiteDesign } from "../../components/sub-components/Slient_Maze_Components/Section6_WebsiteDesign";
import { MazeRef } from "../../components/sub-components/Slient_Maze_Components/Section7_References";


const The_Slient_Maze = ({ bg, tc}) => {
  useEffect(() => {
    document.title = 'Bradlee King || The Silent Maze'
  });

  return (
    <Box
      onLoad={() => {
        window.scrollTo({ top: 0, left: 0 });
      }}
      name="theslientmaze"
      h={"100%"}
      w={"full"}
      justifyContent={"center"}
      bg={bg}
      color={tc}
      fontWeight={"semibold"}
    >
      <Box pt={"120px"} maxW={"1000px"} mx={"auto"} px={"1rem"}>
        <Box>
          <Text
            fontSize={"15px"}
            color={"#E5712F"}
            textTransform={"uppercase"}
            fontWeight={"extrabold"}
            letterSpacing={"1.5px"}
          >
            Project 3
          </Text>
          <Box
            display={{ md: "flex" }}
            justifyContent={{ md: "space-between" }}
          >
            <Heading
              letterSpacing={"1px"}
              fontSize={{ base: "4xl", md: "5xl" }}
              borderBottom={"3px solid #E5712F"}
              display={"inline-block"}
              textTransform={"uppercase"}
            >
              The Slient Maze
            </Heading>
            <Box display={{ base: "none", md: "block" }}>
              <Button
                
                target={"_blank"}
                mr={"20px"}
                as={"a"}
                href={"https://kingofbrad.github.io/"}
              >
                Go to Website
              </Button>
            </Box>
          </Box>
          <Button
            mt={"10px"}
            display={{ md: "none" }}
            mr={"20px"}
            size={"lg"}
            rounded={"sm"}
            as={"a"}
            href={"https://kingofbrad.github.io/"}
            target={"_blank"}
          >
            Go to Website
          </Button>
        </Box>

        <Box name="category"></Box>
        <Box name="summary" mt={"30px"}>
          <Summary />
        </Box>
        <Box name={"project"}>
          <ProductIdea />
        </Box>
        <Box>
          <TeamList />
        </Box>
        <Box name={"software"}>
          <Software />
          <SoftwareDescription />
        </Box>
        <Box>
          <Research />
        </Box>
        <Box>
          <WebsiteDesign />
        </Box>
        <Box>
          <MazeRef />
        </Box>
      </Box>
    </Box>
  );
};

export default The_Slient_Maze;
