import {
  Box,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useColorModeValue,
  Text,
  Heading,
} from "@chakra-ui/react";
import {
  SiVisualstudiocode,
  SiFigma,
  SiGithub,
  SiXcode,
  SiGooglechrome,
  SiFilezilla,
} from "react-icons/si";

export const Software = () => {
  return (
    <Box mt={"10px"}>
      <Text
        textTransform={"uppercase"}
        fontSize={"13px"}
        color={useColorModeValue("green.600", "green.700")}
        fontWeight={"extrabold"}
      >
        Section 4
      </Text>
      <Heading
        letterSpacing={"1px"}
        fontSize={{ base: "2xl", md: "3xl" }}
        borderBottom={"3px solid #e5712f"}
        display={"inline-block"}
      >
        Software Used
      </Heading>
      <Text>Throughout this project, i used a varity of software</Text>
      <Wrap spacingX={"-20px"} mt={"15px"}>
        <WrapItem>
          <Tag cursor={"default"}>
            <TagLeftIcon as={SiVisualstudiocode} />
            <TagLabel>Visual Studio Code</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={"default"}>
            <TagLeftIcon as={SiGithub} />
            <TagLabel>Github</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={"default"}>
            <TagLeftIcon as={SiGooglechrome} />
            <TagLabel>Google Chrome</TagLabel>
          </Tag>
        </WrapItem>
        <WrapItem>
          <Tag cursor={"default"}>
            <TagLeftIcon as={SiFilezilla} />
            <TagLabel>Filezilla</TagLabel>
          </Tag>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export const SoftwareDescription = () => {
  return (
    <Box mt={"20px"} maxW={"800px"}>
      {/* Card 1 */}
      <Box
        mb={"15px"}
        bg={useColorModeValue("gray.100", "blackAlpha.300")}
        p={"5px"}
        rounded={"md"}
      >
        <Tabs>
          <TabList flex={"1"} flexWrap={"wrap"} fontWeight={"extrabold"}>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Visual Studio Code
            </Tab>
            
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Github
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              Google Chrome
            </Tab>
            <Tab _selected={{ color: "#E5712F", fontWeight: "semibold" }}>
              FileZilla
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              Visual Studio Code was my main text editor throughout this project
              mainly due it being my most experienced text editor from others.
              Since I have already used Visual Studio Code in past and within
              the previous project, my knowledge of the shortcuts and using the
              extensions helped me to create the website as quickly as possible.
              <Text>
                The extension that helped me the most was{" "}
                <Text color={'#E5712F'}>&#39;IntelliSence for CSS class names in HTML&#39;</Text>.
                This extension saved a bunch of time.
              </Text>
            </TabPanel>
            <TabPanel>
              I mainly used GitHub for version control and to host the website
              using GitHub Page. The website was originally going to be on the
              same server as my portfolio where the website could be accessed
              but I was running into issues, so I resulted to GitHub, and I
              haven&#39;t had any issues with it.
            </TabPanel>
            <TabPanel>
              Google Chrome &#45; Throughout the project, I would use Google
              Chrome to debug and test the website as I did with the previous
              project as Google Chrome offers some of the best debugging tool
              for web developers.
            </TabPanel>
            <TabPanel>
              FileZilla &#45; I used FileZilla to simply to transfer files
              between local storage and the server. I would transfer updated
              HTML, CSS, and any new files to the server to be displayed on my
              portfolio. However, near the end of the project, I stopped using
              FileZilla as I Started GitHub to store my file and was using
              GitHub Page to host the website.
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
