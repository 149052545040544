import { Box, Text, Heading, useColorModeValue, Image } from "@chakra-ui/react";
import UIDesign from "../../../assets/projects-imgs/the-hive/UI Design.svg";

export const UserInterfaceDesign = () => {
  return (
    <Box>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontSize={"13px"}
          color={useColorModeValue("green.600", "green.700")}
          fontWeight={"extrabold"}
        >
          Section 9
        </Text>
        <Heading
          letterSpacing={"1px"}
          fontSize={{ base: "2xl", md: "3xl" }}
          borderBottom={"3px solid #e5712f"}
          display={"inline-block"}
        >
          User Interface Design
        </Heading>
      </Box>
      <Text color={useColorModeValue("gray.500", "gray.300")}>
        The completed version of the UI was not fully finished due to my lack of
        knowledge and a time constraint
      </Text>
      <Text my={"10px"}>
        For this project, I was tasked to create a user interface that I could
        demonstrate how it would work. Me, James, and Michael all decided on
        multiple designs for the user interface then we went around and ask a
        selection of people what design they preferred. We all decided to go
        with the second design, which can be seen in the screenshot below.
      </Text>
      <Text>
        From this quick design that James created, I went into Figma and
        proceeded to create the more fleshed-out version. The updated version
        can be seen in the screenshot below.
      </Text>
      <Box display={"flex"} justifyContent={"center"}>
        <Image src={UIDesign} rounded={"md"} w={{ md: "90%" }} />
      </Box>
      <Text>
        This version of the UI design was created for the non-VR version of the
        device, I didn&#39;t have enough time to create the VR version.
      </Text>
      <Text my={'10px'}>
        In the middle of the screen, that is the application wheel, this is
        where the user would select the app/game they would like to use/play.
        The bottom right-hand corner is where all necessary info for the device
        will be at. On the left is where the profile image, the username and the
        time will be located at. On the right is where the settings button and
        battery percentage are located at.
      </Text>
      <Text>
        I did end up creating the User interface within Swift UI. I chose Swift
        UI to create the UI mainly because at the time I knew more about that
        language than I did with Java. I used my iPad to display how the
        interface works as we could not get the required hardware due to budget.
      </Text>
    </Box>
  );
};
